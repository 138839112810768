import { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes, useParams } from "react-router-dom"
import { caseManagement, saveArchType } from "@/gluelayer"

import CloseIcon from "@/assets/svgs/close.svg"
import { Backdrop, Box, Grid, IconButton } from "@/components/mui.components"
import { useAppSelector } from "@/core/app/hooks"
import { useAppDispatch } from "@/core/app/hooks"
import { resetCase } from "@/core/app/slices/clinical/clinicalSlice"
import {
  fetchFileList,
  uploadCaseZips,
} from "@/core/app/slices/clinical/clinicalThunkApi"
import { fetchFilesList } from "@/core/app/slices/records/photograph"
import { fetchClinicalSettings } from "@/core/app/slices/user/userApis"
import { RootState } from "@/core/app/store"
import { useMyNavigation } from "@/hooks/useMyNavigation"

import Prescription from "@/modules/Uassist/USmartForms/Prescription"
import Retainer from "@/modules/Uassist/USmartForms/Retainer"
import SmartRxForm from "@/modules/Uassist/USmartForms/SmartRxForm"
import Summary from "@/modules/Uassist/USmartForms/Summary"
import OrderType from "@/modules/Uassist/OrderType"
import PhotoList from "@/modules/Uassist/PhotoList"
import {
  getCaseById,
  updateCaseById,
} from "@/core/app/slices/case/caseThunkApi"

const UassistRoutes: FC<{
  setUpperArchType: (val: number) => void
  setLowerArchType: (val: number) => void
  postSettingTozips: () => void
}> = ({ setUpperArchType, setLowerArchType, postSettingTozips }) => {
  return (
    <Routes>
      <Route
        path={"prescription"}
        element={
          <Prescription
            setUpperArchType={setUpperArchType}
            setLowerArchType={setLowerArchType}
            postSettingTozips={postSettingTozips}
          />
        }
      />
      <Route path={":formName/:formid"} element={<SmartRxForm />} />
      <Route
        path={"retainer"}
        element={
          <Retainer
            setUpperArchType={setUpperArchType}
            setLowerArchType={setLowerArchType}
            postSettingTozips={postSettingTozips}
          />
        }
      />
      <Route path={"ordertype"} element={<OrderType />} />
      <Route path={"summary"} element={<Summary />} />
    </Routes>
  )
}

const RxFormMoudle: FC = () => {
  const { t } = useTranslation()
  const { patientId, caseId } = useParams()
  const dispatch = useAppDispatch()
  const preview = useRef<HTMLInputElement>(null)
  const [selectedPreview, setSelectedPreview] = useState("")
  const [upperArchType, setUpperArchType] = useState<number>(null)
  const [lowerArchType, setLowerArchType] = useState<number>(null)

  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { rxPrescription } = useAppSelector(
    (state: RootState) => state.formService,
  )

  const { fileList } = useAppSelector((state: RootState) => state.PhotosService)

  const { routeFrom } = useAppSelector((state: RootState) => state.config)

  const { clinicalSettings, clinicalSettingsFetchStatus } = useAppSelector(
    (state: RootState) => state.userService,
  )

  useEffect(() => {
    dispatch(fetchClinicalSettings())
  }, [dispatch])

  const postSettingTozips = () => {
    if (!clinicalSettingsFetchStatus) return

    const archesToTreat = rxPrescription.data.arches_to_treat as {
      upper: any
      lower: any
    }
    console.log("🚀 ~ .then ~ archesToTreat:", archesToTreat)

    caseManagement
      .saveJsonDataToZip(
        JSON.stringify(clinicalSettings),
        "uassist_team_settings.json",
        "Setting Data2",
      )
      .then(() => {
        if (caseDetail) {
          const udesign_json = JSON.parse(caseDetail.udesign_json)
          const newUdesignJsonObj = {
            ...udesign_json,
            UpperType: archesToTreat.upper
              ? archesToTreat.upper.aligner_type === "Aligner"
                ? "A"
                : "R"
              : "",
            LowerType: archesToTreat.lower
              ? archesToTreat.lower.aligner_type === "Aligner"
                ? "A"
                : "R"
              : "",
          }
          console.log(
            "🚀 ~ caseManagement.getCaseFiles ~ newUdesignJsonObj:",
            newUdesignJsonObj,
          )

          dispatch(
            updateCaseById({
              patientId,
              caseId,
              payload: {
                case_extra_attrs: JSON.stringify(newUdesignJsonObj),
              },
            }),
          )

          saveArchType(
            archesToTreat.upper
              ? archesToTreat.upper.aligner_type === "Aligner"
                ? 0
                : 1
              : null,
            archesToTreat.lower
              ? archesToTreat.lower.aligner_type === "Aligner"
                ? 0
                : 1
              : null,
          )
        }
      })
      .then(() => {
        caseManagement.getCaseFiles(["bulk2"]).then((r) => {
          const zips = []
          if (r) {
            for (const k in r) {
              zips.push({
                fileName: k,
                file: r[k],
              })
            }
            dispatch(
              uploadCaseZips({
                zips,
                patientId,
                caseId,
              }),
            )
          }
        })
      })
  }

  // useEffect(() => {
  //   zipNames &&
  //     zipNames.length &&
  //     dispatch(
  //       fetchFileZips({
  //         patientId,
  //         caseId,
  //         zipNames,
  //       }),
  //     )
  // }, [zipNames])

  useEffect(() => {
    const handleScroll = () => {
      if (preview && preview.current) {
        const { top, height } = preview.current.getBoundingClientRect()
        //To determine if the columns have wrapped
        const parent = preview.current.parentElement?.offsetWidth
        const form = preview.current.nextSibling?.offsetWidth
        if (parent && form) {
          const isSticky =
            top <= 0 &&
            top + height > 0 &&
            parent >= form + preview.current.offsetWidth + 45
          preview.current.classList.toggle("preview-sticky", isSticky)
        }
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [preview])

  //Clean up clincal zipNames, zipList, and other data to avoid entering clincal with errors
  useEffect(() => {
    return () => {
      // dispatch(resetPrescriptionSchema())
      // dispatch(resetCase())
    }
  }, [])
  useEffect(() => {
    dispatch(getCaseById({ caseId, patientId }))
  }, [])
  return (
    <>
      <Grid
        container
        gap={"45px"}
        marginBottom={"48px"}
        paddingTop={2}
        justifyContent={"center"}
      >
        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          minWidth={"466px"}
          ref={preview}
          height={"720px"}
        >
          {/* <Grid sx={{ my: 2 }} display={"flex"}>
            <PatientBasicInfo
              patientData={patientData}
              caseDetail={caseDetail}
            />
          </Grid> */}
          <PhotoList
            setSelectedPreview={setSelectedPreview}
            upperArchType={upperArchType}
            lowerArchType={lowerArchType}
          />
        </Grid>
        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          width={"640px"}
        >
          <Box
            component={"div"}
            display={"flex"}
            justifyContent={"flex-end"}
            height={0}
            padding={"0"}
          >
            {/* <UButton
              sxProp={{ height: 30, width: 63 }}
              variant={"shade"}
              btnType={"button"}
              btnText={t("button.close")}
              size={"small"}
              onClickHandler={() => handleCloseButton()}
            /> */}
          </Box>
          <UassistRoutes
            setUpperArchType={setUpperArchType}
            setLowerArchType={setLowerArchType}
            postSettingTozips={postSettingTozips}
          />
        </Grid>
      </Grid>
      {selectedPreview && (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <Box
              display="flex"
              justifyContent="center"
              position="fixed"
              top="120px"
              bottom="120px"
              left="120px"
              right="120px"
              overflow="hidden"
            >
              <Box
                id="backdrop"
                position="relative"
                width="inherit"
                height="inherit"
              >
                <img
                  src={selectedPreview}
                  alt={"fullscreenimg"}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  loading="lazy"
                />
                <Box
                  component={"div"}
                  id="closeicon"
                  position="absolute"
                  top="0px"
                  right="0px"
                  sx={{ padding: "8px" }}
                >
                  <IconButton
                    size="medium"
                    sx={{
                      fontSize: "large",
                      backgroundColor: "divider",
                      padding: "0px",
                      ":hover": { backgroundColor: "divider" },
                    }}
                    onClick={() => setSelectedPreview("")}
                  >
                    <Box
                      component={"img"}
                      src={CloseIcon}
                      alt={"Logo"}
                      loading="lazy"
                      sx={{ cursor: "pointer", padding: "12px" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Backdrop>
        </>
      )}
    </>
  )
}
export default RxFormMoudle
