// React/Vue 等框架推荐使用 Hook 封装
import { useLayoutEffect, useState } from "react"

const getViewportWidth = () => {
  return Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  )
}
export const usePageWidth = () => {
  const [width, setWidth] = useState(getViewportWidth())

  useLayoutEffect(() => {
    const handleResize = () => setWidth(getViewportWidth())
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return width
}
