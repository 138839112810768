import http from "../../../global/http"

export const getBundlePricing = async (orgId: string) => {
  try {
    const { data } = await http.get(`/shared/v1/org/${orgId}/payments/bundle/`)
    return data
  } catch (err) {
    return err
  }
}

export const getZenduraPromotionData = async (orgId: string) => {
  try {
    const { data } = await http.get(`/user-mgmt/v1/org/${orgId}/account/promotion/zendura/list`)
    return data
  } catch (err) {
    return err
  }
}

export const assignZenduraPromotionCode = async (orgId: string) => {
  try {
    const { data } = await http.patch(`/user-mgmt/v1/org/${orgId}/account/promotion/zendura/assign`)
    return data
  } catch (err) {
    return err
  }
}