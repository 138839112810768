import { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import LaunchIcon from "@mui/icons-material/Launch"
import Zendura from "../../assets/svgs/zendura.svg"
import { Box, Grid, Link } from "../../components/mui.components"
import { UButton, UText } from "../../ui-component"

import { IconButton, SvgIcon, Typography } from "@/components/mui.components"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { assignZenduraPromoCode } from "@/core/app/slices/pricing/pricingThunkApi"
import { RootState } from "@/core/app/store"
import moment from "moment-timezone"
import { getCurrentTimeByTimeZone } from "../Patient/PatientList/util/commonUtil"

export const PartnershipProgram: FC = () => {
  const { t } = useTranslation("common")
  const [revealCoupon, setRevealCoupon] = useState(false)
  const [remainingDays, setRemainingDays] = useState(null)
  const couponCodeRef = useRef(null)
  const { zenduraPromotionData } = useAppSelector(
    (state: RootState) => state.PricingService,
  )
  const dispatch = useAppDispatch()
  const [couponCode, setCouponCode] = useState('');
  const [expirationDate, setExpirationDate] = useState(null);

  useEffect(() => {
    const promotionInfo = zenduraPromotionData?.[0] || null;
    setCouponCode(promotionInfo?.code || '');
    setExpirationDate(promotionInfo?.expiration_date || null);
  }, [zenduraPromotionData])

  const formatDate = (dateStr: string) => {
    const formattedDate = moment(dateStr, "MM/DD/YY hh:mm A").format(
      "MMM DD, YYYY",
    )
    return formattedDate
  }
  useEffect(() => {
    if (expirationDate) {
      const updateRemainingTime = () => {
        const given = moment(getCurrentTimeByTimeZone(expirationDate))
        const current = moment()
        const duration = moment.duration(given.diff(current))
        setRemainingDays(
          `${duration.days()}d ${duration.hours()}h:${duration.minutes()}m`,
        )
      }

      updateRemainingTime()
      const interval = setInterval(updateRemainingTime, 1000)
      return () => clearInterval(interval)
    }
  }, [expirationDate])

  const handleRevealCoupon = () => {
    dispatch(assignZenduraPromoCode()).then(() => {
      setRevealCoupon(true)
    })
  }

  const handleCopyCoupon = () => {
    if (couponCodeRef.current)
      navigator.clipboard.writeText(couponCodeRef.current.innerText)
  }

  return (
    <Grid sx={{ pt: 6, px: 9, width: "100%" }}>
      <Box sx={{ pb: 1, borderBottom: "1px solid grey" }}>
        <UText variant="h5" color="text.primary">
          {t("partnershipProgram.title")}
        </UText>
      </Box>

      <Grid container justifyContent="center" mt={2}>
        <Grid item sx={{ width: "832px" }}>
          <Box
            sx={{
              background: "#EEE",
              p: "40px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                component="img"
                src={Zendura}
                alt="Logo"
                loading="lazy"
                sx={{ width: "340px" }}
              />
            </Box>
            <UText variant="body1" color="text.primary">
              {t("partnershipProgram.description")}
            </UText>
            <UText variant="body1" color="text.primary">
              <Link
                href="https://www.zenduradental.com/"
                target="_blank"
                sx={{
                  textDecoration: "underline",
                  color: "rgba(33, 94, 205, 1)",
                  fontWeight: "400",
                }}
              >
                {t("partnershipProgram.linkText")}
                <SvgIcon
                  sx={{ mx: 0.5, marginBottom: "-4px", fontSize: "large" }}
                >
                  <LaunchIcon />
                </SvgIcon>
              </Link>
              {t("partnershipProgram.additionaltext")}
            </UText>
          </Box>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" mb={3}>
        <Grid
          item
          sx={{ width: "832px", border: "2px solid rgba(238, 238, 238, 1)" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
            mt={3}
          >
            <UText variant="h6" color="text.primary">
              {t("partnershipProgram.thankyouText")}
            </UText>
            <UText
              variant="body2"
              color="text.primary"
              sxProp={{ fontWeight: "400" }}
            >
              {!revealCoupon &&
                !couponCode &&
                t("partnershipProgram.revealCodeText")}
              {t("partnershipProgram.revealCodeText2")}
            </UText>

            <Grid container sx={{ justifyContent: "center" }} my={2}>
              <Grid
                item
                sx={{ display: "flex", flexDirection: "column", mr: 5 }}
              >
                <UText variant="h3" color="rgba(66, 165, 245, 1)">
                  20% Off
                </UText>
                <UText
                  variant="caption"
                  color="rgba(0, 0, 0, 0.6)"
                  sxProp={{ fontWeight: "400" }}
                >
                  Zendura clear aligner and <br />
                  retainer material
                </UText>
              </Grid>
              {!revealCoupon && !couponCode ? (
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <UButton
                    variant="contained"
                    btnType="button"
                    btnText={t("partnershipProgram.btnText")}
                    onClickHandler={handleRevealCoupon}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item textAlign="center" pr={2} pl={2}>
                    <Grid>
                      <Typography variant="h5" pb={1}>
                        {t("partnershipProgram.yourCode")}
                      </Typography>
                      <UButton
                        sxProp={{
                          border: "2px dashed #2e3176",
                          padding: "20px 60px",
                          backgroundColor: "white",
                          color: "black",
                          fontSize: "16px",
                          "&:hover": {
                            border: "2px dashed #2e3176",
                            padding: "20px 60px",
                            backgroundColor: "white",
                            color: "black",
                            fontSize: "16px",
                          },
                        }}
                        inputRef={couponCodeRef}
                        variant="outlined"
                        btnType="button"
                        endIcon={
                          <IconButton
                            onClick={handleCopyCoupon}
                            title={"Your code"}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        }
                        btnText={couponCode}
                        onClickHandler={handleCopyCoupon}
                      />
                    </Grid>
                    <Grid pt={1}>
                      <Typography
                        variant="caption"
                        sx={{ fontWeight: "400", fontSize: "12px" }}
                      >
                        {t("partnershipProgram.CouponExpiry")}
                        {formatDate(getCurrentTimeByTimeZone(expirationDate))}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    textAlign="center"
                    sx={{ display: "flex", alignItems: "center" }}
                    ml={5}
                    pr={2}
                    pl={2}
                  >
                    {remainingDays && (
                      <div>
                        <Typography variant="caption">
                          {t("partnershipProgram.validityTest")}
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          sx={{ fontSize: "14px", color: "#2e3176" }}
                        >
                          {remainingDays}
                        </Typography>
                      </div>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
            <Link
              href="https://www.zenduradental.com/"
              target="_blank"
              sx={{
                textDecoration: "underline",
                color: "rgba(33, 94, 205, 1)",
                fontWeight: "400",
                mb: 3,
              }}
            >
              {t("partnershipProgram.linkText")}
              <SvgIcon
                sx={{ marginLeft: 0.5, marginBottom: "-1px", fontSize: "12px" }}
              >
                <LaunchIcon />
              </SvgIcon>{" "}
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
