import { FC, useEffect } from "react"
import { I18nextProvider, useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js"
import { Security } from "@okta/okta-react"

import packageJson from "../package.json"

import { useAnalytics } from "./core/app/customHooks"
import { useAppSelector } from "./core/app/hooks"
import { setIsbrowserBack } from "./core/app/slices/navigationPopup/navigationPopupSlice"
import { RootState } from "./core/app/store"
import i18nConfig from "./core/config/i18n.config"
import oktaAuthClient from "./core/config/okta.config"
import { useMyNavigation } from "./hooks/useMyNavigation"
import AnalyticsWrapper from "./AnalyticsWrapper"
import AppRoutes from "./routes"
import { ULoading, UsmileLightThemeProvider } from "./ui-component"

import "./App.scss"
import { CloseTabProvider } from "./core/app/CloseTabContext"

console.log("...VERSION: ", packageJson.version)

const App: FC = () => {
  const initialized = useAnalytics()
  const navigate = useMyNavigation()
  const dispatch = useDispatch()
  const { ready } = useTranslation("common")
  const {
    loading: patientLoading,
    newPatientAPI,
    updatePatientAPI,
  } = useAppSelector((state: RootState) => state.patientService)
  const { loading: photoLoading, isDownloading } = useAppSelector(
    (state: RootState) => state.PhotosService,
  )

  const { isFetching } = useAppSelector(
    (state: RootState) => state.bannerService,
  )

  const { loading: xrayLoading } = useAppSelector(
    (state: RootState) => state.xrayService,
  )
  const { loading, clinicalSettingStatus } = useAppSelector(
    (state: RootState) => state.userService,
  )
  const { loading: orderLoading } = useAppSelector(
    (state: RootState) => state.orderService,
  )
  const { isLoading } = useAppSelector((state: RootState) => state.formService)

  const { isLoading: fileLoading,isWasmLoading: wasmLoading } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )

  const { customListFetching: customLoading, loading: uploadCustomLoading } =
    useAppSelector((state: RootState) => state.customPackagingService)

  const { loading: caseLoading } = useAppSelector(
    (state: RootState) => state.caseService,
  )

  const { loading: billingLoader } = useAppSelector(
    (state: RootState) => state.billingService,
  )
  const { isRouteChangeBlocked } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )
  const { loading: pricingLoader } = useAppSelector(
    (state: RootState) => state.PricingService,
  )

  const loadingVariables = [
    patientLoading,
    photoLoading,
    wasmLoading,
    xrayLoading,
    newPatientAPI,
    updatePatientAPI,
    isDownloading,
    loading,
    isLoading,
    clinicalSettingStatus,
    orderLoading,
    caseLoading,
    fileLoading,
    isFetching,
    billingLoader,
    pricingLoader,
    customLoading,
    uploadCustomLoading,
  ]

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin))
  }
  const onAuthRequired = () => {
    navigate("auth/login", { replace: true })
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     const width = window.innerWidth;
  //     const newScale = width / 1920;
  //     document.body.style.zoom = newScale.toString();
  //   }, 0);
  // }, []);

  useEffect(() => {
    const handleBackButton = () => {
      // Your logic when the back button is clicked
      if (isRouteChangeBlocked) {
        dispatch(setIsbrowserBack(true))
      }
    }
    // Attach the event listener when the component mounts
    window.addEventListener("popstate", handleBackButton)
    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handleBackButton)
    }
  }, [isRouteChangeBlocked])

  return (
    <CloseTabProvider>
<UsmileLightThemeProvider>
      <Security
        oktaAuth={oktaAuthClient}
        restoreOriginalUri={restoreOriginalUri}
        onAuthRequired={onAuthRequired}
      >
        <div className={"App"} data-testid={"app"}>
          <AnalyticsWrapper initialized={initialized}>
            {ready && (
              <I18nextProvider i18n={i18nConfig}>
                <ULoading
                  isLoading={loadingVariables.some(
                    (item) => item === "pending",
                  )}
                />
                <AppRoutes />
              </I18nextProvider>
            )}
          </AnalyticsWrapper>
        </div>
      </Security>
    </UsmileLightThemeProvider>
    </CloseTabProvider>
  )
}

export default App
