const ShortcutsUiText = {
  "PC Shortcuts": {
    "Undo": "CTRL + Z",
    "Redo": "CTRL + Y",
  },
  "PC Mouse Actions": {
    "Rotate Model": "Right Click + Drag",
    "Pan Model": "Shift + Right Click + Drag",
    "Isolated Rotate (Pivot Model)": "CTRL + Right Click + Drag",
    "Zoom In/Out": "Mouse Wheel",
  },
  "Mac Shortcuts": {
    "Undo": "⌘ + Z",
    "Redo": "⌘ + Y",
  },
  "Mac Mouse Actions": {
    "Rotate Model": "Right Click + Drag",
    "Pan Model": "Shift + Right Click + Drag",
  }
}
export default ShortcutsUiText