import { createSlice } from "@reduxjs/toolkit"

import { formatDate } from "../../../../modules/Patient/PatientList/util/commonUtil"
import { BundleItems } from "../../../model/Pricing"

import { assignZenduraPromoCode, fetchBundlePricing, fetchZenduraPromotionData } from "./pricingThunkApi"

export interface Volume {
  CurrentTotal: string
  SixTotal: string
  CurrentAligner?: string
  CurrentRetainer?: string
  SixAligner?: string
  SixRetainer?: string
}
interface Coupon {
  id: number;
  code: string;
  expiration_date: string;
  update_date: string;
  update_org_id: number;
  update_user_id: number | null;
}

interface CouponResponse {
  results: Coupon[];
  remainingCoupon?: boolean;
}
interface Pricing {
  outstandingBalance: null | number
  bundleItems: BundleItems
  otherPricing: any
  shipping: any
  stlPrice: {
    price: string
    discountedPrice: string
    monthlyCap: string
  }
  uassistService: {
    initial: string
    refinement: string
    retainer: string
    bracketRemoval: string
  }
  customPackaging: {
    setup: string
  }
  pricingDate: { startDate: string; endDate: string }
  volume: {
    aligner: Volume
    stl: Volume
  }
  loading: "idle" | "pending" | "succeeded" | "failed"
  zenduraPromotionData: CouponResponse | {}
}
const initialState: Pricing = {
  loading: "idle",
  outstandingBalance: null,
  otherPricing: {},
  bundleItems: null,
  stlPrice: null,
  uassistService: null,
  customPackaging: null,
  shipping: null,
  pricingDate: null,
  volume: null,
  zenduraPromotionData: {}
}

export const PricingServiceSlice = createSlice({
  name: "PricingService",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBundlePricing.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchBundlePricing.fulfilled, (state, action) => {
      state.loading = "succeeded"
      const res = action?.payload?.result
      if (res) {
        state.bundleItems = res.bundle_items
        state.stlPrice = {
          price: res.stl_price,
          discountedPrice: res.stl_discounted_price,
          monthlyCap: res.stl_monthly_cap,
        }
        state.customPackaging = { setup: res.custom_packaging_setup }
        state.shipping = res.shipping
        state.pricingDate = {
          startDate: formatDate(res.program_start_date),
          endDate: formatDate(res.program_end_date),
        } 
        state.volume = {
          aligner: {
            CurrentTotal: res.usmile_month_volume?.current?.total,
            CurrentAligner: res.usmile_month_volume?.current?.aligner,
            CurrentRetainer: res.usmile_month_volume?.current?.retainer,
            SixTotal: res.usmile_month_volume?.six?.total,
            SixAligner: res.usmile_month_volume?.six?.aligner,
            SixRetainer: res.usmile_month_volume?.six?.retainer,
          },
          stl: {
            SixTotal: res.stl_month_volume?.six,
            CurrentTotal: res.stl_month_volume?.current,
          },
        }
      }
    })
    builder.addCase(fetchBundlePricing.rejected, (state) => {
      state.loading = "failed"
    })

    builder.addCase(fetchZenduraPromotionData.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchZenduraPromotionData.fulfilled, (state, action) => {
      state.loading = "succeeded"
      const res = action?.payload?.result?.result
      if (res) {
        state.zenduraPromotionData = res
      }
    })
    builder.addCase(fetchZenduraPromotionData.rejected, (state) => {
      state.loading = "failed"
    })

    builder.addCase(assignZenduraPromoCode.fulfilled, (state, action) => {
      state.loading = "succeeded"
      const res = action?.payload?.result
      if (res) {
        state.zenduraPromotionData = res
      }
    })
    builder.addCase(assignZenduraPromoCode.rejected, (state) => {
      state.loading = "failed"
    })
  },
})
