import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useParams, useSearchParams } from "react-router-dom"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import PatientInfoHeader from "../../../containers/LandingLayout/PatientInfoHeader/PatientInfoHeader"
import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { UButton, UText } from "../../../ui-component"
import { Box, Grid } from "../../../ui-component/mui.components"

import ShipOrder from "./ShipOrder"
import { safeCloseWindow } from "@/core/app/CloseTabContext"

const OrderConfirmation: FC = () => {
  const { t } = useTranslation()
  const navigate = useMyNavigation()
  const { patientId, orderId } = useParams()
  const { currentOrder } = useAppSelector(
    (state: RootState) => state.orderService,
  )

  return (
    <>
      <Grid
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <Grid item lg={12} md={11} sm={10}>
          <PatientInfoHeader
            chipLabel={"Place Order"}
            cancelBtnText={t("button.closeCase")}
            onBtnCancelClick={() => {
              safeCloseWindow()
            }}
            chipSx={{
              background: "#0288D1",
              fontSize: "13px",
              fontWeight: "400px",
              color: "primary.contrastText",
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction={"row"}
        gap={"86px"}
        sx={{
          display: "flex",
          py: 6,
          justifyContent: "center",
        }}
      >
        <Grid item lg={7} md={6} sm={10}>
          <Box component={"div"} sx={{ py: 1, mb: 1 }}>
            <UText variant={"h5"} color="text.primary">
              {t("overview.order.confirmation.thanknote")}
            </UText>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mb: 2,
            }}
          >
            <Box sx={{ mr: 1 }}>
              <CheckCircleIcon color={"success"} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
              }}
            >
              <UText variant={"subtitle1"}>
                {t("overview.order.confirmation.ordernote")}
              </UText>
              <UText
                variant={"body1"}
                sxProp={{
                  paddingLeft: "0px",
                  fontSize: "16px",
                  height: "24px",
                }}
              >
                {`Order #${orderId}`}
                {/* <UButton
                  btnText={`Order #${orderId}`}
                  data-testid={"InputField-id"}
                  onClickHandler={() =>
                    isOpenedInNewTab
                      ? undefined
                      : navigate(
                          `/overview/patient/${patientId}#orderOverview`,
                          {
                            state: { isConfirmation: true },
                          },
                        )
                  }
                  variant={"text"}
                  sxProp={{
                    paddingLeft: "0px",
                    textDecoration: "underline",
                    textDecorationColor: "rgb(33, 94, 205, .4)",
                    textTransform: "none",
                    fontSize: "16px",
                    height: "24px",
                    "&:hover": {
                      backgroundColor: "transparent",
                      textDecoration: "underline",
                    },
                  }}
                /> */}
              </UText>
              {currentOrder?.subOrderId ? (
                <UText variant={"body1"}  sxProp={{
                  paddingLeft: "0px",
                  fontSize: "16px",
                  height: "24px",
                }}>
                 { `Order #${currentOrder.subOrderId}`}
                 {/* <UButton
                    btnText={`Order #${currentOrder.subOrderId}`}
                    data-testid={"InputField-id"}
                    onClickHandler={() =>
                      isOpenedInNewTab
                        ? undefined
                        : navigate(
                            `/overview/patient/${patientId}#orderOverview`,
                            {
                              state: { isConfirmation: true },
                            },
                          )
                    }
                    variant={"text"}
                    sxProp={{
                      cursor: isOpenedInNewTab ? "unset" : "pointer",
                      paddingLeft: "0px",
                      textDecoration: "underline",
                      textDecorationColor: "rgb(33, 94, 205, .4)",
                      textTransform: "none",
                      fontSize: "16px",
                      height: "24px",
                      "&:hover": {
                        backgroundColor: "transparent",
                        textDecoration: "underline",
                      },
                    }}
                  /> */}
                </UText>
              ) : null}
            </Box>
          </Box>
          <Box sx={{ mb: 2 }}>
            <UText variant={"body2"}>
              {t("overview.order.confirmation.orderdesc")}
            </UText>
          </Box>
          
        </Grid>
        <Grid item justifyContent={"centre"} lg={4} md={4} sm={10}>
          <ShipOrder />
        </Grid>
      </Grid>
    </>
  )
}

export default OrderConfirmation
