import { FC, ReactNode, Ref } from "react"
import React from "react"
import { Theme } from "@emotion/react"

import { Button, SxProps } from "../../mui.components"

export interface IButtonProps {
  /**
   *
   * The variant to use. "contained" | "outlined" | "text" | "shade"
   */
  variant: "contained" | "outlined" | "text" | "shade"
  /**
   * button label
   */
  btnText: string
  /**
   * 	
The system prop that allows defining system overrides as well as additional CSS styles. See the `sx` page for more details.
   */
  sxProp?: SxProps<Theme>
  /**
   * Button action
   * @returns void
   */
  onClickHandler?: (e) => void
  /**
   * Button type
   */
  btnType?: "button" | "submit"
  /**
   * Element placed before the children.
   */
  startIcon?: ReactNode | null
  /**
   * Element placed before the children.
   */
  endIcon?: ReactNode | null
  /**
   * 'medium'	
    The size of the component. small is equivalent to the dense button styling.
   */
  size?: "small" | "medium" | "large"
  /**
   *
   * If true, the component is disabled.
   */
  disabled?: boolean
  /**
   *
   * If true, the component ripple effect is disabled.
   */
  disableRipple?: boolean
  btnColor?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
  inputRef?: Ref<HTMLInputElement>;
}

const UButton: FC<IButtonProps> = ({
  variant,
  btnText,
  onClickHandler,
  btnType,
  sxProp,
  startIcon,
  endIcon,
  size,
  disabled,
  disableRipple,
  btnColor,
  inputRef
}) => {
  return (
    <>
      <Button
        ref={inputRef}
        disableRipple={disableRipple}
        variant={variant}
        type={btnType}
        sx={{ ...sxProp }}
        onClick={(e) => onClickHandler && onClickHandler(e)}
        startIcon={startIcon}
        endIcon={endIcon}
        size={size}
        disabled={disabled}
        color={btnColor && btnColor}
      >
        {btnText}
      </Button>
    </>
  )
}

UButton.defaultProps = {
  sxProp: undefined,
  btnType: "button",
  startIcon: null,
  size: "medium",
  disabled: false,
}

export default UButton
