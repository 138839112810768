import { ChangeEvent, FC, useEffect } from "react"
import { useTranslation } from "react-i18next"

import {
  Box,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Radio,
  RadioGroup,
} from "../../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { updateSelectedPackageType } from "../../../../core/app/slices/order"
import { getPackageDetail } from "../../../../core/app/slices/order/orderThunkApi"
import { RootState } from "../../../../core/app/store"
import { formatCurrency } from "../../../../core/utils/formatters"
import { UText } from "../../../../ui-component"

export interface IPackage {
  title: string
  amount: string
  type: string
}

export interface IPackageTypeProps {
  isReviewOrder: boolean
}

const PackageType: FC<IPackageTypeProps> = ({ isReviewOrder }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  let packageList = [
    {
      title: "uLab Standard",
      type: "ulab_packaging",
      amount: "Included",
    },
    {
      title: "Custom Logo",
      type: "custom_packaging",
      amount: "Included",
    },
  ]

  const {
    packageType,
    isCustomPackagingDisabled,
    selectedBundle,
    currentOrder,
    currency,
  } = useAppSelector((state: RootState) => state.orderService)
  const { countryCode } = useAppSelector(
    (state: RootState) => state.userService,
  )

  if (isReviewOrder) {
    packageList = packageList.filter((o) => o.type === packageType)
  }

  const handlePackageTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateSelectedPackageType((event.target as HTMLInputElement).value),
    )
  }

  useEffect(() => {
    if (packageType) {
      return
    }
    dispatch(getPackageDetail()).then((res) => {
      if (res?.payload?.status === "Success") {
        if (String(res?.payload?.result?.default_packaging_option).toLowerCase()==="custom box") {
          const packageType =
            selectedBundle?.name !== "A La Carte"
              ? "custom_packaging"
              : "ulab_packaging"
          dispatch(updateSelectedPackageType(packageType))
        } else {
          dispatch(updateSelectedPackageType("ulab_packaging"))
        }
      }
    })
  }, [])

  const getpackagePrice = (item: IPackage) => {
    if (
      isReviewOrder &&
      currentOrder?.alacarte?.custom_package?.cost &&
      item.type === "custom_packaging"
    ) {
      const price = formatCurrency(
        currentOrder.alacarte.custom_package.cost,
        currency,
        countryCode,
      )
      return price
    } else {
      return item.amount
    }
  }

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: 4,
        padding: "16px",
      }}
    >
      <Box>
        <UText component={"div"} variant={"subtitle2"}>
          {t("overview.order.shippingPacking.packageType")}
        </UText>
        <RadioGroup
          sx={{
            py: 1,
          }}
          aria-labelledby="package-type-group"
          name="package_type"
          onChange={handlePackageTypeChange}
        >
          {packageList.map((item, index) => (
            <Box
              key={item.type}
              sx={{
                borderBottom:
                  index === packageList.length - 1 ? "" : "1px solid #E0E0E0",
                p: "8px 16px",
                color:
                  item.type === "custom_packaging" && isCustomPackagingDisabled
                    ? (theme) => theme.palette.text.disabled
                    : "",
              }}
            >
              <Box>
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid item md={10}>
                    {!isReviewOrder ? (
                      <Box>
                        <FormControlLabel
                          value={item.type}
                          sx={{
                            "> .MuiFormControlLabel-label": {
                              fontWeight: "400 !important",
                            },
                          }}
                          control={
                            <Radio
                              sx={{
                                marginLeft: "9px",
                                marginRight: "14px",
                                marginBottom: "4px",
                                ".MuiSvgIcon-root": {
                                  height: "24px",
                                  width: "24px",
                                },
                              }}
                              checked={item.type === packageType}
                            />
                          }
                          disabled={
                            item.type === "custom_packaging" &&
                            isCustomPackagingDisabled
                          }
                          label={item.title}
                        />
                      </Box>
                    ) : (
                      <Box>{item.title}</Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    md={2}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <UText sxProp={{ p: "12px" }} variant={"subtitle2"}>
                      {item.type === "custom_packaging" && isCustomPackagingDisabled
                        ? ""
                        : getpackagePrice(item)}
                    </UText>
                  </Grid>
                </Grid>
              </Box>
              {item.type === "custom_packaging" &&
                isCustomPackagingDisabled && (
                  <Box
                    sx={{
                      marginLeft: "60px",
                      fontSize: "12px",
                      color:
                        item.type === "custom_packaging"
                          ? (theme) => theme.palette.text.secondary
                          : "",
                    }}
                  >
                    <UText component={"div"} variant={"caption"}>
                      {t("overview.order.shippingPacking.customlogoLink.0")}
                      <Link href="https://hub.ulabsystems.net/settings/custom-boxes">
                        {t("overview.order.shippingPacking.customlogoLink.1")}
                      </Link>
                      {t("overview.order.shippingPacking.customlogoLink.2")}
                    </UText>
                  </Box>
                )}
            </Box>
          ))}
        </RadioGroup>
      </Box>
    </Paper>
  )
}

export default PackageType
