import { useEffect, useState,useMemo,memo } from "react"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { filter, find, isEmpty } from "lodash"
import { LeftPage } from "./LeftPage"
import { PhotosAndXRays, findFileNameFromThumbs } from "./PhotosAndXRays"
import LoadingButton from "@mui/lab/LoadingButton"
import { RootState } from "@/core/app/store"
import { getAccessToken } from "@/core/config/okta.config"
import { CASE_STATUS } from "@/modules/Patient/config/status.config"
import RefinementList from "../../../src/modules/Records/RefinementList"
import { Backdrop } from "@mui/material"
import { PhotosViewer } from "./PhotosViewer"


export const UDPhotos = (props)=>{
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const [isReadonly] = useState(caseDetail.caseDisposition===CASE_STATUS.PLANNING_IN_PROGRESS?true:false)
  const { patientId, caseId } = useParams()
  const [currentReviewNum, setCurrentReviewNum] = useState<number>(0)
  const [openZoom, setopenZoom] = useState({
    open: false,
    index: null,
    datas: [],
  })

  return <>
  <LeftPage
    id="leftpage"
    sx={{
      width: 536,
      Height: "auto",
      zIndex:10,
      visibility: openZoom.open? "hidden": "visible"
    }}
    title="Photos and X-Rays"
    onClose={props.onClose}
    headersx={{
      height: 72,
    }}
    contentsx={{ padding: "0px 0px" }}
    content={
      <PhotosAndXRays
      isReadOnly={isReadonly}
      refinementIndex={currentReviewNum}
      openZoom={openZoom}
      setopenZoom={setopenZoom}
      />
      
    }
    headContent={
      <RefinementList 
        isShow={caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST"? false: true}
        listSX={{ marginLeft: "25px", marginTop: "-8px" }} 
        caseId={caseId}
        patientId={patientId} 
        creatDate={
          caseDetail.createDate?`
          ${caseDetail.createDate.substring(5, 7)}/${caseDetail.createDate.substring(8,10)}/${caseDetail.createDate.substring(2,4)}`
          :""} 
        onRefinementChange={(reviewNum) => 
          setCurrentReviewNum(reviewNum)} />
    }
    >
    </LeftPage>
    {openZoom.open && (
      <Backdrop
        sx={{
          left: 84,
          top: 98,
          width: "536px",
          borderRadius: "8px",
          height: `calc(100% - 114px)`,
          color: "#ffff",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          position: "absolute",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openZoom.open}
      >
        <PhotosViewer
          images={openZoom.datas}
          setopenZoom={setopenZoom}
          index={openZoom.index}
          findFileNameFromThumbs={findFileNameFromThumbs}
          refinementIndex={currentReviewNum}
        />
      </Backdrop>
    )}
  </>
}