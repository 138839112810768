import type { FC } from "react"
import { Box, Backdrop, LinearProgress } from "@mui/material"
import { UText, UButton } from "@/components/index"
import { RootState } from "@/core/app/store"
import { useAppSelector } from "@/core/app/hooks"
import { safeCloseWindow } from "@/core/app/CloseTabContext"

const ConversionMask: FC = () => {
  const { conversionObj } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  //An error occurred while preparing the case. Please contact Customer Sucess at support@ulabsystems.com or call 1-866-900-8522 for assistance. We apologize for the inconvenience.
  return (
    conversionObj.show && (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <Box sx={{ width: "40%" }}>
          <UText variant={"h6"}>
            {conversionObj.isFail
              ? `Case conversion failed`
              : `Converting case...`}
          </UText>
          <LinearProgress
            sx={{
              margin: "8px 0",
              background: conversionObj.isFail
                ? (theme) => theme.palette.error.dark
                : "rgb(170, 193, 236)",
              "& .MuiLinearProgress-bar": {
                display: conversionObj.isFail ? "none" : "",
              },
            }}
          />
          <UText variant={"subtitle1"}>
            {!conversionObj.isFail ? (
              <span>{`Please stand by while we convert the case files for compatibility, which may take a moment. Thank you for your patience!`}</span>
            ) : (
              <span>
                An error occurred while preparing the case. Please contact
                Customer Sucess at  
                <span style={{ textDecoration: "underline", margin: "0 6px" }}>
                  support@ulabsystems.com
                </span>
                 or call 1-866-900-8522 for assistance. We apologize for the
                inconvenience.
              </span>
            )}
          </UText>
          {conversionObj.isFail && (
            <Box sx={{ display: "flex", flexFlow: "row-reverse" }}>
              <UButton
                variant="contained"
                btnText="OK"
                sxProp={{
                  padding: "8px 22px",
                  borderRadius: "4px",
                  background: "#E0E0E0",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "15px",
                  fontWeight: 500,
                }}
                onClickHandler={() => {
                  safeCloseWindow()
                }}
              />
            </Box>
          )}
        </Box>
      </Backdrop>
    )
  )
}
export default ConversionMask
