import { useEffect, useState } from "react"
import { Box, Select, Typography, MenuItem } from "@mui/material"
import { refinementModule } from "../../gluelayer/core/modules/refinement";
import { treatView, caseManagement } from "@/gluelayer"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import {dateFormat} from "@/core/utils/formatters";
import { getUAssistOrders } from "@/core/app/slices/order/orderThunkApi";

const testHistoryList = {
  original: "1/8/24" ,
  Refinement: "1/8/24" ,
  Retainer: "1/8/24" ,
}

type RefinementListProps = {
  isShow: boolean
  listSX: object
  patientId: number | string
  caseId: number | string
  onRefinementChange: (any)=>void
  creatDate?: number
}

const RefinementList = (props: RefinementListProps) => {
  const dispatch = useAppDispatch();

  const [historyList, setHistoryList] = useState([])
  const [currentRefinement, setCurrentRefinement] = useState(null)
  const [showList, setShowList] = useState(false)


  useEffect(()=>{
    dispatch(getUAssistOrders({
      caseId: props.caseId,
      patientId: props.patientId,
    })).then((res)=>{
      const uAssistOrders =  res?.payload
      if ( uAssistOrders?.length ) {
        setShowList(true)
        setHistoryList(uAssistOrders)
        setCurrentRefinement(uAssistOrders[0])
        props.onRefinementChange(uAssistOrders[0].uassistRound ?? 0) 
      }else{
        props.onRefinementChange(0) 
      }
    })
  },[])

  const handleChange = (event) => {
    setCurrentRefinement(event.target.value)
    let refinementNum = event.target.value.uassistRound
    props.onRefinementChange(refinementNum)
  }
  
  return (
    ( showList && props.isShow && (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "331px",
          marginBottom: "16px",
          boxShadow: "0px 3px 5px -2px #888888",
          ...props.listSX
        }}
      >
        <Select
          onChange={handleChange}
          value={currentRefinement}
          sx={{
            height: "30px",
            backgroundColor: "#F5F5F5",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}>
          {historyList.map((item) => (
            <MenuItem
              key={"historyListItem" + item.id}
              value={item}
              sx={{
                color: item.id === currentRefinement.id ? "#9C9C9C" : "#000"
              }}>
                {item.planName}
            </MenuItem>
          ))}
        </Select>
      </Box>)
    )
  )
}
export default RefinementList
