import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { ReactSVG } from "react-svg"
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material"
import {
  biteRampModule,
  caseManagement,
  orderView,
  toothModule,
  viewControlInTreatment,
  wasmModule,
} from "@/gluelayer"
import { isEmpty } from "lodash"

import miss from "../../../assets/svgs/close.svg"
import {
  useAppDispatch,
  useAppSelector,
  useDeepEffect,
} from "../../../core/app/hooks"

import { updateCustomization } from "../../../core/app/slices/order/orderSlice"
import { getMaterialsAPI } from "../../../core/app/slices/order/orderThunkApi"
import { RootState } from "../../../core/app/store"
import { ULoading, UText } from "../../../ui-component"

import { position, svgList } from "./component/toothObject"

const Coverage: FC<{
  isUpper: boolean
  setzoomValue: (arg: number) => void
  zoomValue
  wasmReady: boolean
}> = ({ isUpper, setzoomValue, zoomValue, wasmReady }) => {
  const {
    orderService: {
      alignerOrderPayload: {
        coverage,
        materials,
        stage: {
          retainers: { up: r_up, low: r_low },
        },
      },
    },
  } = useAppSelector((state: RootState) => state)

  const dispatch = useAppDispatch()
  const { t } = useTranslation("common")
  const { patientId, caseId } = useParams()
  const [coverageTooth, setcoverageTooth] = useState([])
  const [radioLeft, setradioLeft] = useState(["1", "1"])
  const [radioRight, setradioRight] = useState(["1", "1"])
  const [selectedTeethid, setselectedTeethid] = useState([])
  const [domeJudgeTeeth, setdomeJudgeTeeth] = useState({ ur: 100, ul: 0, lr: 0, ll: 100 })
  const [missTooth, setmissTooth] = useState([])
  const [attachmentTooth, setattachmentTooth] = useState([])
  const [openDialog, setopenDialog] = useState({
    open: false,
    callback: () => {
      return
    },
  })
  const [openDomePopup, setopenDomePopup] = useState(false)
  const [material, setmaterial] = useState({
    zendura: [],
    reva: [],
  })

  const [selectedMaterial, setselectedMaterial] = useState("")
  const [isUpperHasBiteramp, setIsUpperHasBiteramp] = useState(false)
  const [isLowerHasBiteramp, setIsLowerHasBiteramp] = useState(false)
  const [isBiteramp, setisBiteramp] = useState(false)

  const toothStyle = ({ path, status }) => {
    path?.setAttribute("stroke", "#37474F")
    path?.setAttribute("stroke-width", "1px")
    switch (status) {
      case "init":
        path?.setAttribute("fill", "white")

        break
      case "coverage-end":
        path?.setAttribute("fill", "#215ECD")
        break
      case "coverage":
        path?.setAttribute("fill", "rgba(33, 150, 243, 0.30)")
        break
      case "miss":
        path?.setAttribute("fill", "#CFD8DC")
        path?.setAttribute("stroke-dasharray", "2 2")
        break

      default:
        break
    }
  }

  const teethAction = (id: string) => {
    const dom = document.getElementById(id)?.getElementsByTagName("div")
    if (!dom) return

    const [_, _div, _svg, ...menuDom] = dom

    // selected
    _div.onmouseup = (e) => {
      const path = e?.currentTarget?.getElementsByTagName("path")[0]

      const res = [...selectedTeethid]
     
      const selectedId = Number(e?.currentTarget?.parentNode?.children[0]?.id)
      let domePopup = false
      if (0 < selectedId && selectedId < 9) {
        // UR
        res[0] = selectedId
        if (selectedId > domeJudgeTeeth.ur) {
          domePopup = true
        }
      } else if (8 < selectedId && selectedId < 17) {
        // UL
        res[1] = selectedId
        if (selectedId < domeJudgeTeeth.ul) {
          domePopup = true
        }
      } else if (16 < selectedId && selectedId < 25) {
        // LL
        res[2] = selectedId
        if (selectedId > domeJudgeTeeth.ll) {
          domePopup = true
        }
      } else if (24 < selectedId && selectedId < 33) {
        // LR
        res[3] = selectedId
        if (selectedId < domeJudgeTeeth.lr) {
          domePopup = true
        }
      }

      if (domePopup) {
        setopenDomePopup(true)
        return
      }
      const isRight =
        (0 < selectedId && selectedId < 9) ||
        (24 < selectedId && selectedId < 33)

      const isfull =
        [2, 3, 14, 15, 18, 19, 30, 31].indexOf(selectedId) === -1 ||
        attachmentTooth.indexOf(selectedId) !== -1

      let isopenPopup = false
      for (let index = 0; index < attachmentTooth.length; index++) {
        const attachmentId = attachmentTooth[index]
        // UR
        if (
          0 < selectedId &&
          selectedId < 9 &&
          0 < attachmentId &&
          attachmentId < 9
        ) {
          isopenPopup = selectedId > attachmentId
          if (isopenPopup) break
        }
        // UL
        if (
          8 < selectedId &&
          selectedId < 17 &&
          8 < attachmentId &&
          attachmentId < 17
        ) {
          isopenPopup = selectedId < attachmentId
          if (isopenPopup) break
        }

        // LL
        if (
          16 < selectedId &&
          selectedId < 25 &&
          16 < attachmentId &&
          attachmentId < 25
        ) {
          isopenPopup = selectedId > attachmentId
          if (isopenPopup) break
        }
        // LR
        if (
          24 < selectedId &&
          selectedId < 33 &&
          24 < attachmentId &&
          attachmentId < 33
        ) {
          isopenPopup = selectedId < attachmentId
          if (isopenPopup) break
        }
      }
      if (isopenPopup) {
        setopenDialog({
          open: true,
          callback: () => {
            toothStyle({ path, status: "coverage-end" })
            setselectedTeethid([...res])
            const isRight =
              (0 < selectedId && selectedId < 9) ||
              (24 < selectedId && selectedId < 33)

            const isfull =
              [2, 3, 14, 15, 18, 19, 30, 31].indexOf(selectedId) === -1 ||
              attachmentTooth.indexOf(selectedId) !== -1
            if (!isRight && isfull && isUpper) {
              const res = [...radioLeft]
              res[0] = "1"
              setradioLeft([...res])
            }
            if (isRight && isfull && isUpper) {
              const res = [...radioRight]
              res[0] = "1"
              setradioRight([...res])
            }
            if (!isRight && isfull && !isUpper) {
              const res = [...radioLeft]
              res[1] = "1"
              setradioLeft([...res])
            }
            if (isRight && isfull && !isUpper) {
              const res = [...radioRight]
              res[1] = "1"
              setradioRight([...res])
            }
          },
        })
        return
      }

      toothStyle({ path, status: "coverage-end" })
      setselectedTeethid([...res])

      if (!isRight && isfull && isUpper) {
        const res = [...radioLeft]
        res[0] = "1"
        setradioLeft([...res])
      }
      if (isRight && isfull && isUpper) {
        const res = [...radioRight]
        res[0] = "1"
        setradioRight([...res])
      }
      if (!isRight && isfull && !isUpper) {
        const res = [...radioLeft]
        res[1] = "1"
        setradioLeft([...res])
      }
      if (isRight && isfull && !isUpper) {
        const res = [...radioRight]
        res[1] = "1"
        setradioRight([...res])
      }
    }
  }
  const getUpperRightTooth = () => {
    const res = []
    for (let index = 8; index > 0; index--) {
      res.push(
        <Box
          key={"ul" + index}
          id={"ul" + index + "box"}
          sx={{ visibility: isUpper ? "visible" : "hidden" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              left: position["ul" + index][0],
              top: position["ul" + index][1],
            }}
          >
            <ReactSVG
              id={9 - index}
              src={
                selectedTeethid[0] === 9 - index
                  ? radioRight[0] === "1"
                    ? svgList["ul" + index]
                    : radioRight[0] === "0.75"
                      ? svgList["ul" + index + "_075"]
                      : radioRight[0] === "0.5"
                        ? svgList["ul" + index + "_05"]
                        : radioRight[0] === "0.25" && svgList["ul" + index + "_025"]
                  : svgList["ul" + index]
              }
              afterInjection={(svg) => {
                teethAction("ul" + index + "box")
                if (selectedTeethid[0] === 9 - index && radioRight[0] !== "1")
                  return
                const path = svg.getElementsByTagName("path")[0]
                if (coverageTooth.indexOf(9 - index) !== -1) {
                  toothStyle({ path, status: "coverage" })
                } else if (selectedTeethid.indexOf(9 - index) !== -1) {
                  toothStyle({ path, status: "coverage-end" })
                } else if (missTooth.indexOf(9 - index) !== -1) {
                  toothStyle({ path, status: "miss" })
                } else {
                  toothStyle({ path, status: "init" })
                }
              }}
            />
            {missTooth.indexOf(9 - index) !== -1 && (
              <ReactSVG
                src={miss}
                afterInjection={(svg) => {
                  const path = svg.getElementsByTagName("path")[0]
                  path?.setAttribute("fill", "#455A64")
                  svg.setAttribute("width", "18")
                  svg.setAttribute("height", "18")
                }}
                style={{
                  marginTop: -0.4,
                  marginLeft: 1.5,
                  position: "absolute",
                }}
              />
            )}
          </Box>
        </Box>,
      )
    }
    return res
  }

  const getUpperLeftTooth = () => {
    const res = []

    for (let index = 8; index > 0; index--) {
      res.push(
        <Box
          key={"ur" + index}
          id={"ur" + index + "box"}
          sx={{ visibility: isUpper ? "visible" : "hidden" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              right: position["ur" + index][0],
              top: position["ur" + index][1],
            }}
          >
            <ReactSVG
              id={index + 8}
              src={
                selectedTeethid[1] === 8 + index
                  ? radioLeft[0] === "1"
                    ? svgList["ur" + index]
                    : radioLeft[0] === "0.75"
                      ? svgList["ur" + index + "_075"]
                      : radioLeft[0] === "0.5"
                        ? svgList["ur" + index + "_05"]
                        : radioLeft[0] === "0.25" && svgList["ur" + index + "_025"]
                  : svgList["ur" + index]
              }
              afterInjection={(svg) => {
                teethAction("ur" + index + "box")
                if (selectedTeethid[1] === 8 + index && radioLeft[0] !== "1")
                  return
                const path = svg.getElementsByTagName("path")[0]
                if (coverageTooth.indexOf(8 + index) !== -1) {
                  toothStyle({ path, status: "coverage" })
                } else if (selectedTeethid.indexOf(8 + index) !== -1) {
                  toothStyle({ path, status: "coverage-end" })
                } else if (missTooth.indexOf(8 + index) !== -1) {
                  toothStyle({ path, status: "miss" })
                } else {
                  toothStyle({ path, status: "init" })
                }
              }}
            />

            {missTooth.indexOf(8 + index) !== -1 && (
              <ReactSVG
                src={miss}
                afterInjection={(svg) => {
                  const path = svg.getElementsByTagName("path")[0]
                  path?.setAttribute("fill", "#455A64")
                  svg.setAttribute("width", "18")
                  svg.setAttribute("height", "18")
                }}
                style={{
                  marginTop: -0.4,
                  marginLeft: 1.5,
                  position: "absolute",
                }}
              />
            )}
          </Box>
        </Box>,
      )
    }
    return res
  }

  const getLowerRightTooth = () => {
    const res = []

    for (let index = 8; index > 0; index--) {
      res.push(
        <Box
          key={"dl" + index}
          id={"dl" + index + "box"}
          sx={{ visibility: !isUpper ? "visible" : "hidden" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              left: position["dl" + index][0],
              bottom: position["dl" + index][1],
            }}
          >
            <ReactSVG
              id={24 + index}
              src={
                selectedTeethid[3] === 24 + index
                  ? radioRight[1] === "1"
                    ? svgList["dl" + index]
                    : radioRight[1] === "0.75"
                      ? svgList["dl" + index + "_075"]
                      : radioRight[1] === "0.5"
                        ? svgList["dl" + index + "_05"]
                        : radioRight[1] === "0.25" && svgList["dl" + index + "_025"]
                  : svgList["dl" + index]
              }
              afterInjection={(svg) => {
                teethAction("dl" + index + "box")
                if (selectedTeethid[3] === 24 + index && radioRight[1] !== "1")
                  return
                const path = svg.getElementsByTagName("path")[0]
                if (coverageTooth.indexOf(24 + index) !== -1) {
                  toothStyle({ path, status: "coverage" })
                } else if (selectedTeethid.indexOf(24 + index) !== -1) {
                  toothStyle({ path, status: "coverage-end" })
                } else if (missTooth.indexOf(24 + index) !== -1) {
                  toothStyle({ path, status: "miss" })
                } else {
                  toothStyle({ path, status: "init" })
                }
              }}
            />

            {missTooth.indexOf(24 + index) !== -1 && (
              <ReactSVG
                src={miss}
                afterInjection={(svg) => {
                  const path = svg.getElementsByTagName("path")[0]
                  path?.setAttribute("fill", "#455A64")
                  svg.setAttribute("width", "18")
                  svg.setAttribute("height", "18")
                }}
                style={{
                  marginTop: -0.4,
                  marginLeft: 1.5,
                  position: "absolute",
                }}
              />
            )}
          </Box>
        </Box>,
      )
    }
    return res
  }

  const getLowerLeftTooth = () => {
    const res = []

    for (let index = 8; index > 0; index--) {
      res.push(
        <Box
          key={"dr" + index}
          id={"dr" + index + "box"}
          sx={{ visibility: !isUpper ? "visible" : "hidden" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              right: position["dr" + index][0],
              bottom: position["dr" + index][1],
            }}
          >
            <ReactSVG
              id={25 - index}
              src={
                selectedTeethid[2] === 25 - index
                  ? radioLeft[1] === "1"
                    ? svgList["dr" + index]
                    : radioLeft[1] === "0.75"
                      ? svgList["dr" + index + "_075"]
                      : radioLeft[1] === "0.5"
                        ? svgList["dr" + index + "_05"]
                        : radioLeft[1] === "0.25" && svgList["dr" + index + "_025"]
                  : svgList["dr" + index]
              }
              afterInjection={(svg) => {
                teethAction("dr" + index + "box")
                if (selectedTeethid[2] === 25 - index && radioLeft[1] !== "1")
                  return
                const path = svg.getElementsByTagName("path")[0]
                if (coverageTooth.indexOf(25 - index) !== -1) {
                  toothStyle({ path, status: "coverage" })
                } else if (selectedTeethid.indexOf(25 - index) !== -1) {
                  toothStyle({ path, status: "coverage-end" })
                } else if (missTooth.indexOf(25 - index) !== -1) {
                  toothStyle({ path, status: "miss" })
                } else {
                  toothStyle({ path, status: "init" })
                }
              }}
            />

            {missTooth.indexOf(25 - index) !== -1 && (
              <ReactSVG
                src={miss}
                afterInjection={(svg) => {
                  const path = svg.getElementsByTagName("path")[0]
                  path?.setAttribute("fill", "#455A64")
                  svg.setAttribute("width", "18")
                  svg.setAttribute("height", "18")
                }}
                style={{
                  marginTop: -0.4,
                  marginLeft: 1.5,
                  position: "absolute",
                }}
              />
            )}
          </Box>
        </Box>,
      )
    }
    return res
  }

  useEffect(() => {
    if (!wasmReady) return
    viewControlInTreatment.showArchMode(isUpper ? "up" : "low")
    viewControlInTreatment.setViewType("split")
    const { upDomeList, lowDomeList } = toothModule.domeList()
    const judgeList = { ur: 100, ul: 0, lr: 0, ll: 100 }
    for(const id of [...upDomeList, ...lowDomeList]){
      if (0 < id && id < 9){
        if (id < judgeList.ur) {
          judgeList.ur = id
        }
      }else if(8 < id && id < 17){
        if (id > judgeList.ul) {
          judgeList.ul = id  
        }
      }else if(16 < id && id < 25){
        if (id < judgeList.ll) {
          judgeList.ll = id
        }
      }else if(24 < id && id < 33){
        if (id > judgeList.lr) {
          judgeList.lr = id
        }
      }
    }
    setdomeJudgeTeeth(judgeList)
  }, [isUpper, wasmReady])

  useEffect(() => {
    if (!wasmReady) return
    viewControlInTreatment.zoomWithValueInTreatment(zoomValue)
  }, [zoomValue, wasmReady])

  useEffect(() => {
    const toothList = []
    for (let index = 0; index < selectedTeethid.length; index++) {
      const id = selectedTeethid[index]
      if (0 < id && id < 9) {
        for (let i = 8; i > id; i--) {
          toothList.push(i)
        }
      } else if (8 < id && id < 17) {
        for (let i = 9; i < id; i++) {
          toothList.push(i)
        }
      } else if (16 < id && id < 25) {
        for (let i = 24; i > id; i--) {
          toothList.push(i)
        }
      } else if (24 < id && id < 33) {
        for (let i = 25; i < id; i++) {
          toothList.push(i)
        }
      }
    }
    setcoverageTooth([...toothList])
  }, [selectedTeethid])

  useEffect(() => {
    if (wasmReady) {
      //enable BiteRampModule
      wasmModule.moduleManager.SwitchBiteRampModule(true)

      //find Upper has biteramp
      const isUpperHaveBiteRamp = biteRampModule.isHaveBiteRampOnArch(true)
      setIsUpperHasBiteramp(isUpperHaveBiteRamp)
      //find Lower has biteramp
      const isLowerHaveBiteRamp = biteRampModule.isHaveBiteRampOnArch(false)
      setIsLowerHasBiteramp(isLowerHaveBiteRamp)
    }
  }, [wasmReady])

  useEffect(() => {
    if (wasmReady) {
      orderView.initAlignerCoverageViewInOrderPage([
        36 / 255,
        46 / 255,
        51 / 255,
      ])
      const {
        attachment,
        toothlist: { lowlist, uplist },
        ipr,
      } = caseManagement.getAttachmentIPRReport()
      const _lowlist = lowlist
        .sort((a, b) => {
          const _a = a % 100, _b = b % 100
          return _a > _b ? -1 : 1
        })
      const _uplist = uplist
        .sort((a, b) => {
          const _a = a % 100, _b = b % 100
          return _a < _b ? -1 : 1
        })
      const attachments = []
      for (const key in attachment) {
        if (attachment[key].attachment.length > 0)
          attachments.push(attachment[key].toothId)
      }
      for (const key in ipr) {
        if (ipr[key].length !== 0 && attachments.indexOf(Number(key)) === -1)
          attachments.push(Number(key))
      }

      const missTooth = [],
        selectedTooth = [
          _uplist[0],
          _uplist[_uplist.length - 1],
          _lowlist[_lowlist.length - 1],
          _lowlist[0],
        ]
      for (let index = 1; index < 33; index++) {
        if (
          uplist.indexOf(index) === -1 &&
          lowlist.indexOf(index) === -1 &&
          uplist.indexOf(index + 500) === -1 &&
          lowlist.indexOf(index + 500) === -1
        ) {
          missTooth.push(index)
        }
      }
      setattachmentTooth(attachments)
      setmissTooth([...missTooth])
      setselectedTeethid([...selectedTooth])
    }
    if (!isEmpty(coverage)) {
      const {
        coverageTooth = [],
        selectedTeethid = [],
        radioLeft = ["1", "1"],
        radioRight = ["1", "1"],
        material,
      } = coverage

      setcoverageTooth(coverageTooth)
      setselectedTeethid(selectedTeethid)
      setradioLeft(radioLeft)
      setradioRight(radioRight)
      setselectedMaterial(material)
      return
    }
  }, [])

  useEffect(() => {
    if (materials.retainer.length > 0) {
      const zendura = []
      const reva = []

      materials.retainer.map(({ name, thickness }) => {
        if (name === "zendura") {
          zendura.push(thickness / 1000)
        }
        if (name === "reva") {
          reva.push(thickness / 1000)
        }
      })

      setmaterial({ zendura, reva })
      if (coverage?.material) return
      if (!isEmpty(zendura)) setselectedMaterial(zendura[0])
      else setselectedMaterial("reva")
    }
  }, [materials])

  useEffect(() => {
    if (!wasmReady) return
    viewControlInTreatment.setZoomCallbackInTreatment(setzoomValue)
    // caseManagement.setBackgroundColor(36 / 255, 46 / 255, 51 / 255)
    caseManagement.setBackgroundPic("backgroundvtk.png")
  }, [wasmReady])

  useEffect(() => {
    if (coverage?.keepRamp) {
      setisBiteramp(true)
    }
  }, [])

  useDeepEffect(() => {
    if (isEmpty(coverageTooth) && isEmpty(selectedTeethid)) return
    const {
      attachment,
      toothlist: { lowlist, uplist },
      ipr,
    } = caseManagement.getAttachmentIPRReport()
    const toothList = [...lowlist, ...uplist]
    const _cover = coverageTooth.map((item) => {
      if (
        toothList.indexOf(item) === -1 &&
        toothList.indexOf(item + 500) !== -1
      ) {
        return item + 500
      }
      return item
    })
    const _select = selectedTeethid.map((item) => {
      if (
        toothList.indexOf(item) === -1 &&
        toothList.indexOf(item + 500) !== -1
      ) {
        return item + 500
      }
      return item
    })
    dispatch(
      updateCustomization({
        coverage: {
          coverageTooth: _cover,
          selectedTeethid: _select,
          radioLeft,
          radioRight,
          material: selectedMaterial,
          keepRamp: isBiteramp,
        },
      }),
    )
  }, [
    coverageTooth,
    selectedTeethid,
    radioLeft,
    radioRight,
    selectedMaterial,
    isBiteramp,
  ])

  const MaterialComponent = () => {
    // if (r_up === 0 && r_low === 0) {
    //   return <></>
    // }
    //TODO: have to check hanon and remove this
    // if (!isUpper && r_low === 0) {
    //   return <></>
    // }
    // if (isUpper && r_up === 0) {
    //   return <></>
    // }
    // if (isEmpty(material.zendura)) {
    //   return <></>
    // }
    // if (isEmpty(material.reva)) {
    //   return (
    //     <Box id="material">
    //       <Box sx={{ marginTop: 2, marginBottom: 2 }}>
    //         <Box sx={{ marginTop: 2, marginBottom: 2 }}>
    //           <UText variant={"h6"}>Material</UText>
    //         </Box>
    //         <UText variant={"subtitle2"}>
    //           Choose material for your order
    //         </UText>
    //         <Container sx={{ padding: 2 }}>
    //           <RadioGroup
    //             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
    //               setselectedMaterial((event.target as HTMLInputElement).value)
    //             }}
    //             defaultValue={material.zendura[0]}
    //           >
    //             <FormLabel>Select thickness of retainers</FormLabel>
    //             {material.zendura &&
    //               material.zendura.map((item, index) => {
    //                 return (
    //                   <FormControlLabel
    //                     key={index}
    //                     value={item}
    //                     control={<Radio />}
    //                     label={item + "”"}
    //                   />
    //                 )
    //               })}
    //           </RadioGroup>
    //         </Container>
    //       </Box>
    //     </Box>
    //   )
    // }
    return (
      (material.reva.length > 0 || material.zendura.length > 0) && (
        <Box id="material">
          <Box sx={{ marginTop: 2, marginBottom: 2 }}>
            <Box sx={{ marginTop: 2, marginBottom: 2 }}>
              <UText variant={"h6"}>Material</UText>
            </Box>
            {material.reva.length > 0 && material.zendura.length > 0 && (
              <>
                <UText variant={"subtitle2"}>
                  Choose material for your order
                </UText>
                <Container sx={{ padding: 2 }}>
                  <RadioGroup
                    value={coverage?.material}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.value === "reva") {
                        setselectedMaterial(
                          (event.target as HTMLInputElement).value,
                        )
                      } else {
                        setselectedMaterial(material.zendura[0])
                      }
                    }}
                  >
                    <FormControlLabel
                      value={material.zendura[0]}
                      control={<Radio />}
                      label={"Zendura"}
                    />

                    {/* {selectedMaterial !== "reva" && (
                <RadioGroup
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setselectedMaterial(
                      (event.target as HTMLInputElement).value,
                    )
                  }}
                  sx={{ padding: "0 42px" }}
                  defaultValue={material.zendura[0]}
                >
                  <FormLabel>Select thickness of retainers</FormLabel>
                  {material.zendura &&
                    material.zendura.map((item, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={item}
                          control={<Radio />}
                          label={item + "”"}
                        />
                      )
                    })}
                </RadioGroup>
              )} */}

                    <FormControlLabel
                      value={"reva"}
                      control={<Radio />}
                      label={"Reva"}
                    />
                  </RadioGroup>
                </Container>
              </>
            )}
            {material.reva.length > 0 && material.zendura.length === 0 && (
              <UText variant={"subtitle2"}>
                Your order will be made in Reva
              </UText>
            )}
            {material.reva.length === 0 && material.zendura.length && (
              <UText variant={"subtitle2"}>
                Your order will be made in Zendura
              </UText>
            )}
          </Box>
        </Box>
      )
    )
  }

  return (
    <>
      <ULoading isLoading={!wasmReady} />

      <Box id="coverage" sx={{ marginTop: 0 }}>
        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <UText variant={"h6"}>Coverage</UText>
        </Box>
        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <UText variant={"subtitle2"}>
            Choose the terminal teeth that you want to cover:
          </UText>
        </Box>
        <Box sx={{ margin: "16px 0px", textAlign: "center" }}>
          <UText variant="body1">{isUpper ? "Upper Arch" : "Lower Arch"}</UText>
        </Box>
        <Box
          sx={{
            padding: 2,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Box id="left">
            <FormLabel id="demo-error-radios">
              Aligner coverage (Right)
            </FormLabel>
            <RadioGroup
              value={isUpper ? radioRight[0] : radioRight[1]}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const res = [...radioRight]
                if (isUpper) {
                  res[0] = (event.target as HTMLInputElement).value
                } else {
                  res[1] = (event.target as HTMLInputElement).value
                }
                setradioRight([...res])
              }}
            >
              <FormControlLabel
                sx={{ marginLeft: "125px" }}
                value="1"
                control={<Radio />}
                label="Full"
              />
              <FormControlLabel
                sx={{ marginLeft: "125px" }}
                value="0.75"
                control={<Radio />}
                label="3/4"
                disabled={
                  isUpper
                    ? [1, 2, 3].indexOf(selectedTeethid[0]) === -1
                    : [30, 31, 32].indexOf(selectedTeethid[3]) === -1
                }
              />
              <FormControlLabel
                sx={{ marginLeft: "125px" }}
                value="0.5"
                control={<Radio />}
                label="1/2"
                disabled={
                  isUpper
                    ? [1, 2, 3].indexOf(selectedTeethid[0]) === -1
                    : [30, 31, 32].indexOf(selectedTeethid[3]) === -1
                }
              />
              <FormControlLabel
                sx={{ marginLeft: "125px" }}
                value="0.25"
                control={<Radio />}
                label="1/4"
                disabled={
                  isUpper
                    ? [1, 2, 3].indexOf(selectedTeethid[0]) === -1
                    : [30, 31, 32].indexOf(selectedTeethid[3]) === -1
                }
              />
            </RadioGroup>
          </Box>
          <Box
            id="center"
            sx={{
              width: 369,
              height: 255,
              padding: 2,
              position: "relative",
            }}
          >
            {getUpperLeftTooth()}
            {getUpperRightTooth()}
            {getLowerLeftTooth()}
            {getLowerRightTooth()}
          </Box>
          <Box id="right">
            <FormLabel id="demo-error-radios">
              Aligner coverage (Left)
            </FormLabel>
            <RadioGroup
              value={isUpper ? radioLeft[0] : radioLeft[1]}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const res = [...radioLeft]
                if (isUpper) {
                  res[0] = (event.target as HTMLInputElement).value
                } else {
                  res[1] = (event.target as HTMLInputElement).value
                }
                setradioLeft([...res])
              }}
            >
              <FormControlLabel value="1" control={<Radio />} label="Full" />
              <FormControlLabel
                value="0.75"
                control={<Radio />}
                label="3/4"
                disabled={
                  isUpper
                    ? [14, 15, 16].indexOf(selectedTeethid[1]) === -1
                    : [17, 18, 19].indexOf(selectedTeethid[2]) === -1
                }
              />
              <FormControlLabel
                value="0.5"
                control={<Radio />}
                label="1/2"
                disabled={
                  isUpper
                    ? [14, 15, 16].indexOf(selectedTeethid[1]) === -1
                    : [17, 18, 19].indexOf(selectedTeethid[2]) === -1
                }
              />
              <FormControlLabel
                value="0.25"
                control={<Radio />}
                label="1/4"
                disabled={
                  isUpper
                    ? [14, 15, 16].indexOf(selectedTeethid[1]) === -1
                    : [17, 18, 19].indexOf(selectedTeethid[2]) === -1
                }
              />
            </RadioGroup>
          </Box>
        </Box>
        <Dialog
          open={openDialog.open}
          onClose={() => {
            setopenDialog({
              open: false,
              callback: () => {
                return
              },
            })
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: "rgba(0, 0, 0, 0.87)" }}
            >
              There are attachments or auxiliaries distal to this tooth. Do
              you wish to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setopenDialog({
                  open: false,
                  callback: () => {
                    return
                  },
                })
              }}
              variant="contained"
              size="medium"
              sx={{
                textTransform: "uppercase",
                borderRadius: "4px",
                background: "#E0E0E0",
                color: "rgba(0, 0, 0, 0.87)",
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                openDialog.callback()
                setopenDialog({
                  open: false,
                  callback: () => {
                    return
                  },
                })
              }}
              size="medium"
              variant="contained"
              sx={{ textTransform: "uppercase" }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDomePopup}
          onClose={() => {
            setopenDomePopup(false)
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: "rgba(0, 0, 0, 0.87)" }}
            >
              Skipping teeth with Dome is not allowed.Please reselect.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setopenDomePopup(false)
              }}
              size="medium"
              variant="contained"
              sx={{ textTransform: "uppercase" }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        
      </Box>

      {MaterialComponent()}
      {(!!(isUpperHasBiteramp && r_up) || !!(isLowerHasBiteramp && r_low)) && (
        <Box>
          <UText variant={"h6"}>{t("coverage.title")}</UText>
          <Grid sx={{ ml: "10px", pt: 1, mt: "10px", pl: "14px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={isBiteramp}
                  onChange={() => setisBiteramp(!isBiteramp)}
                />
              }
              label={
                <UText variant={"body1"}>{t("coverage.description")}</UText>
              }
            />
          </Grid>
        </Box>
      )}
    </>
  )
}

export default Coverage
