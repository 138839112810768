import { FC, useEffect, useState } from "react"
import { isEmpty } from "lodash"

import { preview } from "@/gluelayer"

import low from "../../assets/svgs/lower.svg"
import up from "../../assets/svgs/upper.svg"
import add from "../../assets/svgs/add.svg"
import minus from "../../assets/svgs/minus.svg"
import { UText } from "../../ui-component/components"
import { Box, Grid } from "../../ui-component/mui.components"
import WasmCanves from "../WasmView/wasmCanves"
import { initializeWASM } from "@/core/app/slices/clinical/clinicalThunkApi"
import { useAppDispatch } from "@/core/app/hooks"
import Button from "@mui/material/Button";

import {
  canvas,
  model,
  tool,
  view,
  viewbox,
  zoom,
  zoomin,
  zoomout,
} from "./preview.style"
import SvgBtn from "../DarkMode/SvgButton/svgbtn"
import { setHasUploadSTLs, setIsPreviewModelLoaded } from "@/UDTreat/udTreatSlice"
/**
 * @param upperArchType:   Aligner = 0, Retainer = 1,RetainerMove = 2,
 * @param lowerArchType
 */

interface previewType {
  upperStl: {
    data: File | null
    action: "init" | "stl" | "download" | "delete"
  }
  sx?: object
  lowerStl: {
    data: File | null
    action: "init" | "stl" | "download" | "delete"
  }
  fillholeCallback?: () => void
  placeHolder?: string
  zips: Record<string, File>
  setUpperStl?: (data: {
    data: File | null
    action: "init" | "stl" | "download" | "delete"
  }) => void
  setLowerStl?: (data: {
    data: File | null
    action: "init" | "stl" | "download" | "delete"
  }) => void
  upperArchType?: 0 | 1 | 2 | null
  lowerArchType?: 0 | 1 | 2 | null
  isShowRefinement: boolean
}

const Preview: FC = ({
  sx = {},
  upperStl = { data: null, action: "init" },
  lowerStl = { data: null, action: "init" },
  fillholeCallback,
  placeHolder = "",
  zips,
  setUpperStl,
  setLowerStl,
  upperArchType = null,
  lowerArchType = null,
  isShowRefinement,
}: previewType) => {
  const dispatch = useAppDispatch()
  const [activeArch, setactiveArch] = useState("both")
  const [currentZoomValue, setcurrentZoomValue] = useState(1.125)
  const [previewState, setPreviewState] = useState(false)

  useEffect(() => {
    console.log("🚀 ~ ; ~ isShowRefinement:", isShowRefinement)
    ;(async () => {
      const canvasElem = document.getElementById("canvas") as HTMLCanvasElement
      await dispatch(initializeWASM({ canvas: canvasElem }))
      console.time("prewInit")
      preview.drawSTLWithoutCanvas({
        upperArch: null,
        lowerArch: null,
        canvas: canvasElem,
        zoomRange: [0.25, 2],
        isShowRefinement,
      })

      // 在drawSTLWithoutCanvas中会初始化scanpreview。初始化scanpreview的方法不会返回，并且会启动一个渲染循环,这里并不知道scanpreview module是否初始化完成,所以需要等待,后面所有的方法需要依赖这个模块,这里不等待有可能在它没初始化完就去访问，而导致错误。
      await new Promise((resolve) => {
        setTimeout(() => {
          console.timeEnd("prewInit")
          resolve(true)
        }, 500)
      })
      preview.setZoomCallback((value) => {
        setcurrentZoomValue(value)
      })
      drawMtc()
      setPreviewState(true)
    })()

    return () => {
      preview.clearPreview()
    }
  }, [])
  useEffect(() => {
    if (!previewState || (upperArchType === null && lowerArchType === null))
      return
    preview.saveArchType(upperArchType, lowerArchType)
  }, [upperArchType, lowerArchType, previewState])

  useEffect(() => {
    if (
      (lowerStl.action === "init" && upperStl.action === "init") ||
      !previewState
    )
      return
    preview.setZoomCallback((value) => {
      setcurrentZoomValue(value)
    })

    preview.drawSTLWithoutCanvas({
      upperArch: upperStl.data,
      lowerArch: lowerStl.data,
      canvas: document.getElementById("canvas") as HTMLCanvasElement,
      zoomRange: [0.25, 2],
      isShowRefinement,
    })
  }, [lowerStl.action, upperStl.action, previewState])

  const drawMtc = () => {
    //console.log('preview ok start draw mtc!!!',previewState,zips,isEmpty(zips),upperStl.action,lowerStl.action)
    if (
      previewState &&
      !isEmpty(zips) &&
      upperStl.action === "init" &&
      lowerStl.action === "init"
    ) {
      preview.setZoomCallback((value) => {
        setcurrentZoomValue(value)
      })
      console.log("drawMtcFromZips from preview")

      preview
        .drawMtcFromZips(zips, [0.25, 2.0], isShowRefinement)
        .then((res) => {
          if (!res) {
            return
          }
          const upperModel = res.get(isShowRefinement ? "arch_u.mtc" : "arch_o_u.mtc")
          const lowerModel = res.get(isShowRefinement ? "arch_l.mtc" : "arch_o_l.mtc")

          dispatch(setHasUploadSTLs([!!upperModel, !!lowerModel]))
          dispatch(setIsPreviewModelLoaded(true))

          console.log("🚀 ~ preview.drawMtcFromZips ~ res:", res)
          setUpperStl &&
          upperModel &&
            setUpperStl({
              data:
              upperModel ||
                null,
              action: "download",
            })
          setLowerStl &&
          lowerModel &&
            setLowerStl({
              data:
              lowerModel ||
                null,
              action: "download",
            })
        })
    }
  }

  useEffect(() => {
    drawMtc()
  }, [zips, previewState])

  useEffect(() => {
    if (previewState) preview.zoomWithValue(currentZoomValue)
  }, [currentZoomValue])

  const ToolBar = (
    <Grid
      container
      sx={{
        ...tool,
      }}
    >
      <Grid sx={{ ...view, backgroundColor: "rgba(240,240,240)" }}>
        <SvgBtn
          svg={up}
          clickFun={() => {
            if (!upperStl.data) {
              return
            }
            if (activeArch === "up") {
              preview.changeArchMode("both")
              setactiveArch("both")
            } else {
              preview.changeArchMode("up")
              setactiveArch("up")
            }
          }}
          // isdisable={getArchInfo().caseArch !== "both"}
          boxSx={{ ...viewbox, paddingBottom: "8px" }}
          afterInjection={(svg) => {
            const path = svg.getElementsByTagName("path")[0]
            if (activeArch === "up") {
              path?.setAttribute("fill", "#215ECD")
              path?.setAttribute("fill-opacity", "1")
            } else {
              path?.setAttribute("fill", "black")
              path?.setAttribute("fill-opacity", "0.56")
            }
          }}
        ></SvgBtn>
        <SvgBtn
          svg={low}
          clickFun={() => {
            if (!lowerStl.data) {
              return
            }
            if (activeArch === "low") {
              preview.changeArchMode("both")
              setactiveArch("both")
            } else {
              preview.changeArchMode("low")
              setactiveArch("low")
            }
          }}
          // isdisable={getArchInfo().caseArch !== "both"}
          boxSx={{ ...viewbox, paddingBottom: "8px" }}
          afterInjection={(svg) => {
            const path = svg.getElementsByTagName("path")[0]
            if (activeArch === "low") {
              path?.setAttribute("fill", "#215ECD")
              path?.setAttribute("fill-opacity", "1")
            } else {
              path?.setAttribute("fill", "black")
              path?.setAttribute("fill-opacity", "0.56")
            }
          }}
        ></SvgBtn>
      </Grid>

      <Grid container sx={{ ...view, backgroundColor: "rgba(240,240,240)" }}>
        <SvgBtn
          svg={minus}
          boxSx={{ ...viewbox, paddingBottom: "2px" }}
          clickFun={() => {
            if (upperStl.data || lowerStl.data) {
              if (currentZoomValue === 0.25) return
              setcurrentZoomValue(
                currentZoomValue - 0.25 < 0.25 ? 0.25 : currentZoomValue - 0.25,
              )
            }
          }}
        ></SvgBtn>
        <SvgBtn
          svg={add}
          boxSx={{ ...viewbox, paddingBottom: "2px" }}
          clickFun={() => {
            if (upperStl.data || lowerStl.data) {
              if (currentZoomValue === 2) return
              setcurrentZoomValue(
                currentZoomValue + 0.25 > 2 ? 2 : currentZoomValue + 0.25,
              )
            }
          }}
        ></SvgBtn>
      </Grid>
    </Grid>
  )
  return (
    <Grid container sx={{ ...model, ...sx, backgroundColor: "#A0A2A8" }}>
      {ToolBar}
      <WasmCanves style={canvas}></WasmCanves>
      <UText
        variant={"subtitle1"}
        sxProp={{
          position: "absolute",
          color: "rgba(255, 255, 255, 0.7)",
          top: 160,
        }}
      >
        {(upperStl.action === "init" || upperStl.action === "delete") &&
          (lowerStl.action === "init" || lowerStl.action === "delete") &&
          placeHolder}
      </UText>
    </Grid>
  )
}

export default Preview
