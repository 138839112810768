import { FC, lazy, Suspense, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Box, Grid } from "@mui/material"
import { useMyNavigation } from "@/hooks/useMyNavigation"

import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { getBundleByCaseId } from "../../../../core/app/slices/bundle/bundleThunkApi"
import { getOrdersByCaseId } from "../../../../core/app/slices/order/orderThunkApi"
import { RootState } from "../../../../core/app/store"
import { IBundle } from "../../../../core/model/interface/IBundle"
import { ICase } from "../../../../core/model/interface/ICase"
import { UI_PERMISSION } from "../../../../core/utils/access/permissionConstants"
import { dateFormat } from "../../../../core/utils/formatters"
import usePermissions from "../../../../hooks/userPermission"
import { UButton, ULoading, UText } from "../../../../ui-component"
import {
  getBtnActionsByStatus,
  RECORDS_STATUS,
  TABLE_VIEW_STATUS,
} from "../../config/menuAction.config"
import { CASE_STATUS } from "../../config/status.config"
import {
  BUNDLE_OVERVIEW_TABLE,
  CASE_OVERVIEW_TABLE,
  ORDER_OVERVIEW_TABLE,
} from "../caseTable.config"

import RejectedOverview from "./RejectedOverview"

const OverviewTableRenderer = lazy(() => import("./OverviewTableRenderer"))
const CaseSectionHeader = lazy(() => import("./CaseSectionHeader"))

const CaseOverview: FC<{
  index: number
  caseObj: ICase
  setIsAlertOpen: (
    type:
      | "OPEN_CASE_ARCHIVE_MODEL"
      | "OPEN_CANCEL_MODEL"
      | "addNewCase"
      | "CASE_CANNOT_OPEN"
      | "",
  ) => void
  executeAction: (action: string, caseObj: ICase) => void
}> = ({ index, caseObj, setIsAlertOpen, executeAction }) => {
  const { hasAny, doesNotHaveAny } = usePermissions()
  const { patientId } = useParams()
  const navigate = useMyNavigation()
  const { ucloud1_1 } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const { orderList } = useAppSelector((state: RootState) => state.orderService)
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const { bundleList } = useAppSelector(
    (state: RootState) => state.bundleService,
  )

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [orderData, setOrderData] = useState<any>([])
  const [bundleData, setBundleData] = useState<any>([])
  const [showOverview, setShowOverview] = useState<boolean>(!index)

  const actionList = getBtnActionsByStatus(
    caseObj.caseDisposition,
    undefined,
    undefined,
    !!(caseObj?.locked_by || caseObj?.locked_date),
  )

  useEffect(() => {
    if (!orderList[caseObj.id]) return
    setOrderData([...orderList[caseObj.id]])
  }, [orderList])

  useEffect(() => {
    if (!bundleList[caseObj.id]) return
    const bundleData = []
    bundleList[caseObj.id].map((data: IBundle) => {
      if (
        !(
          data.name &&
          data.name.toUpperCase().replaceAll(" ", "") === "ALACARTE"
        )
      ) {
        bundleData.push(data)
      }
    })
    setBundleData([...bundleData])
  }, [bundleList])

  useEffect(() => {
    if (RECORDS_STATUS.includes(caseObj.caseDisposition)) return
    dispatch(getBundleByCaseId(caseObj.id))
    dispatch(getOrdersByCaseId(caseObj.id))
  }, [])

  useEffect(() => {
    if (
      patientData?.status === "ARCHIVED" &&
      !RECORDS_STATUS?.includes(caseObj?.caseDisposition)
    ) {
      setIsAlertOpen("CASE_CANNOT_OPEN")
    }
  }, [patientData])

  const updateMenuByPermissions = (actions: any) => {
    if (doesNotHaveAny(UI_PERMISSION.UI_PATIENTS_ARCHIVE)) {
      actions = actions.filter(
        (item) => item.btnAction !== "OPEN_ARCHIVE_PATIENT_MODEL",
      )
    }
    if (doesNotHaveAny(UI_PERMISSION.UI_PATIENTS_UNARCHIVE)) {
      actions = actions.filter(
        (item) => item.btnAction !== "OPEN_UNARCHIVE_PATIENT_MODEL",
      )
    }

    if (doesNotHaveAny(UI_PERMISSION.UI_PATIENTS_CREATE)) {
      actions = actions.filter(
        (item) =>
          !["OPEN_RECORDS", "OPEN_PRESCRIPTION_FORM", "OPEN_SUMMARY"].includes(
            item.btnAction,
          ),
      )
    }

    return actions
  }

  return (
    <>
      {([
        ...TABLE_VIEW_STATUS,
        CASE_STATUS.SUBMITTED_TO_UASSIST,
        CASE_STATUS.PLANNING_IN_PROGRESS,
        CASE_STATUS.REJECTED,
        CASE_STATUS.ATTENTION_NEEDED,
        CASE_STATUS.MODIFICATION_IN_PROGRESS,
      ].includes(caseObj.caseDisposition) ||
        !ucloud1_1) && (
        <Suspense fallback={<ULoading isLoading={true} />}>
          <CaseSectionHeader
            caseObj={caseObj}
            patientId={patientId}
            setIsAlertOpen={setIsAlertOpen}
            index={index}
            showOverview={showOverview}
            setShowOverview={setShowOverview}
          />
        </Suspense>
      )}
      {(RECORDS_STATUS.includes(caseObj.caseDisposition) || showOverview) && (
        <>
          {!TABLE_VIEW_STATUS.includes(caseObj.caseDisposition) && (
            <Box
              sx={{
                padding: "24px 40px",
                minHeight: "220px",
                backgroundColor: "background.default",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "16px",
                mb: 3,
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {actionList && actionList.topCaption && (
                  <Grid item sx={{ mb: 2 }}>
                    <UText variant="body1" sxProp={{ color: "text.secondary" }}>
                      {actionList.topCaption.textContent}
                    </UText>
                  </Grid>
                )}
                {CASE_STATUS.REJECTED.includes(caseObj?.caseDisposition) && (
                  <RejectedOverview />
                )}
                {patientData.status !== "ARCHIVED" &&
                  caseObj.caseDisposition !== CASE_STATUS.REJECTED &&
                  actionList &&
                  updateMenuByPermissions(actionList.actions).map(
                    (btn, index) => {
                      return (
                        <Grid
                          item
                          key={index}
                          sx={{
                            mb: CASE_STATUS.REJECTED.includes(
                              caseObj?.caseDisposition,
                            )
                              ? 0
                              : 1,
                            gap: 1,
                          }}
                        >
                          <UButton
                            variant={btn.variant}
                            sxProp={{
                              ...btn.sxProps,
                              padding: "8px 22px",
                              height: "42px",
                            }}
                            btnText={btn.btnText}
                            onClickHandler={() => {
                              executeAction(btn.btnAction, caseObj)
                            }}
                          />
                        </Grid>
                      )
                    },
                  )}
                {caseObj.caseDisposition === CASE_STATUS.REJECTED &&
                  actionList &&
                  updateMenuByPermissions(actionList.actions).map(
                    (btn, index) => {
                      return (
                        <Grid
                          container
                          key={index}
                          sx={{
                            mb: CASE_STATUS.REJECTED.includes(
                              caseObj?.caseDisposition,
                            )
                              ? 0
                              : 1,
                            gap: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 2,
                          }}
                        >
                          <UButton
                            variant={btn.variant}
                            sxProp={{
                              ...btn.sxProps,
                              padding: "5px 4px",
                              height: "30px",
                              minWidth: "108px",
                              width: "fit-content",
                              border: "none",
                              fontSize: "13px",
                              mt: 0,
                            }}
                            btnText={btn.btnText}
                            onClickHandler={() => {
                              executeAction(btn.btnAction, caseObj)
                            }}
                          />
                          <UButton
                            variant="contained"
                            sxProp={{
                              height: 30,
                              width: 95,
                              padding: "4px 10px",
                              fontSize: "13px",
                            }}
                            btnText={t("overview.buttons.openCaseBtn")}
                            onClickHandler={() => {
                              navigate(
                                `/clinical/patient/${patientId}/case/${caseObj.id}/treat?from=overview`,
                              )
                            }}
                          />
                        </Grid>
                      )
                    },
                  )}
                {actionList &&
                  actionList.bottomCaption &&
                  (caseObj.submittedDate || caseObj.updateDate) && (
                    <Grid item sx={{ height: "20px" }}>
                      <UText
                        variant="caption"
                        color="rgb(0,0,0,0.38)"
                        sxProp={{ mt: 1 }}
                      >
                        {actionList.bottomCaption.textContent(
                          caseObj.submittedDate
                            ? dateFormat(
                                caseObj.submittedDate,
                                "MM/DD/YYYY",
                                "YYYY-MM-DDTh:mm:ss",
                              )
                            : dateFormat(
                                caseObj.updateDate,
                                "MM/DD/YYYY",
                                "YYYY-MM-DDTh:mm:ss",
                              ),
                        )}
                      </UText>
                    </Grid>
                  )}
              </Grid>
            </Box>
          )}
        </>
      )}

      <Box
        sx={{
          transition:
            "height 0.4s ease-out, opacity 0.4s ease-in, visibility 0.4s ease-in",
          opacity: showOverview ? 1 : 0,
          overflow: showOverview ? "visible" : "hidden",
          height: showOverview ? "100%" : 0,
        }}
      >
        {TABLE_VIEW_STATUS.includes(caseObj.caseDisposition) && (
          <>
            <Grid item>
              <Suspense fallback={<ULoading isLoading={true} />}>
                <OverviewTableRenderer
                  id={"caseOverview"}
                  tableConfig={CASE_OVERVIEW_TABLE}
                  tableData={[caseObj]}
                  executeAction={executeAction}
                  hideMenu={patientData?.status === "ARCHIVED"}
                />
              </Suspense>
            </Grid>
            {!!bundleData.length && (
              <Grid item>
                <Suspense fallback={<ULoading isLoading={true} />}>
                  <OverviewTableRenderer
                    id={"bundleOverview"}
                    tableConfig={BUNDLE_OVERVIEW_TABLE}
                    tableData={bundleData}
                    hideMenu
                  />
                </Suspense>
              </Grid>
            )}
            {!!orderData.length && hasAny(UI_PERMISSION.UI_ORDERS_VIEW) && (
              <Grid item>
                <Suspense fallback={<ULoading isLoading={true} />}>
                  <OverviewTableRenderer
                    id={"orderOverview"}
                    tableConfig={ORDER_OVERVIEW_TABLE}
                    tableData={orderData}
                    hideMenu
                  />
                </Suspense>
              </Grid>
            )}
          </>
        )}
      </Box>
    </>
  )
}

export default CaseOverview
