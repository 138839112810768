
const ZoomSettingsUiText = {
  "Windows": {
    "Zoom In / Out": "<bold>Ctrl</bold> and <bold>+ / -</bold>",
    "Reset to Default": "<bold>Ctrl</bold> and <bold>0</bold>",
  },
  "Mac": {
    "Zoom In / Out": "<bold>Cmd</bold> and <bold>+ / -</bold>",
    "Reset to Default": "<bold>Cmd Ctrl</bold> and <bold>0</bold>",
  }
}
export default ZoomSettingsUiText