import { createAsyncThunk } from "@reduxjs/toolkit"

import store from "../../store"

import { assignZenduraPromotionCode, getBundlePricing, getZenduraPromotionData } from "./pricing.service"

export const fetchBundlePricing = createAsyncThunk(
  "PricingService/fetchBundlePricing",
  async (_, { rejectWithValue }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return await getBundlePricing(orgId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchZenduraPromotionData = createAsyncThunk(
  "PricingService/fetchZenduraPromotionData",
  async (_, { rejectWithValue }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return await getZenduraPromotionData(orgId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const assignZenduraPromoCode = createAsyncThunk(
  "PricingService/assignZenduraPromoCode",
  async (_, { rejectWithValue }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return await assignZenduraPromotionCode(orgId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)