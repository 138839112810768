import { CASE_STATUS } from "../../modules/Patient/config/status.config"
import { dateFormat } from "../utils/formatters"

import Deserialize from "./interface/Deserialize"
import {
  IExistingPatientList,
  INewPatientData,
  IPatient,
  IPatientList,
} from "./interface/IPatient"

export default class Patient implements IPatient, Deserialize {
  id = null
  firstName = ""
  lastName = ""
  dob = ""
  address = ""
  street = ""
  city = ""
  state = ""
  zipCode = ""
  email = ""
  phoneNumber = ""
  countrycode = ""

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Deserialize(input: any): this {
    if (input) {
      if (input.id) {
        this.id = input.id
      }
      this.firstName = input.firstName
      this.lastName = input.lastName
      this.dob = input.dob
      this.address = input.address
      this.street = input.street
      this.city = input.city
      this.state = input.state
      this.zipCode = input.zipCode
      this.email = input.email
      this.phoneNumber = input.phoneNumber
      this.countrycode = input.countryCode
    }
    return this
  }
}

export class PatientList implements IPatientList, Deserialize {
  id: number
  order: {
    id: undefined
    statusId: undefined
    statusName: ""
    trackingNumber: undefined
  }
  caseId: undefined
  orgId: ""
  caseDisposition: ""
  updatedDate: ""
  assignedTo: 0
  user: { id: ""; firstName: ""; lastName: ""; preference: "" }
  patientId: undefined
  firstName: ""
  lastName: ""
  status: ""
  dob: ""
  udesign_json: string
  uassist: string
  treatmentName: string
  order_metric: {
    upper_print_ordered_stage_range_count: number
    lower_print_ordered_stage_range_count: number
    print_ordered_upper_retainer: boolean
    print_ordered_lower_retainer: boolean
    print_ordered_upper_template: boolean
    print_ordered_lower_template: boolean
  }[]
  plannedUpperArch: number
  plannedLowerArch: number

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private deserializeCaseId(input: any): void {
    if (input.case_id) {
      this.uassist = input.uassist_order ? "Yes" : "No"
      const caseType = input?.treatment_plan_name
      this.treatmentName = `#${input.case_id} | ${caseType} - ${dateFormat(
        input.created_date,
        "MM/DD/YYYY",
        "YYYY-MM-DDTh:mm:ss",
      )}`
      this.caseId = input.case_id
    }
  }

  private deserializeOrderMetrics(input: any): void {
    if (input?.order_metrics?.length) {
      const recentMetric = input.order_metrics[input.order_metrics.length - 1];
      this.order_metric = [{
        upper_print_ordered_stage_range_count: recentMetric.upper_print_ordered_stage_range_count,
        lower_print_ordered_stage_range_count: recentMetric.lower_print_ordered_stage_range_count,
        print_ordered_upper_retainer: recentMetric.print_ordered_upper_retainer,
        print_ordered_lower_retainer: recentMetric.print_ordered_lower_retainer,
        print_ordered_upper_template: recentMetric.print_ordered_upper_template,
        print_ordered_lower_template: recentMetric.print_ordered_lower_template,
      }]
    }
    this.plannedUpperArch = input.planned_upper_arch ?? 0
    this.plannedLowerArch = input.planned_lower_arch ?? 0
  }  

  private deserializeUser(input: any): void {
    if (input.assigned_to && input.assigned_to != 0 && input.user) {
      this.assignedTo = input.assigned_to
      this.user = {
        id: input.user.id,
        firstName: input.user.first_name,
        lastName: input.user.last_name,
        preference: input.user.preference,
      }
    }
  }

  private deserializePatient(input: any): void {
    if (input.patient_id && input.patient) {
      this.patientId = input.patient_id
      this.firstName = input.patient.first_name
      this.lastName = input.patient.last_name
      this.status = input.patient.status
      this.dob = input.patient.dob
    }
  }

  private deserializeOrder(input: any): void {
    if (input.orders && input.orders.length > 0) {
      this.order = {
        id: input.orders[0].id,
        statusId: input.orders[0].status_id,
        statusName: input.orders[0].status_name.type_name,
        trackingNumber: input.orders[0].tracking_no,
      }
    }
  }

  Deserialize(input: any): this {
    if (input) {
      this.id = input.patient_id
      this.deserializeCaseId(input)
      this.deserializeOrderMetrics(input)
      this.deserializeUser(input)
      this.deserializePatient(input)
      this.deserializeOrder(input)

      this.udesign_json = input.udesign_json
      this.orgId = input.org_id
      this.updatedDate = input.updated_date
      this.caseDisposition = input.case_disposition
        ? input.case_disposition
        : CASE_STATUS.FROM_UDESIGN
    }
    return this
  }
}
export class PatientData implements INewPatientData {
  id = ""
  firstName = ""
  lastName = ""
  email = ""
  dob = ""
  notes = ""
  country = ""
  phone = ""
  status = ""
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Deserialize(input: any): this {
    if (input) {
      if (input.id) {
        this.id = input.id
      }
      this.firstName = input.first_name
      this.lastName = input.last_name
      this.email = input.email
      this.dob = input.patient_dob
      this.notes = input.ref_notes
      this.country = input.country
      this.phone = input.phone
      this.status = input.status
    }
    return this
  }
}

export class ExistingPatientList implements IExistingPatientList, Deserialize {
  id: string
  firstName: string
  lastName: string
  orgId: string

  Deserialize(input: any): this {
    if (input) {
      this.id = input.id
      this.firstName = input.first_name
      this.lastName = input.last_name
      this.orgId = input.org_id
    }
    return this
  }
}
