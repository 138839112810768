import { FC, useEffect } from "react"
import { Outlet } from "react-router-dom"

import { Grid } from "../components/mui.components"
import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"

const Order: FC = () => {
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  useEffect(() => {  
    if (patientData && patientData.firstName && patientData.lastName) {
      document.title = `${patientData.firstName[0]}. ${patientData.lastName}`
    } else {
      document.title = "uDesign Cloud"
    }
  
    return () => {
      document.title = "uDesign Cloud"
    }
  }, [patientData])
  
  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      minWidth={"lg"}
      sx={{ minHeight: "85vh" }}
    >
      <Grid container maxWidth={"lg"} flexDirection={"column"}>
        <Outlet />
      </Grid>
    </Grid>
  )
}

export default Order
