import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Tab } from "@mui/material"
import { isEmpty } from "lodash"

import { caseManagement } from "@/gluelayer"

import WasmCanves from "../../../components/WasmView/wasmCanves"
import Zoom from "../../../components/Zoom/zoom"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import {
  fetchFileList,
  fetchFileZips,
} from "../../../core/app/slices/clinical/clinicalThunkApi"
import { updateCustomization } from "../../../core/app/slices/order/orderSlice"
import { fetchClinicalSettings } from "../../../core/app/slices/user/userApis"
import { RootState } from "../../../core/app/store"

import Coverage from "./Coverage"
import Recession from "./Recession"
import Scanned from "./Scanned"
import { getMaterialsAPI } from "@/core/app/slices/order/orderThunkApi"

const Customization: FC<{
  nextCustomizationStep: string
  cuStepTmp: number[]
  setCuStep: (arg: number[]) => void
}> = ({ nextCustomizationStep, cuStepTmp, setCuStep }) => {
  const [tabValue, settabValue] = useState("1")
  const dispatch = useAppDispatch()
  const { caseId, patientId } = useParams()
  const [isUpper, setisUpper] = useState(true)
  const [isLower, setisLower] = useState(false)
  const [wasmReady, setwasmReady] = useState(false)
  const [zoomValue, setzoomValue] = useState(1)
  const {
    clinicalService: { zipNames, zipList },
    orderService: {
      alignerOrderPayload: {
        coverage,
        materials,
        stage: {
          retainers: { up: r_up, low: r_low },
        },
      },
    },
  } = useAppSelector((state: RootState) => state)
  const {
    caseDetail: { udesign_category },
  } = useAppSelector((state: RootState) => state.caseService)

  const getArchInfo = () => {
    let caseArch = "both",
      UpperType = "A",
      LowerType = "A"

    if (!udesign_category) return { caseArch, UpperType, LowerType }
    UpperType = udesign_category.split("/")[0]

    LowerType = udesign_category.split("/")[1]

    if (UpperType.includes("--")) {
      caseArch = "lower"
    } else if (LowerType.includes("--")) {
      caseArch = "upper"
    }

    return { caseArch, UpperType, LowerType }
  }
  useEffect(() => {
    if (isEmpty(zipList)) {
      dispatch(
        fetchFileList({
          patientId,
          caseId,
          fileNames: [
            "bulk0",
            "bulk1",
            "bulk2",
            "bulk10",
            "storage.version.json",
          ],
        }),
      )
    }

    // get clinical setting data
    dispatch(fetchClinicalSettings())
    dispatch(getMaterialsAPI({ patientId, caseId }))
  }, [])

  useEffect(() => {
    settabValue(nextCustomizationStep)
    if (nextCustomizationStep === "3") {
      setCuStep([1, 2, 3])
    }
  }, [nextCustomizationStep])

  useEffect(() => {
    if (!wasmReady) return
    dispatch(
      updateCustomization({
        extra: caseManagement.getCaseExtraInfomation(),
      }),
    )
    if (!isEmpty(coverage)) return
    const {
      toothlist: { lowlist, uplist },
    } = caseManagement.getAttachmentIPRReport()
    const selectedTeethid = [
        uplist[0],
        uplist[uplist.length - 1],
        lowlist[lowlist.length - 1],
        lowlist[0],
      ],
      coverageTooth = [
        ...uplist.slice(1, uplist.length - 2),
        ...lowlist.slice(1, lowlist.length - 2),
      ]
    dispatch(
      updateCustomization({
        coverage: {
          coverageTooth,
          selectedTeethid,
          radioLeft: ["1", "1"],
          radioRight: ["1", "1"],
          material: "",
          keepRamp: false,
        },
      }),
    )
  }, [wasmReady])

  useEffect(() => {
    if (getArchInfo().UpperType === "R" && getArchInfo().LowerType === "R") {
      settabValue("1")
    }
  }, [udesign_category])

  useEffect(() => {
    if (tabValue === "1") {
      if (getArchInfo().caseArch === "lower") {
        setisUpper(false)
      } else {
        setisUpper(true)
      }
    } else {
      setisUpper(false)
      setisLower(false)
    }
  }, [tabValue, udesign_category])

  useEffect(() => {
    if (tabValue === "1") {
      setisUpper(!isLower)
    }
  }, [isLower])

  useEffect(() => {
    if (zipNames.length > 0 && isEmpty(zipList)) {
      dispatch(
        fetchFileZips({
          orgId: "",
          patientId,
          caseId,
          zipNames,
        }),
      )
    }
  }, [zipNames])

  useEffect(() => {
    if (isEmpty(zipList)) return
    caseManagement.openCaseOrSwitchCanvas(
      document.getElementById("canvas") as HTMLCanvasElement,
      zipList as any,
      () => {
        setwasmReady(true)
      },
    )
  }, [zipList])

  const moveCustomizationStep = (tabValue: string) => {
    settabValue(tabValue)
  }

  return (
    <Box
      sx={{
        width: "100%",
        padding: "26px 140px 66px",
        marginTop: "18px",
        marginBottom: "50px",
        background: "#FFF",
        borderRadius: 2,
      }}
    >
      <TabContext value={tabValue}>
        <TabList
          // onChange={(event: React.SyntheticEvent, newValue: string) => {
          //   settabValue(newValue)
          //   const tmp = JSON.parse(JSON.stringify(cuStepTmp))
          //   if (!tmp.includes(+newValue)) {
          //     tmp.push(+newValue)
          //   }
          //   tmp.sort((a, b) => a - b)
          //   setCuStep(tmp)
          // }}
          centered
        >
          <Tab label="COVERAGE & MATERIAL" value="1" />
          <Tab label="RECESSION FILL" value="2" />
          <Tab label="SCANNED BUTTONS" value="3" />
        </TabList>
        <TabPanel value={"1"}></TabPanel>
        <TabPanel value={"2"}></TabPanel>
        <TabPanel value={"3"}></TabPanel>
      </TabContext>
      <Box
        id="tooth-view"
        sx={{
          height: 380,
          width: "100%",
          padding: "10px 126px",
        }}
      >
        <Box
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
            background: "#455A64",
          }}
        >
          <Zoom
            setisUpper={setisUpper}
            setzoomValue={setzoomValue}
            setisLower={setisLower}
            zoomValue={zoomValue}
            isUpper={isUpper}
            isLower={isLower}
          ></Zoom>
          <WasmCanves
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              left: "0",
              top: "0",
            }}
          />
        </Box>
      </Box>
      {tabValue === "1" && wasmReady && (
        <Coverage
          isUpper={
            getArchInfo().caseArch === "both"
              ? isUpper
              : getArchInfo().caseArch === "lower"
              ? false
              : true
          }
          setzoomValue={setzoomValue}
          zoomValue={zoomValue}
          wasmReady={wasmReady}
        />
      )}
      {tabValue === "2" && wasmReady && (
        <Recession
          isUpper={isUpper}
          isLower={isLower}
          setzoomValue={setzoomValue}
          zoomValue={zoomValue}
          wasmReady={wasmReady}
        />
      )}
      {tabValue === "3" && wasmReady && (
        <Scanned
          isUpper={isUpper}
          isLower={isLower}
          setzoomValue={setzoomValue}
          zoomValue={zoomValue}
          wasmReady={wasmReady}
        />
      )}
    </Box>
  )
}

export default Customization
