import CloseIcon from "@mui/icons-material/Close"
import { FC } from "react"
import { Trans, useTranslation } from "react-i18next"

import { UModalPopup, UText } from "../../../ui-component"
import { Box, IconButton } from "../../../ui-component/mui.components"

import ZoomSettingsUiText from "./ZoomSettings.Text"

const ZoomSettings: FC<{
  isOpen: boolean
  onClose: (value: boolean) => void
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslation("common")

  const ListItem = ({ list }) => {
    return (
      <Box>
        {Object.keys(list).map((listKey, index) => (
          <Box
            key={listKey}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              background: index % 2 == 0 ? "#FFF" : "#f4f4f4",
              padding: "5px 20px",
            }}
          >
            <Box sx={{ width: "50%", fontSize: "16px", fontWeight: "400" }}>
              <UText variant={"body2"}>{listKey}</UText>
            </Box>
            <Box sx={{ width: "50%", fontSize: "16px", fontWeight: "400" }}>
              <UText variant={"body2"}>
                <Trans components={{ bold: <b style={{ fontWeight: 600 }} /> }}>{list[listKey]}</Trans>
              </UText>
            </Box>
          </Box>
        ))}
      </Box>
    )
  }
  const orderList = [
    t("ZoomSettings.ChromeSettingStep1"),
    t("ZoomSettings.ChromeSettingStep2"),
  ]

  const OrderedListItem = ({ list }) => {
    return (
      <>
        {list.map((step, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              padding: "0 0 0 30px",
            }}
          >
            <UText
              variant="body1"
              sxProp={{
                fontWeight: "400",
                fontSize: "16px",
                flexBasis: "12.5px",
                textAlign: "right",
              }}
            >
              {index + 1}.
            </UText>
            <UText
              variant="body1"
              sxProp={{
                fontWeight: "400",
                fontSize: "16px",
              }}
            >
              {step}
            </UText>
          </Box>
        ))}
      </>
    )
  }

  return (
    <UModalPopup
      data-testid={"ZoomSettings"}
      title={
        <Box
          sx={{
            position: "relative",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UText
            variant={"h5"}
            sxProp={{
              fontWeight: "500",
            }}
          >
            {t("ZoomSettings.title")}
          </UText>
          <IconButton
            sx={{
              color: "#707070",
            }}
            onClick={() => {
              onClose(false)
            }}
          >
            <CloseIcon
              sx={{
                height: "20px",
                width: "20px",
              }}
            />
          </IconButton>
        </Box>
      }
      isAlert={isOpen}
      content={
        <Box
          sx={{
            background: "#fff",
            padding: "24px 50px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <UText
              variant={"body1"}
              sxProp={{
                fontWeight: "400",
                fontSize: "12px",
                color: "#00000099",
                // paddingBottom: "10px"
              }}
            >
              {t("ZoomSettings.contentNote")}
            </UText>
            <UText
              variant={"body1"}
              sxProp={{ fontWeight: "600", fontSize: "24px", padding: "8px 0" }}
            >
              {t("ZoomSettings.usingKeyboard")}
            </UText>
            {Object.keys(ZoomSettingsUiText).map((shortcutCategory) => (
              <Box
                key={shortcutCategory}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <UText
                  variant={"h5"}
                  sxProp={{ fontWeight: "500", fontSize: "20px", padding: "8px 20px" }}
                >
                  {shortcutCategory}
                </UText>
                <ListItem list={ZoomSettingsUiText[shortcutCategory]} />
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <UText
              variant={"body1"}
              sxProp={{ fontWeight: "600", padding: "8px 0", fontSize: "24px" }}
            >
              {t("ZoomSettings.usingMouse")}
            </UText>
            <UText
              variant={"body1"}
              sxProp={{
                fontWeight: "400",
                padding: "6px 20px",
                fontSize: "16px",
              }}
            >
              <Trans components={{ bold: <b /> }}>
                {t("ZoomSettings.scrollAndZoomCtrl")}
              </Trans>
            </UText>
            </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <UText
              variant={"body1"}
              sxProp={{
                fontWeight: "600",
                padding: "8px 0 14px",
                fontSize: "24px",
              }}
            >
              {t("ZoomSettings.usingChromeSettings")}
            </UText>
            <OrderedListItem list={orderList} />
          </Box>
        </Box>
      }
      sxModalProps={{
        "& .MuiDialog-paper": {
          minWidth: "640px",
          borderRadius: "8px",
          backgroundColor: "#EBEBEC",
        },
        "#titleCntr": {
          // backgroundColor: "primary.light",
          padding: "24px 30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        },
        "#btnListCntr": {
          height: "20px",
        },
      }}
      btnList={[]}
    />
  )
}

export default ZoomSettings
