import React, { useEffect, useState } from 'react';
import { createAsyncThunk } from "@reduxjs/toolkit"
import store from "../../../core/app/store"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { setSelectTxDataSave } from '@/UDTreat/udTreatSlice';
import {
  initialfinal,
  stageControl,
  toothMovement,
  EBiteJumpLocationType,
  viewControlInTreatment,
  caseManagement,
  history,
  stagingManager,
} from "@/gluelayer"

export function isNodeContains(nodeTypes, nodeType) {
  //console.info("=====nodeTypes: " + nodeTypes.length)
  if (nodeTypes.length > 0) {
    //console.info(nodeTypes[0])
  }
  let index = nodeTypes.indexOf(nodeType)
  //console.info("index:" + index)
  return index >= 0
}

// export const waitForUserHandleTxDataChange = createAsyncThunk(
//   "stageCommon/waitForUserHandleTxDataChange",
//   async (arg, { rejectWithValue, getState }) => {

//     try {
//       const unsubscribe = store.subscribe(() => {
//         const newData = store.getState().udTreatService.selectTxDataSave;
//         if (newData === "save_yes") {
//           unsubscribe();
//           return;
//         }
//         else if (newData === "save_no") {
//           unsubscribe();
//           return;
//         }
//       })
//     } catch (err) {
//       return rejectWithValue(err)
//     }
//   },
// )

export const checkTxDataChange = async () => {
  await userSelectTxChangeSave()
  // const isTxDataChanged = caseManagement.getTxDataChanged()
  // if(isTxDataChanged)
  // {
  //   const dispatch = useAppDispatch()
  //   dispatch(setSelectTxDataSave("save_show"))
  //   await userSelectTxChangeSave()
  // }
};

export const userSelectTxChangeSave = async () => {
  return new Promise((resolve) => {
    const unsubscribe = store.subscribe(() => {
      const newData = store.getState().udTreatService.selectTxDataSave;
      console.info("checkTxDataChange selectTxDataSave:", newData)
      if (newData === "save_yes") {
        unsubscribe();
        caseManagement.saveCurrentTx(true)
        resolve(newData);
      }
      else if (newData === "save_no") {
        unsubscribe();
        caseManagement.saveCurrentTx(false)
        if((window as any).pulloutCallback()){
          (window as any).pulloutCallback()
        }
        resolve(newData);
      }
      else if (newData === "save_cancel") {
        unsubscribe();
        resolve(newData);
      }
    });
  });
};

class StageCommon {
  
}

export const stageCommon = new StageCommon();

