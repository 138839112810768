import { FC, useEffect, useMemo, useState } from "react"
import { Actions, ControlProps } from "@jsonforms/core"
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react"
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material"
import { debounce } from "lodash";

const UTextarea: FC<ControlProps> = (props) => {
  const { path, visible, label, schema, data, handleChange, required } = props
  const [errors, setErrors] = useState("")
  const { core, dispatch } = useJsonForms()

  const [inputValue, setInputValue] = useState(data || "")
  const debouncedHandleChange = useMemo(
    () =>
      debounce((path, value) => {
        handleChange(path, value); // Sends only the last value
      }, 300, { leading: false, trailing: true }),
    [handleChange]
  );
  const updateErrors = (message: string) => {
    setErrors(message)
    dispatch &&
      dispatch(
        Actions.updateErrors([
          {
            instancePath: "/" + path,
            message,
            schemaPath: "",
            keyword: "",
            params: {},
          },
        ]),
      )
  }
  useEffect(() => {
    updateErrors(
      required && data?.length === 0 ? "This is a required field" : "",
    )
    if(data!=inputValue){
      setInputValue(data) // inputValue should not be in deps
    }
  }, [data, core?.validationMode])

  return (
    <>
      {visible && (
        <Box
          sx={{
            my: 1,
            display: schema.isSettings ? "flex" : "",
            justifyContent: schema.isSettings ? "space-between" : "",
            pl: schema.isSettings ? 1 : "",
          }}
        >
          <InputLabel
            error={!!(errors && core?.validationMode === "ValidateAndShow")}
            sx={{
              mb: 1,
              color: "rgba(0, 0, 0, 0.60)",
              "&.Mui-focused": {
                color: "rgba(0, 0, 0, 0.60)",
              },
              // v1:
              width: schema.isSettings ? "210px" : "100%",
              // v2:
              //  width: schema.isSettings ? "200px" : "100%",
              whiteSpace: "normal",
              // v2:
              // minWidth: "200px",
            }}
          >
            {label}
          </InputLabel>
          <FormControl
            error={!!(errors && core?.validationMode === "ValidateAndShow")}
            variant={"outlined"}
            fullWidth={schema.isSettings ? false : true}
          >
            <OutlinedInput
              id={label}
              multiline
              rows={schema.options.rows}
              value={inputValue}
              placeholder={schema.placeholder || ""}
              onChange={(event: any) => {
                if( event.target.value === inputValue) return
                setInputValue(event.target.value)
                debouncedHandleChange(path, event.target.value)
              }}
              // v1:
              sx={{ width: schema.isSettings ? "341px" : "100%" }}
              // v2:
              // sx={{ width: schema.isSettings ? "250px" : "100%" }}
            />
            {!!(errors && core?.validationMode === "ValidateAndShow") && (
              <FormHelperText sx={{ marginLeft: 0 }}>{errors}</FormHelperText>
            )}
          </FormControl>
        </Box>
      )}
    </>
  )
}

export default withJsonFormsControlProps(UTextarea)
