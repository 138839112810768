import moment from "moment-timezone"

import { maxRangeVal, stageRange } from "../../../Order/stage.helper"
import { ICase } from "@/core/model/interface/ICase"

export const getAvatarInitial = (value: string) => {
  const names = value.split(" ")
  return (
    names.length > 1
      ? names[0].charAt(0) + names[1].charAt(0)
      : names[0].charAt(0)
  ).toUpperCase()
}

export const formatDate = (inputDate) => {
  if (inputDate) {
    return moment(inputDate).format("MMM DD, YYYY")
  }
}

export const CapitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const convertBlobToPdfUrl = (response) => {
  const blob = new Blob([response], { type: "application/pdf" })
  return URL.createObjectURL(blob)
}

export const calculateAge = (inputDate) => {
  const dobDate = new Date(inputDate)
  const currentDate = new Date()

  if (isNaN(dobDate)) return
  const timeDiff = Math.abs(currentDate - dobDate)
  return Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365.25)).toString()
}

export const splitCountryCodeAndMobileNumber = (inputString) => {
  const regex = /^(\+\d+)(\d{10})$/

  const match = inputString.match(regex)

  if (match) {
    const countryCode = match[1]
    const mobileNumber = match[2]
    return { countryCode, mobileNumber }
  }
}

export const getQuoteRequestObj = (
  caseDetail: ICase,
  stage?: { upper: number, lower: number }
) => {
  const requestObj = {
    case_id: caseDetail.id,
    upper_template_stage: "",
    lower_template_stage: "",
    upper_aligner_stage: "",
    lower_aligner_stage: "",
    upper_retainer_stage: "",
    lower_retainer_stage: "",
  }

  if (caseDetail?.udesign_json) {
    const udesign_json = JSON.parse(caseDetail.udesign_json)

    const setAlignerAndRetainerStage = (
      stageValue: string,
      isUpper: boolean
    ) => {
      const alignerStageKey = isUpper ? "upper_aligner_stage" : "lower_aligner_stage"
      const retainerStageKey = isUpper ? "upper_retainer_stage" : "lower_retainer_stage"

      requestObj[alignerStageKey] = stageRange(+stageValue)
      requestObj[retainerStageKey] = `${500 + maxRangeVal(+stageValue)}`
    }

    // Set upper aligner and retainer stages
    if (udesign_json.UpperType === "A") {
      let ustage = stage?.upper
        ? stage.upper
        : caseDetail?.planned_upper
          ? caseDetail.planned_upper
          : udesign_json.UpperMaxStageNum
      setAlignerAndRetainerStage(ustage, true)
    }

    // Set lower aligner and retainer stages
    if (udesign_json.LowerType === "A") {
      let lstage = stage?.lower
        ? stage.lower
        : caseDetail?.planned_lower
          ? caseDetail.planned_lower
          : udesign_json.LowerMaxStageNum
      setAlignerAndRetainerStage(
        lstage,
        false
      )
    }

    if (udesign_json.UpperType === "R") {
      requestObj.upper_retainer_stage = "500"
    }
    if (udesign_json.LowerType === "R") {
      requestObj.lower_retainer_stage = "500"
    }

    if (udesign_json.UpperTemplate > 0) {
      requestObj.upper_template_stage = "400"
    }
    if (udesign_json.LowerTemplate > 0) {
      requestObj.lower_template_stage = "400"
    }
  }
  return requestObj
}

export const downloadZipFile = (blobRes: Blob, caseId: number) => {
  const link = document.createElement("a")
  link.style.display = "none"
  document.body.appendChild(link)
  const blob = new Blob([blobRes], {
    type: "application/zip",
  })
  link.href = URL.createObjectURL(blob)
  link.download = `Case_${caseId}.zip`
  link.click()
  document.body.removeChild(link)
}

export const getCreditVal = (val: string) => {
  const number = Number(val.replace(/[^0-9.-]+/g, ""))
  const formattedNum = number.toFixed(2)
  if (number < 0) {
    return `(-$${Math.abs(number).toFixed(2)})`
  } else {
    return `$${formattedNum}`
  }
}

export const getCurrentTimeByTimeZone = (time: string, onlyDate?: boolean) => {
  if (!time) return
  const current_time = moment
    .tz(time, Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format(onlyDate ? "MM/DD/YY" : "MM/DD/YY hh:mm A")
  return current_time
}


export const hasFileHistoryChanged = (
  oldFileHistory: { name: string; time: string }[],
  newFileHistory: { name: string; time: string }[]
): boolean => {
  const oldFileMap = new Map(oldFileHistory.map(file => [file.name, file.time]));
  const newFileMap = new Map(newFileHistory.map(file => [file.name, file.time]));

  // Check for new or updated files in newFileHistory
  for (const [name, newTime] of newFileMap) {
    const oldTime = oldFileMap.get(name)
    if(newTime === null && oldTime === null) continue
    if (!oldTime || !moment.utc(newTime).isSame(moment.utc(oldTime))) {
      return true
    }
  }

  // Check for removed files (files in oldFileHistory not in newFileHistory)
  for (const name of oldFileMap.keys()) {
    if (!newFileMap.has(name)) {
      return true
    }
  }

  return false
};

