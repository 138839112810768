import { createSlice } from "@reduxjs/toolkit"

import { ICase } from "../../../model/interface/ICase"

import {
  getCaseById,
  getCaseByPatient,
  getCaseFilesByCaseId,
  updateCaseById,
  updateCaseStatus,
  updateCaseUnarchive,
  conversionThunk,
} from "./caseThunkApi"

interface ICaseServiceState {
  loading: "idle" | "pending" | "succeeded" | "failed"
  caseListFetching: "idle" | "pending" | "succeeded" | "failed"

  caseDetail: ICase
  caseList: ICase[]
}

const initialState: ICaseServiceState = {
  loading: "idle",
  caseListFetching: "idle",
  caseDetail: {} as ICase,
  caseList: [],
}

export const caseServiceSlice = createSlice({
  name: "caseService",
  initialState,
  reducers: {
    setData(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
    resetCasedata: (state) => {
      state.caseDetail = {} as ICase
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCaseStatus.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(updateCaseStatus.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(updateCaseStatus.rejected, (state) => {
      console.log("rejected")
      state.loading = "failed"
    })
    builder.addCase(updateCaseUnarchive.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(updateCaseUnarchive.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(updateCaseUnarchive.rejected, (state) => {
      console.log("rejected")
      state.loading = "failed"
    })
    builder.addCase(getCaseById.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(getCaseById.fulfilled, (state, action) => {
      state.caseDetail = action.payload
      state.loading = "succeeded"
    })
    builder.addCase(getCaseById.rejected, (state) => {
      console.log("rejected")

      state.loading = "failed"
    })
    builder.addCase(getCaseByPatient.pending, (state) => {
      state.loading = "pending"
      state.caseListFetching = "pending"
      state.caseList = []
    })
    builder.addCase(getCaseByPatient.fulfilled, (state, action) => {
      state.caseList = action.payload
      state.loading = "succeeded"
      state.caseListFetching = "succeeded"
    })
    builder.addCase(getCaseByPatient.rejected, (state) => {
      state.caseListFetching = "failed"
      state.loading = "failed"
    })
    builder.addCase(updateCaseById.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(updateCaseById.fulfilled, (state, action) => {
      state.caseDetail = action.payload
      state.loading = "succeeded"
    })
    builder.addCase(updateCaseById.rejected, (state) => {
      console.log("rejected")
      state.loading = "failed"
    })
    builder.addCase(getCaseFilesByCaseId.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(getCaseFilesByCaseId.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(getCaseFilesByCaseId.rejected, (state) => {
      state.loading = "failed"
    })
    // conversion Request Flow Processing
    builder.addCase(conversionThunk.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(conversionThunk.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(conversionThunk.rejected, (state) => {
      state.loading = "failed"
    })
  },
})
export const { resetCasedata, setData } = caseServiceSlice.actions
