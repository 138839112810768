import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import {
  attachmentModule,
  GlobalEvents,
  globalEvents,
  stagingManager,
} from "@/gluelayer"
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  Tooltip,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"

import Elastics1 from "../../assets/right/button1.svg"
import Elastics2 from "../../assets/right/button2.svg"
import Elastics3 from "../../assets/right/button3.svg"
import SlitIcon from "../../assets/right/slit.svg"

const ElasticsIDs = [
  {
    key: "6",
    id: "6",
    name: "Precision Button",
  },
  {
    key: "5",
    id: "5",
    name: "Genetics 1",
  },
  {
    key: "4",
    id: "4",
    name: "Genetics 2",
  },
  {
    key: "23",
    id: "23",
    name: "Elastic slits",
  },
]
export const UDElastics = () => {
  const { currentStep } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  const [auxToothID, setAuxToothID] = useState(0)
  const [stageMenuIems, setStageMenuIems] = useState([])

  const upStage = stagingManager.wasmStageData.jointUpKeypoints
  const lowerStage = stagingManager.wasmStageData.jointLowerKeypoints

  const [startendStage, setStartEndStage] = useState([-1, -1])

  const [inputLabelState, setInputLabelState] = useState(2) //0:清空数据   1:置灰显示  2:正常显示
  useEffect(() => {
    attachmentModule.setOnSelectedAuxCallback((toothid) => {
      console.log("setOnSelectedAuxCallback toothid:", toothid)
      setAuxToothID(toothid)
      setInputLabelState(2)

      const startAndEndStageValue =
        attachmentModule.getElasticsStartAndEndStageValue()
      console.log(
        "🚀 ~ attachmentModule.setOnSelectedAuxCallback ~ startAndEndStageValue:",
        startAndEndStageValue,
      )
      setStartEndStage(startAndEndStageValue)
    })

    attachmentModule.setAttachmentSelectAbleType("ButtonAttachment")

    attachmentModule.setCancleSelectAttachmentCB((num) => {
      console.log("setCancleSelectAttachmentCB num:", num)
      if (num === 0) setStartEndStage([-1, -1])
      setInputLabelState(1)
    })

    return () => {
      attachmentModule.setOnSelectedAuxCallback(null)
    }
  }, [])

  useEffect(() => {
    let tempStageMenuIems = []

    const stages = auxToothID < 18 ? upStage : lowerStage

    stages.forEach((val) => {
      // if (val.name === "0") return
      if (val.name.includes("R")) tempStageMenuIems.push(val.name)
      else tempStageMenuIems.push(val.name)
    })

    setStageMenuIems(tempStageMenuIems)
  }, [auxToothID, currentStep])

  const onStageChange = (changedVal: number, startOrEnd: number) => {
    const tempStages =
      startOrEnd === 0
        ? [changedVal, startendStage[1]]
        : [startendStage[0], changedVal]

    const newStages = attachmentModule.changeStartAndEndStageValue(
      tempStages[0],
      tempStages[1],
      startOrEnd,
    )
    console.log("🚀 ~ onStageChange ~ newStages:", newStages)
    if (newStages[0] !== -1 && newStages[1] !== -1) setStartEndStage(newStages)
  }

  return (
    <Box width={"404px"} paddingLeft={1} marginBottom={10}>
      <Stack direction={"row"} spacing={1}>
        {ElasticsIDs.map((val) => {
          let iconSrc = Elastics1
          switch (val.key) {
            case "6":
              iconSrc = Elastics1
              break
            case "5":
              iconSrc = Elastics3
              break
            case "4":
              iconSrc = Elastics2
              break
            case "23":
              iconSrc = SlitIcon
              break
            default:
              break
          }
          return (
            <Tooltip title={val.name} placement="bottom">
              <ToggleButton
                sx={{
                  border: "none",
                  width: "100px",
                  // height: "80px",
                }}
                value="showUpper"
                // size="large"
                onClick={() => {
                  attachmentModule.setAttachmentSelectLibID(
                    Number.parseInt(val.id),
                  )
                }}
              >
                <ReactSVG src={iconSrc}></ReactSVG>
              </ToggleButton>
            </Tooltip>
          )
        })}
      </Stack>

      <Typography sx={{ marginTop: 5 }}>
        Choose the start and end stage for each button and/or elastic slit to
        create cutouts.
      </Typography>

      <Stack direction="row" justifyContent={"center"} alignItems={"center"}>
        <FormControl
          sx={{ m: 1, minWidth: 140 }}
          disabled={inputLabelState === 1}
        >
          <InputLabel id="demo-simple-select-standard-label">
            Start from
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={startendStage[0]}
            onChange={(e) => {
              onStageChange(e.target.value, 0)
            }}
            label="Start from"
          >
            {stageMenuIems.map((val, index) => {
              return (
                <MenuItem key={val} value={index}>
                  {val}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <FormControl
          sx={{ m: 1, minWidth: 140 }}
          disabled={inputLabelState === 1}
        >
          <InputLabel id="demo-simple-select-standard-label">End at</InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={startendStage[1]}
            onChange={(e) => {
              onStageChange(e.target.value, 1)
            }}
            label="End at"
          >
            {stageMenuIems.map((val, index) => {
              return (
                <MenuItem key={val} value={index}>
                  {val}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Stack>
    </Box>
  )
}
