import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useParams } from "react-router-dom"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import { Box, Container, Grid, Paper } from "../../../components/mui.components"
import PatientInfoHeader from "../../../containers/LandingLayout/PatientInfoHeader/PatientInfoHeader"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { UButton, UText } from "../../../ui-component"
import Summary from "../USmartForms/Summary"
import { safeCloseWindow } from "@/core/app/CloseTabContext"

const Confirmation: FC<{ isRefinement?: boolean }> = ({ isRefinement }) => {
  const { t } = useTranslation()
  const navigate = useMyNavigation()
  const { patientId, caseId } = useParams()
    const [searchParams] = useSearchParams()
    const from = searchParams.get("from")

  return (
    <>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        minWidth={"lg"}
      >
        <Grid container maxWidth={"lg"} sx={{ alignContent: "flex-start" }}>
          {!isRefinement && (
            <PatientInfoHeader
              chipLabel={"Submitted to uAssist"}
              cancelBtnText={t("button.closeCase")}
              onBtnCancelClick={() => {
                if (from === "treat")
                  safeCloseWindow()
                else
                  navigate("/patients")
              }}
              chipSx={{
                background: "transparent",
                fontSize: "13px",
                fontWeight: "400px",
                color: "info.main",
                border: "1px solid rgba(2, 136, 209, 1)",
              }}
            />
          )}
          <Container sx={{ my: 4, width: "664px" }}>
            <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
              <Box sx={{ py: 1, mb: 2 }}>
                <UText variant={"h5"}>
                  {isRefinement
                    ? t("uassist.uAssistForm.refinementconfirmation.header")
                    : t("summary.confirmation.thanksMsg")}
                </UText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mb: 2,
                }}
              >
                <Box sx={{ mr: 1 }}>
                  <CheckCircleIcon color={"success"} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                  }}
                >
                  <UText variant={"subtitle1"}>
                    {isRefinement
                      ? t(
                          "uassist.uAssistForm.refinementconfirmation.submitted",
                        )
                      : t("summary.confirmation.submitted")}
                  </UText>
                  <UText
                    variant={"body1"}
                    sxProp={{
                      paddingLeft: "0px",
                      fontSize: "16px",
                      height: "24px",
                    }}
                  >
                    {`Case #${caseId}`}
                    {/* <UButton
                      btnText={`Case #${caseId}`}
                      data-testid={"InputField-id"}
                      onClickHandler={(event) => {
                        event.preventDefault()
                        navigate(
                          `/clinical/patient/${patientId}/case/${caseId}/treat`,
                        )
                      }}
                      variant={"text"}
                      sxProp={{
                        paddingLeft: "0px",
                        textDecoration: "underline",
                        textDecorationColor: "rgb(33, 94, 205, .4)",
                        textTransform: "none",
                        fontSize: "16px",
                        height: "24px",
                        "&:hover": {
                          backgroundColor: "transparent",
                          textDecoration: "underline",
                        },
                      }}
                    /> */}
                  </UText>
                </Box>
              </Box>
              <Box sx={{ mb: 2 }}>
                <UText variant={"body2"}>
                  {isRefinement
                    ? t("uassist.uAssistForm.refinementconfirmation.note")
                    : t("summary.confirmation.notes")}
                </UText>
              </Box>
              {/* <Box sx={{ display: "flex", justifyContent: "end", my: 1 }}>
                <UButton
                  sxProp={{ width: "172px", height: "36px" }}
                  variant={"contained"}
                  btnType={"submit"}
                  onClickHandler={() => navigate("/patients")}
                  btnText={t("button.gotoPatientlist")}
                />
              </Box> */}
            </Paper>
          </Container>
        </Grid>
      </Grid>
    </>
  )
}

export default Confirmation
