export const navigateTo = (
  page: string,
  navigate: (url: string) => void,
  patientId: string | number,
  caseId: string | number,
  udesignJson?: string,
) => {
  switch (page) {
    case "RECORDS":
      navigate(`/patients/choosetype/patient/${patientId}/case/${caseId}`)
      break
    case "PRESCRIPTION":
      if (udesignJson) {
        const val = JSON.parse(udesignJson)
        if (val.LowerType === "A" || val.UpperType === "A") {
          navigate(
            `/records/patient/${patientId}/case/${caseId}/rxForm/prescription`,
          )
        } else if (val.UpperType === "R" || val.LowerType === "R") {
          navigate(
            `/records/patient/${patientId}/case/${caseId}/rxForm/retainer`,
          )
        } else {
          navigate(`/patients/choosetype/patient/${patientId}/case/${caseId}`)
        }
      }
      break
    case "SUMMARY":
      navigate(`/uassist/patient/${patientId}/case/${caseId}/summary`)
      break
    case "OverViewSUMMARY":
      navigate(`/records/patient/${patientId}/case/${caseId}/rxForm/summary`)
      break
    case "RETAINER":
      navigate(`/overview/patient/${patientId}/case/${caseId}/form/retainer`)
      break
    case "TREAT":
      navigate(`/clinical/patient/${patientId}/case/${caseId}/treat`)
      break
    case "REFINEMENT":
      navigate(
        `/overview/patient/${patientId}/case/${caseId}/courserefinement/scans`,
      )
      break
    default:
      console.log("action", caseId)
  }
}

/**
 * Converts a comma-separated string of numbers into a range string.
 * @param rangeValues - A string containing comma-separated numbers (e.g., "8,6,7").
 * @returns A string representing the range (e.g., "6-8").
 */
export const getAlignerRange = (rangeValues: string): string => {
  // Convert the string into an array of numbers
  const values = rangeValues.split(",").map(Number)

  // Find the minimum and maximum values
  const min = Math.min(...values)
  const max = Math.max(...values)

  // Return the range in the "min-max" format
  return `${min}-${max}`
}
