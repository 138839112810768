import type { FC } from "react"
import { useEffect, useState } from "react"
import { Rnd } from "react-rnd"

import { DragProps } from "./type"

import "./Drag.scss"
/**
 * 拖拽缩放组件 Drag and drop zoom component https://www.npmjs.com/package/react-rnd#instance-api
 *
 *
 *  islockAspectRatio<boolean | number>:Set to true to lock the aspect ratio based on the initial size. Set to numeric to lock in a specific aspect ratio (e.g. 16/9). If omitted, set to false
 *                                      设置为true可根据初始大小锁定纵横比。设置为数值以锁定特定的纵横比（例如16/9）。如果省略，则设置为false
 *
 * DragSize:控制组件的大小 Controlling the size of components
 *
 * DragPosition:控制组件位置 Control component locations
 *
 * disableDragging:禁用完全拖动 Disable full dragging
 *
 * enableResizing：用于设置组件的可调整大小权限允许 top, right, bottom, left, topRight, bottomRight, bottomLeft, topLeft方向调整大小。如果省略，将启用所有大小调整器。如果只允许正确方向调整大小，请设置{top:false, right:true, bottom:false, left:false，顶部ight:false，底部ight:false，底部eft:false，顶部eft:false }.
 *                 Used to set the component's resizable permissions to allow resizing in the top, right, bottom, left, topRight, bottomRight, bottomLeft, topLeft directions. If omitted, all resizers will be enabled. To allow resizing in the correct direction only, set {top:false, right:true, bottom:false, left:false, topight:false, bottomight:false, bottomeft:false, topeft:false }
 *
 * children:需要拖拽的组件 Components to be dragged and dropped
 *
 *
 * dragHandleClassName:选择xxx元素作为拖拽的启动器 dragHandleClassName Select the xxx element as the drag and drop launcher dragHandleClassName
 *
 * bounds: 拖拽区域。 Specifies movement boundaries,window, body, Selector like .fooClassName or
 * Element.
 */

const Drag: FC<DragProps> = ({
  callback,
  islockAspectRatio = false,
  DragSize,
  DragPosition,
  disableDragging = false,
  enableResizing = false,
  children = <></>,
  dragHandleClassName = "",
  bounds = "window",
}) => {
  const [Size, setSize] = useState(DragSize)
  const [pos, setPosition] = useState(DragPosition)

  // change size的时候把坐标赋给position
  useEffect(() => {
    if (Size?.x || Size?.y) {
      setPosition({
        x: Size.x,
        y: Size.y,
      })
    }
  }, [Size])
  return (
    <Rnd
      dragHandleClassName={dragHandleClassName}
      style={{ position: "fixed", zIndex: 21 }}
      lockAspectRatio={islockAspectRatio}
      disableDragging={disableDragging}
      // size={{ width: Size?.width, height: Size?.height }}
      position={{ x: pos.x, y: pos.y }}
      onDragStop={(e, d) => {
        setPosition({ x: d.x, y: d.y })
      }}
      className={"Drag"}
      onResize={(e, direction, ref, delta, position) => {
        callback(Number(ref.style.width.slice(0, -2)))
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        setSize({
          width: ref.style.width,
          height: ref.style.height,
          ...position,
        })
      }}
      enableResizing={enableResizing}
      bounds={bounds}
    >
      {children}
    </Rnd>
  )
}

export default Drag
