/**
 *  call this component when close case and not order case
 */
import React, { FC, useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
} from "@mui/material"
import { caseManagement } from "@/gluelayer"

import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { updateCaseById } from "../../../../core/app/slices/case/caseThunkApi"
import {
  unlockCaseApi,
  uploadCaseZips,
} from "../../../../core/app/slices/clinical/clinicalThunkApi"
import { RootState } from "../../../../core/app/store"
import DialogBox from "@/modules/Clinical/components/DialogBox/dialogBox"
import { UButton } from "@/components/index"
import { Checkbox } from "@mui/material"
import { setShouldCloseTab } from "@/UDTreat/udTreatSlice"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { safeCloseWindow } from "@/core/app/CloseTabContext"
interface assignTyp {
  isActive: boolean
  planList: { txName: string; index: number }[]
  uploadReportPdf: () => void
  currentPlan: {
    txName: string
    index: 2 | 1
  }
  showPrompt: boolean | (() => void)
  confirmNavigation: boolean | (() => void)
  uploadAllData
  deletePlanFunction: (plan: number) => boolean
  // setCloseClick: (val: boolean) => void
  isEdit: boolean
  isFull: boolean
  setIsShowAssign
}
const AssignPopup: FC<assignTyp> = ({
  isActive,
  planList,
  uploadReportPdf,
  currentPlan,
  showPrompt,
  confirmNavigation,
  uploadAllData,
  deletePlanFunction,
  // setCloseClick,
  setIsShowAssign,
  isEdit,
  isFull
}) => {
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const {
    userService: {
      user: { user_id },
    },
    doctorService: { doctorList },
    clinicalService: { treatmentList },
  } = useAppSelector((state: RootState) => state)

  const [doctors, setdoctor] = useState([])
  const [staffs, setstaffs] = useState([])
  const [assigner, setassigner] = useState<number>(null)
  const [isAssign, setisAssign] = useState(false)
  const [plan, setplan] = useState<number>(1)
  const dispatch = useAppDispatch()
  const { caseId, patientId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showDeleteWarning, setShowDeleteWarning] = useState(false)
  // const [showAssign, setShowAssign] = useState(true)
  const [needUpload, setNeedUpload] = useState(false)
  const navigate = useMyNavigation()
  const from = searchParams.get("from")
  
  useEffect(() => {
    const d = [],
      s = []
    doctorList.map(({ category, first_name, last_name, id }) => {
      if (category === "Doctors") {
        d.push({ name: first_name + " " + last_name, id: id })
      } else {
        s.push({ name: first_name + " " + last_name, id: id })
      }
    })
    setdoctor(d)
    setstaffs(s)
  }, [doctorList])

  const onClickSaveCase = () => {
    if(!isEdit) {
      safeCloseWindow()
      return
    }
    dispatch(setShouldCloseTab(true))
    let path = "/patients"
    if (from === "bundle" || location.hash === "#uassist") {
      console.log("location :", location.hash)
      path = "/patients"
    }
    navigate(path)
  }

  /**
   * post to BE when you assign order case to someone
   */
  const assignFun = () => {
    if (isAssign && caseManagement.getTreatmentPlanList().length > 1){
      setNeedUpload(true)
      setShowDeleteWarning(true)
      // setShowAssign(false)
      return
    }
    const res = isAssign
      ? {
          assigned_to: assigner,
          case_disposition: "PLACE_ORDER",
        }
      : {
          assigned_to: assigner,
        }
    // setShowAssign(false)
    if (!isEdit && needUpload){
      uploadAllData({
        ...res,
        callback: () => {
          dispatch(unlockCaseApi({ patientId, caseId })).then(() => {
            onClickSaveCase()
          })
        },
      })
    } else {
      dispatch(
        updateCaseById({
          patientId,
          caseId,
          payload: {
            ...res
          },
        }),
      ).then(() => {
        onClickSaveCase()
      })
    }
  }

  useEffect(() => {
    if (treatmentList && treatmentList.length && treatmentList[0].assigned_to)
      setassigner(Number(treatmentList[0].assigned_to))
    else setassigner(Number(user_id))
  }, [user_id, treatmentList])

  useEffect(() => {
    if (isAssign) setplan(currentPlan?.index || 1)
  }, [planList, isAssign])

  // useEffect(() => {
  //   if (showPrompt && isActive && !showDeleteWarning) {
  //     setShowAssign(true)
  //   }
  // }, [showPrompt, isActive])

  return (
    <>
      <Dialog open={showPrompt && isActive}>
        <Paper sx={{ width: 444, height: "auto" }}>
          {/* <DialogTitle id="alert-dialog-title">
            {"Save case"}
          </DialogTitle>
           */}
          <DialogContent>
            <DialogContentText sx={{ padding: "15px 0 8px", color:"rgba(0, 0, 0, 0.87)" }}>
              {"Assign to"}
            </DialogContentText>
          
            <Select
              value={assigner}
              sx={{
                width: "100%",
              }}
              onChange={(e) => {
                setassigner(e.target.value)
              }}
            >
              <FormHelperText
                sx={{
                  width: "100%",
                  padding: "0 16px",
                }}
              >
                Doctors
              </FormHelperText>
              {doctors.map(({ name, id }) => (
                <MenuItem key={id} value={id} sx={{ paddingTop: 1, fontStyle: "normal !important", }}>
                  {name}
                </MenuItem>
              ))}
              <Divider></Divider>
              <FormHelperText
                sx={{
                  width: "100%",
                  padding: "0 16px",
                }}
              >
                Staffs
              </FormHelperText>
              {staffs.map(({ name, id }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            {isFull && (
              <>
               <DialogContentText sx={{ padding: "15px 0 0", color:"rgba(0, 0, 0, 0.87)", fontSize: "16px", fontWeight: 400 }}>
                  {"Status"}
                </DialogContentText>
                <DialogContentText sx={{ padding: "5px 0 5px", color:"rgba(0, 0, 0, 0.6)", fontSize: "14px", fontWeight: 500, letterSpacing: "0.1px", lineHeight: "157%" }}>
                  {`Set the case status to "Place Order" to assign it to your team member for manual ordering; it is not processed automatically.`}
                </DialogContentText>
                
                <Box sx={{fontSize: "16px", fontWeight: 400}}>
                  <Checkbox
                    checked={isAssign}
                    onChange={() => {
                      setisAssign(!isAssign)
                    }}
                  ></Checkbox>{"Set to"}
                  <DialogContentText 
                    sx={{ 
                      opacity: isAssign ? 1 : 0.5,
                      margin: "10px 0 0 10px",  
                      display: "inline-block", 
                      backgroundColor: "rgba(46, 125, 50, 1)", 
                      color: "white", 
                      padding: "5px 12px 5px 12px", 
                      borderRadius: "100px",
                      fontSize: "13px",
                      }}>
                    {"Place Order"}
                  </DialogContentText> 
                </Box>
    
                {isAssign && (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={currentPlan?.index || 1}
                    name="radio-buttons-group"
                    sx={{ padding: "0 32px" }}
                  >
                    {planList.map(({ txName, index }, num) => (
                      <FormControlLabel
                        key={num}
                        value={index}
                        control={<Radio />}
                        label={txName}
                        onClick={() => {
                          setplan(index)
                        }}
                      />
                    ))}
                  </RadioGroup>
                )} 
             </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                setIsShowAssign(false)
                dispatch(setShouldCloseTab(false))
              }}
              sx={{
                textTransform: "uppercase",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              CANCEL
            </Button>
            <Button
              onClick={() => {
                // setShowAssign(false)
                setIsShowAssign(false)
                assignFun()
              }}
              autoFocus
              sx={{
                textTransform: "uppercase",
              }}
              size="medium"
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
      <DialogBox open={showDeleteWarning} title="Warning" 
        content={<>{`You have selected to proceed with 'Plan ${plan}' and 'Plan ${plan === 1 ? 2 : 1}' will be deleted. Are you sure you want to proceed with this choice?`}</>} 
        actions=
        {<>
          <UButton
            onClickHandler={ () => {
              setIsShowAssign(true)
              setShowDeleteWarning(false)
              // setShowAssign(true)
              setNeedUpload(false)
            }}
            variant={"text"}
            btnText={("Cancel")}
          />
          <UButton
            onClickHandler={ () => {  
              setIsShowAssign(false) 
              setNeedUpload(true) 
              const isdel = deletePlanFunction(plan === 1 ? 2 : 1)
              if(isdel){
                setShowDeleteWarning(false)
                assignFun()
              }
            }}
            variant={"text"}
            btnText={("OK")}
          />
        </>
        }
        >
      </DialogBox>
    </>
    
  )
}
export default AssignPopup
