import { useEffect, useLayoutEffect, useState } from "react"
import { useRef } from "react"
import { useParams } from "react-router-dom"
import { Box, Stack, ThemeProvider } from "@mui/material"
import { isEmpty } from "lodash"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import {
  getCaseById,
  getCaseByPatient,
  updateCaseById,
} from "@/core/app/slices/case/caseThunkApi"
import {
  forcelockCaseApi,
  lockCaseApi,
  unlockCaseApi,
  uploadCaseZips,
} from "@/core/app/slices/clinical/clinicalThunkApi"
import { RootState } from "@/core/app/store"
import {
  caseManagement,
  viewEdit,
  wasmModule,
  treatView,
  stagingManager,
} from "@/gluelayer"
import { useCallbackPrompt } from "@/hooks/useCallbackPrompt"
import CaseLockPopup from "@/modules/Clinical/components/CaseLockPopup/caseLockPopup"
import MessagePopup from "@/modules/Clinical/components/CloseCase/messagePopup"
import OrderPopup from "@/modules/Clinical/components/CloseCase/orderPopup"
import { ScreeshotReport } from "@/modules/Clinical/components/ReportPDF/screenshotReport"
import ToothHover from "@/modules/Clinical/components/ToothHover/toothhover"
import WasmCanvas from "@/wasm3d/WasmCanvas"

import { UDBanner } from "./Banner/UDBanner"
import { BottomStatusBar } from "./bottom/BottomStatusBar"
import { BottomToolBar } from "./bottom/BottomToolBar"
import {
  caseLockByCRB,
  caseLockByOtherUser,
  caseLockByShipped,
  caseLockByUassist,
} from "./bottom/Toggle/column"
import { ReopenCaseDlg } from "./components/ReopenCaseDlg"
import SmartRx from "./components/SmartRx"
import { UploadSTL } from "./components/UploadSTL"
import { LeftPages } from "./left/LeftPages"
import { LeftToolBar } from "./left/LeftToolBar"
import { RightPages } from "./right/RightPages"
import { RightToolBar } from "./right/RightToolBar"
import { darkTheme, lightTheme } from "./theme/UDThemes"
import { ThemeSwitch } from "./top/ThemeSwitch"
import { TopToolBar } from "./top/TopToolBar"
import BiteRampButtons from "./UDesign/Attachments/BiteRampButtons"
import ExtractedToothList from "./UDToothInfo/ExtractedToothList"
import UDToothInfo from "./UDToothInfo/UDToothInfo"
import { KFEditor } from "./WeDesign/KFEditor"
import { WeStageBar } from "./WeDesign/WeStageBar"
import { UDChairsideWorkflow } from "./workflow/chairside/UDChairsideWorkflow"
import { UDRetainerRxWorkflow } from "./workflow/retainer/UDRetainerWorkflow"
import { UDSmartRxWorkflow } from "./workflow/smartrx/UDSmartRxWorkflow"
import { UDLockCaseForEdit } from "./UDLockCaseForEdit"
import { UDOpenCase } from "./UDOpenCase"
import NoPopup from "@/modules/Clinical/components/CloseCase/noPopup"
import AssignPopup from "@/modules/Clinical/components/CloseCase/assignPopup"
import { CASE_STATUS } from "@/modules/Patient/config/status.config"
import { CreateRefinement } from "@/modules/Records/CreateRefinement"
import {
  setPlanList,
  setSwitchViewEdit,
  setSmartRxShow,
  setUassistRxShow,
  setStageShowType,
  setIsCreateNewRefinement,
  setWidthAll,
  clearTreatStates,
} from "./udTreatSlice"
import {
  resetPrescriptionSchema,
  resetRxRefinementFormAdd,
  resetRxRefinementFormEdit,
  resetSchema,
} from "@/core/app/slices/uassist/formSlice"
import { refinementModule } from "@/gluelayer/core/modules/refinement"
import { setPhotoTypeJson } from "@/core/app/slices/records/photograph/photographSlice"
import { setAlert } from "@/core/app/slices/alert/alertSlice"
import AttachmentWarning from "./UDesign/Attachments/AttachmentWarning"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { useOktaAuth } from "@okta/okta-react"
import ConversionMask from "./components/ConversionMask"

export const UDTreat = () => {
  const dispatch = useAppDispatch()
  const {
    curCaseMode,
    workflowMode,
    isCasePreview,
    showBottomStatusBar,
    planList,
    reportData,
    switchViewEdit,
    refinementNum,
    isCreateNewRefinement,
    unlockAndLogout,
  } = useAppSelector((state: RootState) => state.udTreatService)
  const navigate = useMyNavigation()
  const [isSubmitModification, setisSubmitModification] = useState(true)
  const [isSubmitReply, setisSubmitReply] = useState(true)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { isCaseFilesUpdated } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  const { txplanSubname, txplanName } = caseDetail
  const { caseId, patientId } = useParams()
  const [openCase, setOpenCase] = useState(true)
  const [darkMode, setDarkMode] = useState(false)
  const [openWarning, setOpenWarning] = useState(true)
  const pageRef = useRef(null)
  const { oktaAuth } = useOktaAuth()
  const logOutRedirect = async () => oktaAuth.signOut()
  useEffect(() => {
    dispatch(getCaseByPatient({ patientId }))
    // dispatch(getCaseById({ patientId, caseId }))
    // clear rx data
    dispatch(resetSchema())
    dispatch(resetPrescriptionSchema())
    dispatch(resetRxRefinementFormEdit())
    dispatch(resetRxRefinementFormAdd())

    // clear photos json
    dispatch(setPhotoTypeJson(null))

    return () => {
      dispatch(clearTreatStates())
    }
  }, [])

  // 添加新的 useEffect 用于设置键盘事件监听器
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // if (event.ctrlKey || event.metaKey) {
      if (event.ctrlKey) {
        console.log("handleKeyDown")
        const inRefinement = wasmModule.statusController.IsInRefinementModule()
        if (inRefinement) return
        switch (event.key.toLowerCase()) {
          case "z":
          case "y":
            event.preventDefault()
            console.log("undo/redo key pressed")
            if (!wasmModule.isInit) return
            const module = wasmModule.ulabwinIns.getModuleManager()
            const undoRedoModule = module.GetUndoOrRedoModule()
            if (undoRedoModule) {
              if (event.key.toLowerCase() === "z") {
                console.log("Executing undo")
                undoRedoModule.GetLastState()
              } else {
                console.log("Executing redo")
                undoRedoModule.GetNextState()
              }
            }
            break
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    // 清理函数
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, []) // 空依赖数组意味着这个效果只在组件挂载和卸载时运行

  // Assembly plan data
  const assemblyPlanData = (planData: string[]) => {
    const txArr = []
    planData.map((i, j) => {
      txArr.push({
        txName: i.trim(),
        index: j + 1,
      })
    })
    return txArr
  }
  // function to deletePlan
  const deletePlanFunction = (plan = currentPlan.index) => {
    const isDel = caseManagement.delTx(plan)
    const txList = caseManagement.getTreatmentPlanList()
    const txArr = assemblyPlanData(txList)
    setCurrentPlan(txArr[0])
    setPlanList(txArr)
    return isDel
  }
  const [closeClick, setCloseClick] = useState(false)
  const [isShowAssign, setIsShowAssign] = useState(false)
  // order states
  const [isClickOrder, setisClickOrder] = useState(false)
  // case lock popup
  const [openLockPopup, setOpenLockPopup] = useState<boolean>(false)
  // case lock type
  //-1--- CRB  ,0---lock by uassist,   1---order been place,  2----edit by other user  ,
  const [lockType, setLockType] = useState<number>(2)
  const [allowUnlock, setAllowUnlock] = useState(true)
  const [ispopupAfterOrder, setispopupAfterOrder] = useState(false)
  const [isFull, setIsFull] = useState(true)
  // lock by username and machine
  const [lockInfo, setLockInfo] = useState<{
    username: string
    device_info: string
    locked_date: string
  }>({ username: "", device_info: "", locked_date: "" })
  // click lock control or override button
  const clickControlOverride = (overrideCallBak) => {
    dispatch(forcelockCaseApi({ patientId, caseId })).then(({ payload }) => {
      const { status } = payload
      if (status === "Success") {
        dispatch(setSwitchViewEdit(true))
        setOpenLockPopup(false)
        overrideCallBak && overrideCallBak()
      }
    })
  }
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    switchViewEdit || isClickOrder || !isSubmitReply,
  )
  // current tx
  const [currentPlan, setCurrentPlan] = useState<{
    txName: string
    index: 2 | 1
  }>({})

  //max refine
  const [maxRefine, setMaxRefine] = useState<number>(0)
  // pdf shoot
  const reportPdfRef = useRef(null)
  const openOrUploadReportPdf = (status: "open" | "upload") => {
    reportPdfRef?.current.uploadReportPDF(status)
  }
  // lock case function
  // unlock request
  const unlockRequest = () => {
    dispatch(unlockCaseApi({ patientId, caseId })).then(({ payload }) => {
      const { status } = payload
      if (status === "Success") {
        dispatch(setSwitchViewEdit(false))
        if (unlockAndLogout) logOutRedirect()
      } else {
        const { errors } = payload
        const { error_message } = errors[0]
        alert(error_message)
      }
    })
  }
  // unlock post request
  const unlockFunc = () => {
    uploadAllData({ callback: unlockRequest })
  }
  // lock post request
  const lockFunc = (callBack?: () => void, allowControl = true) => {
    dispatch(lockCaseApi({ patientId, caseId })).then(({ payload }) => {
      const { status } = payload
      if (status === "Success") {
        callBack && callBack()
      } else {
        const { errors, username, device_info, locked_date } = payload.data
        const { error_message } = errors[0]
        const info = error_message.split("::")[0]
        if (caseLockByUassist.includes(info)) {
          setLockType(0)
          setAllowUnlock(allowControl)
          setOpenLockPopup(true)
        } else if (caseLockByShipped.includes(info)) {
          setLockType(1)
          setAllowUnlock(allowControl)
          setOpenLockPopup(true)
        } else if (caseLockByOtherUser.includes(info)) {
          setLockInfo({ username, device_info, locked_date })
          setLockType(switchViewEdit ? 3 : 2)
          setAllowUnlock(allowControl)
          setOpenLockPopup(true)
        } else if (caseLockByCRB.includes(info)) {
          setLockType(-1)
          setAllowUnlock(allowControl)
          setOpenLockPopup(true)
        }
      }
    })
  }
  // switch edit or view function
  const handleSwitchEdit = () => {
    if (!switchViewEdit) {
      // lock case
      lockFunc(() => {
        dispatch(setSwitchViewEdit(true))
      })
    } else {
      // unlock case
      unlockFunc()
    }
  }

  const handerOrderClick = (callBack) => {
    lockFunc(() => {
      callBack()
    }, false)
  }
  /**
   * update the case detail by the case id
   * @param txname:the active case
   * @param other: the other payload for the case detail api
   */
  const updateCaseFun = ({ txname = planList[0].txName, ...other }) => {
    let extralInfo = {}
    // extralInfo.UpperRetainer = 1
    // extralInfo.LowerRetainer = 1
    let newUdesignJsonObj = {}
    let isHasAttachmentData
    let isHasIPRData
    try {
      extralInfo = caseManagement.getCaseExtraInfomation()

      isHasAttachmentData =
        caseManagement.getAttachmentIPRData()?.isHasAttachmentData
      isHasIPRData = caseManagement.getIsHasIPRNode()
    } catch {
      return
    }
    const isStartFromOne = refinementModule.getIsStartFromOne()
    dispatch(getCaseById({ patientId, caseId })).then((res) => {
      if (res?.payload?.udesign_json) {
        const udesign_json = JSON.parse(res.payload.udesign_json)
        //Merge with existing JSON.
        newUdesignJsonObj = {
          ...udesign_json,
          ...extralInfo,
        }
      } else {
        newUdesignJsonObj = { ...extralInfo }
      }
      const txsubname = maxRefine
        ? `Course Refinement ${maxRefine}`
        : planList[0].txName !== txname
        ? planList[0].txName
        : planList[1]?.txName || ""
      dispatch(
        updateCaseById({
          patientId,
          caseId,
          payload: {
            case_extra_attrs: JSON.stringify(newUdesignJsonObj),
            txplan_name: txname,
            txplan_subname: txsubname,
            course_check_number: isStartFromOne ? refinementNum - 1 : 0,
            complexity: isHasAttachmentData || isHasIPRData ? 2 : 1,
            planned_upper:
              Number(
                stagingManager.wasmStageData.jointUpKeypoints[
                  stagingManager.wasmStageData.jointUpKeypoints.length - 1
                ]?.name,
              ) || 0,
            planned_lower:
              Number(
                stagingManager.wasmStageData.jointLowerKeypoints[
                  stagingManager.wasmStageData.jointLowerKeypoints.length - 1
                ]?.name,
              ) || 0,
            ...other,
          },
        }),
      )
    })
  }
  /**
   * upload all of the datas when close the case
   * @param txname:active plan in the case(modifi plan or assign plan or order plan)
   * @param txsubname:inactive plan in the case
   * @param callback:the function after upload zips
   * @param other: the payload for upload case detail
   */
  const uploadAllData = ({
    txname = txplanName,
    txsubname = txplanSubname,
    callback = () => {
      return
    },
    ...other
  }: {
    txname?: string
    txsubname?: string
    callback: () => void
    other
  }) => {
    if (!txname) {
      // if there is no txname in the DB
      txname = planList[0]?.txName
    }
    if (!txsubname && planList[1]) {
      // if there is no txsubname in the DB
      txsubname = planList[1]?.txName
    }
    // if there is no plan
    if (!txname) {
      callback && callback()
      return
    }
    lockFunc(() => {
      updateCaseFun({ txname, txsubname, ...other })
      if (!isCasePreview) {
        // update pdf
        openOrUploadReportPdf("upload")
      }
      // update zips
      caseManagement.getCaseFiles(["bulk2"]).then((r) => {
        const zips = []
        if (r) {
          for (const k in r) {
            zips.push({
              fileName: k,
              file: r[k],
            })
          }

          dispatch(
            uploadCaseZips({
              zips,
              patientId,
              caseId,
            }),
          ).then(({ payload }) => {
            if (zips.length === payload.length) {
              for (let i = 0; i < payload.length; i++) {
                if (payload[i].status !== 200) {
                  return
                }
              }
              // caseManagement.closeCase()
              callback && callback()
            }
          })
        }
      })
    })
  }

  useEffect(() => {
    if (unlockAndLogout) unlockFunc()
  }, [unlockAndLogout])

  // 传递给3d view or edit
  useEffect(() => {
    if (!wasmModule.isInit) return
    viewEdit.switchViewToEdit(switchViewEdit)
  }, [switchViewEdit])

  useLayoutEffect(() => {
    return () => {
      console.log("clear wasm memory")
      caseManagement.closeCase()
    }
  }, [])

  useEffect(() => {
    console.info("treat currentPlan: ", currentPlan.txName, currentPlan.index)
  }, [currentPlan])

  useEffect(() => {
    console.info("treat planList: ", planList)
  }, [planList])

  const onclickCloseBtn = (callBack) => {
    if (
      (caseDetail.caseDisposition === CASE_STATUS.REVIEW_PLAN ||
        caseDetail.caseDisposition === CASE_STATUS.REVIEW_MODIFICATION ||
        caseDetail.caseDisposition === CASE_STATUS.FROM_UDESIGN ||
        caseDetail.caseDisposition === CASE_STATUS.SHIPPED) &&
      !isCreateNewRefinement
    ) {
      setIsShowAssign(true)
    } else {
      setIsShowAssign(false)
      callBack()
    }
  }

  // left smartRx
  const { smartRxShow, uassistRxShow } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Box
        ref={pageRef}
        sx={{
          display: "flex",
          top: 60,
          left: 0,
          bottom: 0,
          width: "100%",
          flexDirection: "column",
          border: "none",
        }}
      >
        <Box sx={{ flexGrow: 1, overflow: "hidden" }} position="relative">
          <WasmCanvas />
          <TopToolBar
            isShowAssign={isShowAssign}
            setLockType={setLockType}
            setOpenLockPopup={setOpenLockPopup}
            setisClickOrder={setisClickOrder}
            setispopupAfterOrder={setispopupAfterOrder}
            switchEditCallBack={() => {
              handleSwitchEdit()
            }}
            isEdit={switchViewEdit}
            ispopup={isClickOrder || !isSubmitReply}
            orderClick={handerOrderClick}
            onclickCloseBtn={onclickCloseBtn}
          />
          <BottomToolBar
            parentRef={pageRef}
            setTreatCurrentPlan={setCurrentPlan}
          />
          <ExtractedToothList />
          <ThemeSwitch
            sx={{
              position: "absolute",
              right: "180px",
              top: "-45px",
            }}
            value={darkMode}
            onChange={(e) => {
              setDarkMode(e.target.checked)
            }}
          />
          {workflowMode === "aidesign" && <LeftToolBar />}
          <LeftPages
            isEdit={switchViewEdit}
            setisSubmitModification={setisSubmitModification}
            setisSubmitReply={setisSubmitReply}
            isSubmitReply={isSubmitReply}
            isSubmitModification={isSubmitModification}
            showPrompt={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
            updateCaseFun={updateCaseFun}
          />
          <UDToothInfo isOpenCase={openCase} isEdit={switchViewEdit} />
          {openCase && <ToothHover />}

          <RightToolBar />
          <RightPages />
          <BiteRampButtons />
          <SmartRx
            needToSubmit={false}
            smartRxShow={smartRxShow}
            setSmartRxShow={setSmartRxShow}
            styleObj={{ left: 84 }}
          />
          <SmartRx
            unlockFunc={unlockFunc}
            needToSubmit={true}
            smartRxShow={uassistRxShow}
            setSmartRxShow={setUassistRxShow}
            styleObj={{ right: 84 }}
          />
          <UDOpenCase
            open={openCase}
            onClose={() => {
              setOpenCase(false)
            }}
            switchEditCallBack={() => {
              handleSwitchEdit()
            }}
            setIsFull={setIsFull}
          />
          <ConversionMask />
          <UDLockCaseForEdit />
          <UDBanner
            open={openWarning}
            onOK={() => {
              setOpenWarning(false)
            }}
          />
          {workflowMode === "smartrx" && <UDSmartRxWorkflow />}
          {workflowMode === "retainerrx" && <UDRetainerRxWorkflow />}
          {/* {showBottomStatusBar && <BottomStatusBar />} */}
          {/* <BottomStatusBar /> */}
          {/* curCaseMode === "WeDesign" && <WeStageBar /> */}
          {curCaseMode === "WeDesign" && <KFEditor />}
          <ReopenCaseDlg />
          {isCreateNewRefinement && (
            <CreateRefinement
              onClose={() => {
                // setOpenRefDialog(false)
                treatView.Open(false)
                dispatch(setStageShowType("normal"))
                dispatch(setIsCreateNewRefinement(false))
              }}
            ></CreateRefinement>
          )}
        </Box>
        <AttachmentWarning />
        {/* {!isCasePreview && <BottomToolBar />} */}
        {/* close no popup */}
        <NoPopup
          showPrompt={showPrompt && isSubmitModification && isSubmitReply}
          confirmNavigation={confirmNavigation}
          isActive={(isClickOrder && !ispopupAfterOrder) || !isClickOrder}
          uploadAllData={uploadAllData}
          isClickOrder={isClickOrder}
        />
        {/* case lock popup */}
        <CaseLockPopup
          lockType={lockType}
          openLockPopup={openLockPopup}
          setOpenLockPopup={setOpenLockPopup}
          lockInfo={lockInfo}
          clickControlOverride={clickControlOverride}
          allowControl={allowUnlock}
          uploadAllData={uploadAllData}
          cancelNavigation={cancelNavigation}
        />
        {/* close case popup */}
        <AssignPopup
          currentPlan={currentPlan}
          planList={planList}
          showPrompt={isShowAssign}
          // setCloseClick={setCloseClick}
          confirmNavigation={confirmNavigation}
          isActive={!isClickOrder}
          uploadReportPdf={() => openOrUploadReportPdf("upload")}
          uploadAllData={uploadAllData}
          deletePlanFunction={deletePlanFunction}
          isEdit={switchViewEdit}
          setIsShowAssign={setIsShowAssign}
          isFull={isFull}
        />
        {/* if 2 plan popup*/}
        <OrderPopup
          currentPlan={currentPlan}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
          isClickOrder={
            isClickOrder && ispopupAfterOrder
            //&& isSubmitModification
          }
          setisClickOrder={setisClickOrder}
          setispopupAfterOrder={setispopupAfterOrder}
          planList={planList}
          uploadReportPdf={() => openOrUploadReportPdf("upload")}
          deletePlanFunction={deletePlanFunction}
          lockFunc={lockFunc}
          uploadAllData={uploadAllData}
        ></OrderPopup>
        {/** Fill in the full report pdf */}
        <ScreeshotReport
          // isReady={preparation}
          isReady={true}
          attachment={reportData.attachment}
          toothlist={reportData.toothlist}
          iprData={reportData.iprData}
          ref={reportPdfRef}
        />
        <MessagePopup
          cancelNavigation={cancelNavigation}
          showPrompt={
            showPrompt &&
            ((isClickOrder
              ? !isSubmitModification && planList.length === 1
              : !isSubmitModification) ||
              !isSubmitReply)
          }
          setisSubmitModification={setisSubmitModification}
          setisSubmitReply={setisSubmitReply}
          isSubmitModification={isSubmitModification}
          uploadAllData={uploadAllData}
          confirmNavigation={confirmNavigation}
          isEdit={switchViewEdit}
        ></MessagePopup>
      </Box>
    </ThemeProvider>
  )
}
