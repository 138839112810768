import { UButton, UModalPopup, UText } from "@/ui-component"
import React, { createContext, useContext, useState, ReactNode } from "react"

interface CloseTabContextType {
  showCloseModal: () => void
  isModalOpen: boolean
}

export const CloseTabContext = createContext<CloseTabContextType | undefined>(
  undefined,
)

// Global function reference
let globalShowCloseModal: () => void = () => {}

export const CloseTabProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const showCloseModal = () => setModalOpen(true)

  // Store function in global variable
  globalShowCloseModal = showCloseModal

  return (
    <CloseTabContext.Provider value={{ showCloseModal, isModalOpen  }}>
      {children}

      {/* Close Tab Modal */}
      <UModalPopup
        sxModalProps={{
          "#contentCntr": {
            padding: 5,
          },
          // "#btnListCntr": {
          //   padding: 3,
          // },
          ".MuiDialog-paper": {
            maxWidth: 444,
            minHeight: "auto",
          },
        }}
        content={
          <UText variant={"body1"} sxProp={{ fontFamily:'Poppins' }}>
            {"Your case has been saved. You can now close your browser tab."}
          </UText>
        }
        isAlert={isModalOpen}
        btnList={[
          // <UButton
          //   key="ok"
          //   variant="contained"
          //   btnText="OK"
          //   onClickHandler={() => setModalOpen(false)}
          // />,
        ]}
      />
    </CloseTabContext.Provider>
  )
}

// Utility function for accessing the modal globally

export function safeCloseWindow() {
  if (window.opener) {
    window.close()
  } else {
    globalShowCloseModal()
  }
}
