import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

import { UText } from "@/components/index"
import { Box, Button, Grid } from "@/components/mui.components"
import NavigationBlockPopup from "@/components/NavigationBlockPopup/NavigationBlockPopup"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { setAlert } from "@/core/app/slices/alert/alertSlice"
import { getCaseById } from "@/core/app/slices/case/caseThunkApi"
import { uploadCaseZips } from "@/core/app/slices/clinical/clinicalThunkApi"
import { setIsRouteChangeBlocked } from "@/core/app/slices/navigationPopup/navigationPopupSlice"
import { updateOrder } from "@/core/app/slices/order/orderThunkApi"
import FORM_CONSTANT from "@/core/app/slices/uassist/form.constant"
import { getFormByName } from "@/core/app/slices/uassist/form.util"
import {
  resetPrescriptionSchema,
  resetRxRefinementFormEdit,
  updatePrescriptionData,
  updatePrescriptionSchema,
  updatePrescriptionSchemaArches,
} from "@/core/app/slices/uassist/formSlice"
import {
  fetchFormByCaseIdWithMergeData,
  fetchFormByCaseIdWithMergeDataRefinement,
  getSmartTemplateData,
  updateFormSavedData,
} from "@/core/app/slices/uassist/formThunkApi"
import { RootState } from "@/core/app/store"
import { checkIfObjectHasKeys, parseToObject } from "@/core/utils/formatters"
import { caseManagement, stageControl } from "@/gluelayer"
import { useCallbackPrompt } from "@/hooks/useCallbackPrompt"
import JsonFormBuilder from "@/rx-form/builder/JsonFormBuilder"

import { NoUassistPopup } from "./NoUassistPopup"
import Summary from "./Summary"
import SummaryForView from "./SummaryForView"
import RenderSmartRxForm from "@/UDTreat/USmartForms/RenderSmartRxForm"
import { CASE_STATUS } from "@/modules/Patient/config/status.config"
import LeavePagePopup from "./leavePagePopup"
// show and add refinement

type archesToTreatType = {
  upper?: { [key: string]: any }
  lower?: { [key: string]: any }
}
const OrderFormsRefinement: FC<{
  needToSubmit?: boolean
  reviewNum?: number
  setActionType?: (val: string) => void
  actionType?: string
  currentReviewNum?: number
  isAdd: boolean
  setStep?: (val: number) => void
  smartRxGeneratePDF?: boolean
  setSmartRxGeneratePDF?: (val: boolean) => void
}> = ({
  needToSubmit = true,
  reviewNum = 0,
  setActionType = () => {},
  actionType = "",
  currentReviewNum,
  isAdd,
  setStep,
  smartRxGeneratePDF,
  setSmartRxGeneratePDF,
}) => {
  const { clinicalSettings } = useAppSelector(
    (state: RootState) => state.userService,
  )
  const { refinementNum, stageObjBySlice } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const { upList, downList } = stageObjBySlice
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const [schemaWithNumberOfAligners, setSchemaWithNumberOfAligners] = useState(
    {},
  )
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const [error, setError] = useState([])
  const { patientId, caseId } = useParams()
  const [downloadPDF, setDownloadPDF] = useState<boolean>(false)
  const [showNoUassistAlert, setShowUNoUassistAlert] = useState<boolean>(false)
  const [showRXForm, setShowRXForm] = useState<string | null>(null)
  const [showPopup, setShowPopup] = useState(false)
  const { formTemplateList, rxPrescription } = useAppSelector(
    (state: RootState) => state.formService,
  )

  const { isRouteChangeBlocked } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )
  const { caseList, caseDetail } = useAppSelector(
    (state: RootState) => state.caseService,
  )
  const { uassist_progress } = caseDetail
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)

    const [isViewSummaryFormOnly, setIsViewSummaryFormOnly] = useState<boolean>(false) // true: show only summary form, false: show form & download pdf

  const handelTemplateAction = (template: { id: string; value: string }) => {
    setShowRXForm(template.id)
    setActionType("smartRX")
  }
  function updateRxPrescriptionSchema(
    tempenum: ({ name: string; enum: any[] } | { name: string; enum: any[] })[],
    archesToTreat: archesToTreatType,
  ) {
    if (checkIfObjectHasKeys(rxPrescription.schema)) {
      let updatedProperties = {}
      const { properties } = rxPrescription.schema
      if (properties && properties["smart_rx_template"]) {
        // Create a new properties object to avoid mutation
        updatedProperties = {
          ...properties,
          smart_rx_template: {
            ...properties["smart_rx_template"],
            options: {
              ...properties["smart_rx_template"].options,
              handelBtnAction: handelTemplateAction,
            },
          },
        }
      }

      updatedProperties = {
        ...updatedProperties,
        current_aligner_number: {
          ...rxPrescription.schema.properties?.current_aligner_number,
          inputGroup: tempenum,
        },
        arches_to_treat: {
          ...rxPrescription.schema.properties?.arches_to_treat,
          properties: archesToTreat,
        },
      }
      if (
        rxPrescription.id ===
        getFormByName(formTemplateList, "UASSIST", FORM_CONSTANT.REFINEMENT_2)
          ?.id
      ) {
        setSchemaWithNumberOfAligners({
          ...rxPrescription.schema,
          properties: updatedProperties,
        })
      } else {
        setSchemaWithNumberOfAligners({
          ...rxPrescription.schema,
          properties: {
            ...rxPrescription.schema.properties,
            current_aligner_number: {
              ...rxPrescription.schema.properties?.current_aligner_number,
              inputGroup: tempenum,
            },
          },
        })
      }
    }
  }
  // }, [caseList, rxPrescription.schema])
  useEffect(() => {
    let err = []
    const getUassistFormIdByName = (name: string) =>
      getFormByName(formTemplateList, "UASSIST", name).id

    if (
      rxPrescription.id === getUassistFormIdByName(FORM_CONSTANT.REFINEMENT)
    ) {
      if (rxPrescription.schema?.required?.length && !rxPrescription.saveid) {
        err = Object.keys(rxPrescription.schema.required).map((key) => {
          return {
            instancePath: "/" + rxPrescription.schema.required[key],
          }
        })
      }
    }
    if (
      rxPrescription.id === getUassistFormIdByName(FORM_CONSTANT.REFINEMENT_2)
    ) {
      const temTmp = [
        "deep_bite_template_instructions",
        "spacing_template_instructions",
        "crowding_template_instructions",
        "open_bite_template_instructions",
        "limited_treatment_template_instructions",
      ]
      if (rxPrescription.schema?.required?.length && !rxPrescription.saveid) {
        err = Object.keys(rxPrescription.schema.required).map((key) => {
          if (!temTmp.includes(rxPrescription.schema.required[key])) {
            return {
              instancePath: "/" + rxPrescription.schema.required[key],
            }
          }
        })
      }
    }

    const filteredErr = err.filter((item) => item !== undefined)

    const udesignCategory = caseList[0]?.udesign_category
    if (udesignCategory && checkIfObjectHasKeys(rxPrescription.data)) {
      const arches = udesignCategory.split("/")
      const data = []
      if (arches[0].includes("R") || arches[0].includes("A")) {
        data.push("upper")
      }
      if (arches[1].includes("R") || arches[1].includes("A")) {
        data.push("lower")
      }
      data.length
        ? setError([
            ...filteredErr.filter(
              (err) => err.instancePath !== "/arches_to_treat",
            ),
          ])
        : setError([...filteredErr])
      if (
        rxPrescription.id ===
          getUassistFormIdByName(FORM_CONSTANT.REFINEMENT).id &&
        !rxPrescription?.data?.arches_to_treat?.length
      )
        handleDataChange({ ...rxPrescription.data, arches_to_treat: data }, [])
    }
    // update the default stage number
    if (caseList[0]?.udesign_json && rxPrescription.id) {
      const obj = JSON.parse(caseList[0].udesign_json)
      const udesign_json = JSON.parse(caseDetail.udesign_json)
      const tempenum = []
      const temparches = {
        ...rxPrescription.schema.properties?.arches_to_treat?.properties,
      }
      let archesToTreat: archesToTreatType
      if (udesign_json.UpperType === "A" || udesign_json.UpperType === "R") {
        tempenum.push({ name: "Upper", enum: [] })
        archesToTreat = { ...archesToTreat, upper: temparches?.upper }
      }
      if (udesign_json.LowerType === "A" || udesign_json.LowerType === "R") {
        tempenum.push({ name: "Lower", enum: [] })
        archesToTreat = { ...archesToTreat, lower: temparches?.lower }
      }

      tempenum.map((item) => {
        if (item.name === "Upper") {
          for (let i = 0; i < upList.length; i++) {
            item.enum.push(upList[i].showStage)
          }
        } else {
          for (let i = 0; i < downList.length; i++) {
            item.enum.push(downList[i].showStage)
          }
        }
      })
      updateRxPrescriptionSchema(tempenum, archesToTreat)
      
      if (
        rxPrescription.schema.properties &&
        caseList[0].udesign_json &&
        rxPrescription.id === getFormByName(formTemplateList, "UASSIST", FORM_CONSTANT.REFINEMENT).id
      ){
        // In Refinement form 1, make the arches_to_treat values Upper and Lower present 
        // if only the respective stl in uploaded and make it uneditably checked.

        let archesToTreatSchema = {...rxPrescription.schema.properties.arches_to_treat}
        let archesToTreatData = []

        if( udesign_json.UpperType!=="--" || udesign_json.upperUploaded ){
          archesToTreatData.push('upper')
          if(!archesToTreatSchema.checkboxes.find(x=>x.value=='upper')){
            archesToTreatSchema.checkboxes.push({

            })
          }
        }else{
          archesToTreatSchema = archesToTreatSchema.checkboxes.filter(arch=>arch.value!=="upper")
        }

        if( udesign_json.LowerType!=="--" || udesign_json.lowerUploaded ){
          archesToTreatData.push('lower')
        }else{
          archesToTreatSchema = archesToTreatSchema.checkboxes.filter(arch=>arch.value!=="lower")
        }

        if(!archesToTreatSchema.isCheckBoxGroupDisabled){
          archesToTreatSchema.isCheckBoxGroupDisabled = true
          dispatch(updatePrescriptionSchemaArches(archesToTreatSchema))
        }

        dispatch(updatePrescriptionData({
          ...rxPrescription.data,
          arches_to_treat: archesToTreatData,
        }))
      }

      if (
        rxPrescription.schema.properties &&
        caseList[0].udesign_json &&
        rxPrescription.id ===
          getFormByName(formTemplateList, "UASSIST", FORM_CONSTANT.REFINEMENT_2)
            .id
      ) {
        const udesign_json = JSON.parse(caseList[0].udesign_json)
        const udesignCategory = caseList[0]?.udesign_category
        const arches = udesignCategory && udesignCategory.split("/")
        let tempData = { ...rxPrescription.data.arches_to_treat }
        const tempSchema = {
          ...rxPrescription.schema.properties.arches_to_treat.properties,
        }
        if ( udesign_json.upperUploaded) {
          if (rxPrescription.saveid && tempData.upper) {
            delete tempData.upper
          }
          if (rxPrescription.schema.properties) {
            if (tempSchema.upper) {
              delete tempSchema.upper
              dispatch(updatePrescriptionSchema(tempSchema))
            }
          }
        } else if(!rxPrescription.saveid){
          tempData = {
            ...tempData,
            upper: {
              isChecked: "Upper",
              cover_lingual_bar: "",
              aligner_type: "Aligner",
            },
          }
        }
        if (udesign_json.lowerUploaded) {
          if (rxPrescription.saveid && tempData.lower) {
            delete tempData.lower
          }
          if (rxPrescription.schema.properties) {
            if (tempSchema.lower) {
              delete tempSchema.lower
              dispatch(updatePrescriptionSchema(tempSchema))
            }
          }
        } else if(!rxPrescription.saveid){
          tempData = {
            ...tempData,
            lower: {
              isChecked: "Lower",
              cover_lingual_bar: "",
              aligner_type: "Aligner",
            },
          }
        }
        if (!rxPrescription.saveid) {
          dispatch(
            updatePrescriptionData({
              ...rxPrescription.data,
              arches_to_treat: tempData,
              current_aligner_number: {
                Upper: rxPrescription.data.current_aligner_number?.Upper
                  ? rxPrescription.data.current_aligner_number.Upper
                  : upList.length > 0
                  ? upList[upList.length - 1].showStage
                  : upList.length,
                Lower: rxPrescription.data.current_aligner_number?.Lower
                  ? rxPrescription.data.current_aligner_number.Lower
                  : downList.length > 0
                  ? downList[downList.length - 1].showStage
                  : downList.length,
              },
            }),
          )
        }
      }
    }
  }, [caseList, rxPrescription.schema, formTemplateList])

  useEffect(() => {
    window.scrollTo({ top: 0 })
    const getUassistFormIdByName = (name: string) =>
      getFormByName(formTemplateList, "UASSIST", name).id

    // uassist button show the refinement form
    if (
      isAdd &&
      rxPrescription.id === "" &&
      caseDetail &&
      caseDetail.uassist_round >= 0
    ) {
      let addFormId = ""
      if (uassist_progress === "REFINEMENT_READY") {
        addFormId = getUassistFormIdByName(FORM_CONSTANT.REFINEMENT)
      } else if (
        stageControl.getMaxStageNodeIndex() > 0 &&
        uassist_progress === "READY"
      ) {
        addFormId = getUassistFormIdByName(FORM_CONSTANT.REFINEMENT_2)
      }

      dispatch(
        fetchFormByCaseIdWithMergeData({
          formId: addFormId,
          caseId: caseId,
          getSavedData: true,
          patientId: patientId,
          formType: "UASSIST",
          uassistRound: caseDetail.uassist_round + 1, // Adding so we increment uassist_round
          // optionObj: {
          //   isAdd: isAdd,
          //   currentReviewNum,
          //   refinementNum,
          // },
        }),
      )
    }
  }, [dispatch, caseDetail, formTemplateList])
  useEffect(() => {
    window.scrollTo({ top: 0 })
    const getUassistFormIdByName = (name: string) =>
      getFormByName(formTemplateList, "UASSIST", name).id

    // smart rx button show the refinement form
    if (currentReviewNum > 0 && !isAdd) {
      dispatch(
        fetchFormByCaseIdWithMergeData({
          // formId: getUassistFormIdByName(FORM_CONSTANT.REFINEMENT),
          caseId: caseId,
          getSavedData: true,
          patientId: patientId,
          formType: "UASSIST",
          uassistRound: currentReviewNum,
          // optionObj: {
          //   isAdd: isAdd,
          //   currentReviewNum,
          //   refinementNum,
          // },
        }),
      )
    }
  }, [currentReviewNum, formTemplateList])
  useEffect(() => {
    return () => {
      if (!isAdd) {
        dispatch(resetRxRefinementFormEdit())
        dispatch(resetPrescriptionSchema())
      }
    }
  }, [])

  useEffect(() => {
    if (rxPrescription?.data?.stage_ipr) {
      if (
        rxPrescription?.data?.stage_ipr === "Not applicable" &&
        error.length
      ) {
        setError(
          error.filter(
            (item) =>
              item.instancePath !== "/stage_ipr" &&
              item.instancePath !== "/max_interproximal_conatct",
          ),
        )
      }
    }
  }, [rxPrescription?.data?.stage_ipr])
  // ???
  useEffect(() => {
    const upper = rxPrescription?.data?.current_aligner_number?.Upper;
    const lower = rxPrescription?.data?.current_aligner_number?.Lower;
  
    // If both upper and lower are 0 → Add error if not present
    if (
      Number(upper) === 0 &&
      Number(lower) === 0 &&
      !error.some((e) => e.instancePath === "/current_aligner_number")
    ) {
      const errorObj = {
        instancePath: "/current_aligner_number",
      }
      setError((prev) => [...prev, errorObj])
    }
  
    // If either upper or lower is non-zero → Remove existing error
    if (
      (Number(upper) !== 0 || Number(lower) !== 0) &&
      error.some((e) => e.instancePath === "/current_aligner_number")
    ) {
      setError((prev) =>
        prev.filter((e) => e.instancePath !== "/current_aligner_number")
      )
    }
  }, [
    rxPrescription?.data?.current_aligner_number?.Upper,
    rxPrescription?.data?.current_aligner_number?.Lower,
    error,
  ])   
  // useEffect(() => {
  //   if (!needToSubmit) {
  //     return
  //   }
  //   console.log(error, rxPrescription.data, "submitting retainer form")

  //   saveform()
  // }, [error, downloadPDF])
  useEffect(() => {
    if (reviewNum) {
      setIsFormSubmitted(true)
      if (!needToSubmit) {
        if (actionType === "edit") {
          saveFormOnly()
        }
      }
    }
    if (reviewNum === 0) {
      setDownloadPDF(false)
    }
  }, [reviewNum])
  // refinement_2 will chose smartRx template get case_specific_instructions to change refinementAdd.data.xxx_template_instructions
  useEffect(() => {
    if (
      rxPrescription.id ===
      getFormByName(formTemplateList, "UASSIST", FORM_CONSTANT.REFINEMENT_2).id
    ) {
      if (rxPrescription.data.smart_rx_template_id) {
        // get smartRx template data and give it to rxPrescription.data      
        dispatch(
          getSmartTemplateData(rxPrescription.data.smart_rx_template_id.id),
        ).then((res) => {
          if (res) {
            const { payload } = res
            const { default_data } = payload
            const smartRxData = parseToObject(default_data)
            if (Object.keys(smartRxData).length) {
              const currentSmartData = smartRxData.case_specific_instructions
              const currentSmart =
                rxPrescription.data.smart_rx_template.toLowerCase()
              const currentSmartKey =
                currentSmart.split(" ").join("_") + "_template_instructions"

              // obj[currentSmartKey] = currentSmartData
              const obj = { ...rxPrescription.data }
              obj[currentSmartKey] = currentSmartData
              dispatch(updatePrescriptionData({ ...obj }))
            }
          }
        })
      }
    }
  }, [rxPrescription.data.smart_rx_template_id])

  useEffect(() => {
    if (
      actionType === "review" && !needToSubmit
    ) {
      setIsViewSummaryFormOnly(true)
      setDownloadPDF(true)
    }
  }, [actionType])

  const handleDataChange = (data: any, errors: any) => {
    setError((prevErrors: any[]) => {
      let updatedErrors = prevErrors.filter(
        (e) => e.instancePath !== "/current_aligner_number"
      )
  
      if (
        prevErrors.some((e) => e.instancePath === "/current_aligner_number") &&
        (data?.current_aligner_number?.Upper || data?.current_aligner_number?.Lower)
      ) {
        updatedErrors = updatedErrors.filter(
          (e) => e.instancePath !== "/current_aligner_number"
        )
      }
  
      if (
        prevErrors.some((e) => e.instancePath === "/smart_rx_template") &&
        data?.smart_rx_template
      ) {
        updatedErrors = updatedErrors.filter(
          (e) => e.instancePath !== "/smart_rx_template"
        )
      }
  
      if (errors.length) {
        const currError = errors[0]
        if (currError.instancePath) {
          const index = updatedErrors.findIndex(
            (err) => err.instancePath === currError.instancePath
          )
  
          if (index > -1) {
            if (currError.message === "") {
              updatedErrors.splice(index, 1) // Remove if message is empty
            } else {
              updatedErrors[index] = currError // Update existing error
            }
          } else if (currError.message !== "") {
            updatedErrors.push(currError) // Add new error
          }
        }
      }
  
      return updatedErrors
    })
  
    console.log(data, errors, "...error")
    dispatch(updatePrescriptionData(data))
  }  
  const saveform = async (fromSubmit) => {
    console.log(error,"...errors", downloadPDF)
    if (downloadPDF) return
    if (error.length) {
      const getFocusId = setTimeout(() => {
        const errorElms = document.getElementsByClassName("Mui-error")
        if (errorElms.length > 0) {
          errorElms[0].scrollIntoView({ behavior: "smooth" })
        }
        clearTimeout(getFocusId)
      }, 100)
    } else if (fromSubmit) {
      if (caseDetail && caseDetail.uassist_progress === "NO_UASSIST") {
        setShowUNoUassistAlert(true)
      } else {
        let isUpperArchPresent = false
        let isLowerArchPresent = false
      
        if (
          rxPrescription.id ===
          getFormByName(formTemplateList, "UASSIST", FORM_CONSTANT.REFINEMENT_2).id
        ) {
          isUpperArchPresent = rxPrescription?.data?.arches_to_treat?.upper
          isLowerArchPresent = rxPrescription?.data?.arches_to_treat?.lower
        } else {
          isUpperArchPresent = 
          rxPrescription?.data?.arches_to_treat?.includes("upper")
          isLowerArchPresent = 
          rxPrescription?.data?.arches_to_treat?.includes("lower")
        }
        const isarchSaved = await caseManagement.saveDoRefinementStage(
          !isUpperArchPresent ? "-1010" : rxPrescription?.data?.current_aligner_number?.Upper,
          !isLowerArchPresent ? "-1010" : rxPrescription?.data?.current_aligner_number?.Lower
        )
      
        const CaseType = caseDetail.udesign_category.includes("A") ? "Aligner" : "Retainer";
      
        const getUpperArch = () => {
          if (
            rxPrescription.id ===
            getFormByName(
              formTemplateList,
              "UASSIST",
              FORM_CONSTANT.REFINEMENT_2,
            ).id
          ) {
            return rxPrescription?.data?.arches_to_treat?.upper?.aligner_type
          } else {
            return CaseType
          }
        }
      
        const getLowerArch = () => {
          if (
            rxPrescription.id ===
            getFormByName(
              formTemplateList,
              "UASSIST",
              FORM_CONSTANT.REFINEMENT_2,
            ).id
          ) {
            return rxPrescription?.data?.arches_to_treat?.lower?.aligner_type
          } else {
            return CaseType
          }
        }

        const isJsonUpdatedToZip = await caseManagement.saveJsonDataToZip(
          JSON.stringify({
            upperTPStep: !isUpperArchPresent
              ? "-1010"
              : rxPrescription?.data?.current_aligner_number?.Upper ?? "0",
            lowerTPStep: !isLowerArchPresent
              ? "-1010"
              : rxPrescription?.data?.current_aligner_number?.Lower ?? "0",
            UpperArch: !isUpperArchPresent ? "" : getUpperArch(),
            LowerArch: !isLowerArchPresent ? "" : getLowerArch(),
          }),
          "paras.json",
          "Setting Data10/refinement",
        )
        if (isarchSaved && isJsonUpdatedToZip) {
          caseManagement
            .saveJsonDataToZip(
              JSON.stringify(clinicalSettings),
              "uassist_team_settings.json",
              "Setting Data2",
            )
            .then((res) => {
              if (res) {
                caseManagement.getCaseFiles(["bulk2", "bulk10"]).then((r) => {
                  const zips = []
                  if (r) {
                    for (const k in r) {
                      zips.push({
                        fileName: k,
                        file: r[k],
                      })
                    }
                    dispatch(
                      uploadCaseZips({
                        zips,
                        patientId,
                        caseId,
                      }),
                    ).then(() => {
                      dispatch(
                        updateFormSavedData({
                          formId: rxPrescription.id,
                          caseId: caseId,
                          payload: rxPrescription.data,
                          savedId: rxPrescription.saveid,
                          isRefinement: true,
                        }),
                      ).then((res) => {
                        if (res.payload.status === 200 && rxPrescription?.id) {
                          dispatch(
                            setIsRouteChangeBlocked({
                              isRouteChangeBlocked: false,
                            }),
                          )
                          dispatch(
                            setAlert({
                              message: t(
                                "uassist.uAssistForm.refinementsavesuccess",
                              ),
                            }),
                          )
                          if (caseDetail && caseDetail.udesign_json) {
                            const udesignCategory = caseDetail?.udesign_category
                            const arches =
                              udesignCategory && udesignCategory.split("/")
                            const upper = arches && arches[0]
                            const lower = arches && arches[1]
                            let upperStr = ""
                            let lowerStr = ""
                            const getUassistFormIdByName = (name: string) =>
                              getFormByName(formTemplateList, "UASSIST", name)
                                .id

                            if (
                              rxPrescription.id ===
                              getUassistFormIdByName(FORM_CONSTANT.REFINEMENT)
                            ) {
                              lowerStr =
                                rxPrescription.data.arches_to_treat.includes(
                                  "lower",
                                )
                                  ? lower
                                  : ""
                              upperStr =
                                rxPrescription.data.arches_to_treat.includes(
                                  "upper",
                                )
                                  ? upper
                                  : ""
                            } else if (
                              rxPrescription.id ===
                              getUassistFormIdByName(FORM_CONSTANT.REFINEMENT_2)
                            ) {
                              upperStr = rxPrescription.data.arches_to_treat
                                .upper
                                ? rxPrescription.data.arches_to_treat.upper
                                    .aligner_type === "Aligner"
                                  ? "A"
                                  : "R"
                                : ""

                              lowerStr = rxPrescription.data.arches_to_treat
                                .lower
                                ? rxPrescription.data.arches_to_treat.lower
                                    .aligner_type === "Aligner"
                                  ? "A"
                                  : "R"
                                : ""
                            }
                            setDownloadPDF(true)
                          }
                        } else {
                          dispatch(
                            setAlert({
                              message:
                                rxPrescription?.id &&
                                t("uassist.uAssistForm.refinementsavefailed"),
                            }),
                          )
                        }
                      })
                    })
                  }
                })
              }
            })
        }
      }
    }
  }
  //refinement add
  //smart show
  // update only form function
  const saveFormOnly = async () => {
    if (error.length) {
      const getFocusId = setTimeout(() => {
        const errorElms = document.getElementsByClassName("Mui-error")
        if (errorElms.length > 0) {
          errorElms[0].scrollIntoView({ behavior: "smooth" })
        }
        clearTimeout(getFocusId)
      }, 100)
    } else {
      if (caseDetail && caseDetail.uassist_progress === "NO_UASSIST") {
        setShowUNoUassistAlert(true)
      } else {
        let isUpperArchPresent = null
        let isLowerArchPresent = null
        if (
          rxPrescription.id ===
          getFormByName(formTemplateList, "UASSIST", FORM_CONSTANT.REFINEMENT_2)
            .id
        ) {
          isUpperArchPresent = rxPrescription?.data?.arches_to_treat?.upper
          isLowerArchPresent = rxPrescription?.data?.arches_to_treat?.lower
        } else {
          isUpperArchPresent =
            rxPrescription?.data?.arches_to_treat?.includes("upper")
          isLowerArchPresent =
            rxPrescription?.data?.arches_to_treat?.includes("lower")
        }
        const isarchSaved = await caseManagement.saveDoRefinementStage(
          !isUpperArchPresent
            ? "-1010"
            : rxPrescription?.data?.current_aligner_number?.Upper,
          !isLowerArchPresent
            ? "-1010"
            : rxPrescription?.data?.current_aligner_number?.Lower,
        )
        if (isarchSaved) {
          caseManagement.getCaseFiles(["bulk2", "bulk10"]).then((r) => {
            const zips = []
            if (r) {
              for (const k in r) {
                zips.push({
                  fileName: k,
                  file: r[k],
                })
              }
              dispatch(
                uploadCaseZips({
                  zips,
                  patientId,
                  caseId,
                }),
              ).then(() => {
                dispatch(
                  updateFormSavedData({
                    formId: rxPrescription.id,
                    caseId: caseId,
                    payload: rxPrescription.data,
                    savedId: rxPrescription.saveid,
                    isRefinement: true,
                  }),
                ).then((res) => {
                  if (res.payload.status === 200 && rxPrescription?.id) {
                    // dispatch(getCaseById({ patientId, caseId }))
                    setActionType("review")
                    setDownloadPDF(true)
                  }
                })
              })
            }
          })
        }
      }
    }
  }
  return (
    <>
      <NoUassistPopup
        showPopup={showNoUassistAlert}
        setShowPopup={setShowUNoUassistAlert}
        setIsPdfLoading={setIsFormSubmitted}
      />
      <Grid
        container
        flexDirection={"column"}
        sx={{
          backgroundColor: "background.default",
          borderRadius: 2,
          paddingTop: 0,
          justifyContent: "start",
        }}
      >
        {(!showRXForm && rxPrescription?.id) || showRXForm || (downloadPDF && rxPrescription?.id) ? (
          <Grid item sx={{ display: "flex", justifyContent: "center" }}>
            {/* {rxPrescription?.id && (
                <UText
                  variant={"h4"}
                  sxProp={{ textAlign: "center", mb: "16px" }}
                >
                  {t("uassist.uAssistForm.refinementTitle")}
                </UText>
              )} */}
            <UText variant={"h4"} sxProp={{ textAlign: "center", mb: "16px" }}>
              {downloadPDF ? t("uassist.uDC.refinementSummary") : t("uassist.uAssistForm.refinementTitle")}
            </UText>
          </Grid>
        ) : (
          <UText variant="body2" sxProp={{ textAlign: "center", color: "text.secondary"}}>
            {t("uassist.noUassistFormSubmitted")}
          </UText>
        )}
        {!downloadPDF && !smartRxGeneratePDF && caseDetail?.caseDisposition !== "SUBMITTED_TO_UASSIST" && (
          <Grid item>
            {!showRXForm ? (
              !!(
                checkIfObjectHasKeys(schemaWithNumberOfAligners) &&
                checkIfObjectHasKeys(rxPrescription.uischema) &&
                checkIfObjectHasKeys(rxPrescription.data)
              ) && (
                <JsonFormBuilder
                  schema={schemaWithNumberOfAligners}
                  uischema={rxPrescription.uischema}
                  defaultData={rxPrescription.data}
                  handleChange={handleDataChange}
                  isFormSubmitted={isFormSubmitted}
                  setIsFormSubmitted={setIsFormSubmitted}
                />
              )
            ) : (
              <RenderSmartRxForm
                setActionType={setActionType}
                setShowRXForm={setShowRXForm}
                formId={showRXForm}
                actionType={actionType}
                setSmartRxGeneratePDF={setSmartRxGeneratePDF}
              />
            )}
          </Grid>
        )}
        {(downloadPDF || smartRxGeneratePDF || caseDetail?.caseDisposition === "SUBMITTED_TO_UASSIST" ) && rxPrescription?.id && (
          <Box>
            <Summary
              setDownloadPdf={setDownloadPDF}
              setActionType={setActionType}
              needToSubmit={needToSubmit}
              isRefinement={true}
              noPdfDownload={isViewSummaryFormOnly}
              smartRxGeneratePDF={smartRxGeneratePDF}
              setSmartRxGeneratePDF={setSmartRxGeneratePDF}
            />
          </Box>
        )}

        <Grid item container justifyContent={"space-between"} mt={2}>
          {rxPrescription.id && !showRXForm && !downloadPDF && needToSubmit && (
            <>
              <Button
                variant={"outlined"}
                onClick={() => {
                  if (isRouteChangeBlocked) {
                    setShowPopup(true)
                  } else {
                    setStep && setStep(1)
                  }
                  // navigate(
                  //   `/overview/patient/${patientId}/case/${caseId}/courcerefinement/xray`,
                  // )
                }}
                sx={{ width: "92px", height: "36px" }}
              >
                <ChevronLeftIcon
                  fontSize={"small"}
                  sx={{ marginLeft: "3px" }}
                />
                {t("button.back")}
              </Button>
              {needToSubmit ? (
                <Button
                  variant={"contained"}
                  sx={{ width: "146px", height: "36px" }}
                  onClick={() => {
                    setDownloadPDF(false)
                    setIsFormSubmitted(true)
                    saveform(true)
                  }}
                >
                  {"Review"}
                  <ChevronRightIcon
                    fontSize={"small"}
                    sx={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "8px",
                    }}
                  />
                </Button>
              ) : (
                <></>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <LeavePagePopup
        showPopup={showPopup}
        setStep={setStep}
        setShowPopup={setShowPopup}
      />
    </>
  )
}

export default OrderFormsRefinement
