import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch } from "@/core/app/hooks"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import {
  caseLockByCRB,
  caseLockByOtherUser,
  caseLockByShipped,
  caseLockByUassist,
} from "@/UDTreat/bottom/Toggle/column"
import { UButton } from "@/ui-component"
import { useTranslation } from "react-i18next"
import { useParams, useSearchParams } from "react-router-dom"
import { caseManagement } from "@/gluelayer"
import DialogBox from "../Clinical/components/DialogBox/dialogBox"
import { safeCloseWindow } from "@/core/app/CloseTabContext"

function OrderCaseLockPopup({
  isPopupOpen,
  setIsPopupOpen,
  lockInfo,
  errorInfo,
  forceUnlock,
}: {
  isPopupOpen: boolean
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>
  lockInfo: {
    username: string
    device_info: string
    locked_date: string
  } | null
  errorInfo: string
  forceUnlock: ()=>void
}) {
  const navigate = useMyNavigation()
  const { t } = useTranslation("common")
  const { caseId, patientId } = useParams()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const [allowToTakeLock, setAllowToTakeLock] = useState(true)
  const [contentText, setContentText] = useState<string | JSX.Element>("")

  useEffect(() => {
    if (errorInfo) {
      if (caseLockByUassist.includes(errorInfo)) {
        // Case Locked By UAssist
        // Dont allow to take control
        setAllowToTakeLock(false)
        setContentText(t("treat.lockPopUp.uassist1"))
      } else if (caseLockByCRB.includes(errorInfo)) {
        // Case Locked By CRB
        // Dont allow to take control
        setAllowToTakeLock(false)
        setContentText(t("treat.lockPopUp.byCRB"))
      } else if (caseLockByShipped.includes(errorInfo)) {
        // Case Already Ordered,
        // Redirect to /patients
        setAllowToTakeLock(false)
        setContentText(t("treat.lockPopUp.orderPlace"))
      } else if (caseLockByOtherUser.includes(errorInfo)) {
        // Case locked by other user
        // Allow to take control
        setAllowToTakeLock(true)
        setContentText(
          <>
            {t("treat.lockPopUp.otherUser")}
            <br />
            <span
              style={{
                color: "#000",
                fontSize: "12px",
                letterSpacing: "0.4px",
              }}
            >{`${lockInfo.username}\u00A0\u00A0\u00A0${lockInfo.device_info}`}</span>
            <br />
            <br />
            {t("treat.lockPopUp.controlContent")}
          </>
        )
        }
    }
  }, [isPopupOpen, errorInfo, lockInfo])

  async function leaveOrderProcess() {
    caseManagement.closeCase()
    setIsPopupOpen(false)
    safeCloseWindow()
    // if( searchParams.get('from') === "patientList" ){
    //   console.log("opened in a new tab, closing now")
    //   window.close()
    // }
    // if from patientList and still not closed, then the window is not opened in a new tab
    // continue to navigate to /patients
    // navigate(`/patients`)
  }

  return (
    <DialogBox
      open={isPopupOpen}
      title={t("treat.lockPopUp.title")}
      content={
        <p style={{ margin: "0", fontSize: "14px" }}>
          {contentText}
        </p>
      }
      actions={
        <>
          <UButton
            onClickHandler={leaveOrderProcess}
            variant={"text"}
            btnText={t("button.leaveOrderProcess")}
          />
          {allowToTakeLock && (
            <UButton
              onClickHandler={async ()=>{
                await forceUnlock()
                setIsPopupOpen(false)
              }}
              variant={"contained"}
              btnText={t("treat.lockPopUp.control")}
            />
          )}
        </>
      }
    />
  )
}

export default OrderCaseLockPopup
