import { resourcesSynchronization } from "../resourcemanager/resourcessynchronization"
import {
  wasmModule,
  WASMLifeCircleEventType,
} from "../../modules/wasm/wasmModule"

import { EShowArchType } from "../../common/types"
import { IDrawRefinementSTLOps, caseManagement } from "../../interfaces"
import { resourceManager } from "../resourcemanager"
import { Mi } from "../../../thirdparts/index"
import * as lodash from "lodash"

/**
 *  WASM interface of preview
 *
 * enable preview
 * this will invoke a loop can't do something after invoke this function
 * wasmModule.module._scanPreview();
 *
 * set zoom callback
 * wasmModule.module.getScanPreviewWin().SetUpdateSliderCB
 *
 * set zoom
 * wasmModule.module.getScanPreviewWin().ZoomBySlider(scale)
 *
 * set range of zoom scale
 * wasmModule.module.getScanPreviewWin().SetMinScaleValue(double minScaleValue)
 * wasmModule.module.getScanPreviewWin().SetMaxScaleValue(double maxScaleValue)
 *
 * change arch
 * wasmModule.module.getScanPreviewWin().OnArchChanged(type)
 *
 * drwa arch
 * wasmModule.module.getScanPreviewWin().UpdateArch('upper arch path', 'lower arch path')
 * wasmModule.module.getScanPreviewWin().RemoveArch(isupper:boolean,islower:boolean);
 *
 * fill hole
 * wasmModule.module._autoFillHoles(wasmModule.module.allocateUTF8('test/pre/arch_u.stl'), wasmModule.module.allocateUTF8('test/pre/fillready_arch_u.stl'))
 *
 *
 */

interface IDrawSTLOps {
  upperArch: File | null
  lowerArch: File | null
  canvas: HTMLCanvasElement
  zoomRange: [number, number]
  onZoomChange?: (zoomScaleVal: number) => void
  fillholeCallback?: (isSuccess: boolean, isUpperFlag: "up" | "low") => void
  isShowRefinement: boolean
}

export enum EArchTreatmentType {
  Aligner = 0,
  Retainer = 1,
  /**
   * not used in current version
   */
  RetainerMove = 2,
}
/**
 * draw STL when upload file
 */
let currentShowSTLUpper: Blob | null = null
let currentShowSTLLower: Blob | null = null
let stlFilePath: Record<string, any> = {}
let isEnabled = false

export function saveArchType(
  upperType: EArchTreatmentType | null,
  lowerType: EArchTreatmentType | null,
) {
  const archTypeData = resourcesSynchronization.getFileDirectly(
    "ArchType.json",
    "Setting Data2",
    "utf8",
  )

  if (!archTypeData) return

  const dataObject = JSON.parse(archTypeData)
  // eslint-disable-next-line no-prototype-builtins
  if (dataObject.hasOwnProperty("upArch") && upperType != null) {
    dataObject.upArch = upperType
  }

  // eslint-disable-next-line no-prototype-builtins
  if (dataObject.hasOwnProperty("downArch") && lowerType != null) {
    dataObject.downArch = lowerType
  }
  const newArchTypeData = JSON.stringify(dataObject)
  console.log("ashuaizzz🚀 ~ newArchTypeData:", newArchTypeData)
  resourcesSynchronization.saveJsonDataToZip(
    newArchTypeData,
    "ArchType.json",
    "Setting Data2",
  )
}

export async function saveDoRefinementStage(upperStage: any, lowerState: any) {
  if (!isEnabled) return false

  const parasData = {
    upperTPStep: Number.parseInt(upperStage),
    lowerTPStep: Number.parseInt(lowerState),
  }

  const jsonData = JSON.stringify(parasData, (key, value) => {
    // 如果值是数字，不添加引号
    return typeof value === "number" ? value : value
  })
  console.log("ashuaizzz🚀 ~save refinement stage jsonData:", jsonData)

  const blob = new Blob([jsonData], { type: "text/plain" })
  const file = new File([blob], `case/Setting Data10/refinement/paras.json`)
  const savePath: Record<string, any> = {}
  savePath[`test/case/Setting Data10/refinement/paras.json`] = file

  const saveRes = await resourcesSynchronization.moduleFS(savePath)
  return saveRes
}

export function isInitPreview() {
  return isEnabled
}

export function drawScanMtc(zoomRange: [number, number] = [0.25, 4.0]) {
  if (!isEnabled) return
  console.log("DrawScanMtc :", zoomRange)
  const previewWin = wasmModule.module.getScanPreviewWin()
  if (!previewWin) return
  const mtcNames = [
    "/test/case/Raw/arch_o_u.mtc",
    "/test/case/Raw/arch_o_l.mtc",
  ]
  wasmModule.module.getScanPreviewWin().UpdateArch(mtcNames[0], mtcNames[1])
  wasmModule.module.getScanPreviewWin().SetMinScaleValue(zoomRange[0])
  wasmModule.module.getScanPreviewWin().SetMaxScaleValue(zoomRange[1])
  const mtcFiles = resourcesSynchronization.getMtcsFromURL([
    mtcNames[0],
    mtcNames[1],
  ])
  return mtcFiles
}

export async function drawMtcFromZips(
  zips: Record<string, File>,
  zoomRange: [number, number] = [0.25, 4.0],
  isShowRefinement: boolean,
): Promise<Map<string, File> | undefined> {
  if (!isEnabled) return

  await resourcesSynchronization.writeCaseFilesToVirtualFS(zips)
  console.log("DrawMtcFromZips :", JSON.stringify(zips), zoomRange)
  const previewWin = wasmModule.module.getScanPreviewWin()
  if (!previewWin) {
    console.error("drawMtcFromZips false: getPreviewWin is null", previewWin)
    return
  }

  const mtcNames = [
    isShowRefinement
      ? "/test/case/Setting Data10/refinement/arch_u.mtc"
      : "/test/case/Raw/arch_o_u.mtc",
    isShowRefinement
      ? "/test/case/Setting Data10/refinement/arch_l.mtc"
      : "/test/case/Raw/arch_o_l.mtc",
  ]
  wasmModule.module.getScanPreviewWin().UpdateArch(mtcNames[0], mtcNames[1])

  wasmModule.module.getScanPreviewWin().SetMinScaleValue(zoomRange[0])
  wasmModule.module.getScanPreviewWin().SetMaxScaleValue(zoomRange[1])

  const mtcFiles = resourcesSynchronization.getMtcsFromURL([
    mtcNames[0],
    mtcNames[1],
  ])
  return mtcFiles
}

export async function drawRefinementMtcFromZips(
  zips: Record<string, File>,
  zoomRange: [number, number] = [0.25, 4.0],
) {
  if (!isEnabled) return

  console.log("drawRefinementMtcFromZips :", zips, zoomRange)
  await resourcesSynchronization.writeCaseFilesToVirtualFS(zips)

  const previewWin = wasmModule.module.getScanPreviewWin()
  if (!previewWin) {
    console.error(
      "drawRefinementMtcFromZips false: getPreviewWin is null",
      previewWin,
    )
    return
  }

  const mtcNames = [
    "/test/case/Setting Data10/refinement/arch_u.mtc",
    "/test/case/Setting Data10/refinement/arch_l.mtc",
  ]
  previewWin.UpdateArch(mtcNames[0], mtcNames[1])

  const mtcFiles = resourcesSynchronization.getMtcsFromURL([
    mtcNames[0],
    mtcNames[1],
  ])
  // console.log("mtcFiles ",mtcFiles);

  if (wasmModule.module.getScanPreviewWin) {
    previewWin.SetMinScaleValue(zoomRange[0])
    previewWin.SetMaxScaleValue(zoomRange[1])
  }

  return mtcFiles
}

function getFileExtension(file: File) {
  // 使用split()方法将文件名拆分成名称和后缀
  const parts = file.name.split(".")
  const extension = parts[parts.length - 1].toLowerCase()
  return extension
}

// 让drawSTL可重入
let isInitingPreview = false

/**
 * 初始化 scan / preview
 * @param canvas
 * @param zips
 */
export async function initScanView(
  canvas: HTMLCanvasElement,
  zips: Record<string, File>,
) {
  const timestampStart = Date.now();
  console.log("initScanView start ", timestampStart);
  const windowTitle = document.title
  await wasmModule.initWASM(canvas, async () => {
    resourcesSynchronization.fsUnlink()
    wasmModule.cancelemloop()
    // wasmModule.module.setPreviewColor(0.69, 0.86, 0.945, 0.69, 0.86, 0.945)
    wasmModule.module.setPreviewColor(0.63, 0.64, 0.65, 0.63, 0.64, 0.65)
    await resourcesSynchronization.writeCaseFilesToVirtualFS(zips)
    isEnabled = true
    wasmModule.module._scanPreview(wasmModule.module.allocateUTF8(windowTitle))
    wasmModule.module
      .getScanPreviewWin()
      .UpdateArch("test/pre/arch_u.stl", "test/pre/arch_l.stl")
  })
  console.log("initScanView successful")
  const timestampEnd = Date.now();
  console.log("initScanView end ", timestampEnd);
}

/**
 * 初始化refinement view
 * @param canvas
 * @param zips
 */
export async function initRefinementView(
  canvas: HTMLCanvasElement,
  zips: Record<string, File>,
) {
  const windowTitle = document.title
  await wasmModule.initWASM(canvas, async () => {
    resourcesSynchronization.fsUnlink()
    wasmModule.cancelemloop()
    // wasmModule.module.setPreviewColor(0.69, 0.86, 0.945, 0.69, 0.86, 0.945)
    wasmModule.module.setPreviewColor(0.63, 0.64, 0.65, 0.63, 0.64, 0.65)

    // this will invoke a loop can't do something after invoke this function
    setTimeout(() => {
      wasmModule.module._scanPreview(
        wasmModule.module.allocateUTF8(windowTitle),
      )
    }, 100)
    isEnabled = true
  })

  console.log("initRefinementView zips :", zips)
  await resourcesSynchronization.writeCaseFilesToVirtualFS(zips)
}

/**
 * 在胶水层UI测试初始化refinement view
 * @param canvas
 * @param zips
 */
export async function testInitRefinementView(
  canvas: HTMLCanvasElement,
  zip: File,
) {
  const windowTitle = document.title
  await wasmModule.initWASM(canvas, async () => {
    wasmModule.cancelemloop()
    // wasmModule.module.setPreviewColor(0.69, 0.86, 0.945, 0.69, 0.86, 0.945)
    wasmModule.module.setPreviewColor(0.63, 0.64, 0.65, 0.63, 0.64, 0.65)

    // this will invoke a loop can't do something after invoke this function
    setTimeout(() => {
      wasmModule.module._scanPreview(
        wasmModule.module.allocateUTF8(windowTitle),
      )
    }, 100)
    isEnabled = true
  })

  const resFileList = await resourceManager.parseZipFilesFromAZipPacket(zip)
  const writeFileList = await resourcesSynchronization.makeVirtualDirectory(
    resFileList,
  )
  await resourcesSynchronization.moduleFS(writeFileList)

  isInitingPreview = true
}

/**
 * abandon
 * @param drawSTLOps
 */
export async function drawRefinementSTL(drawSTLOps: IDrawRefinementSTLOps) {
  // if (!wasmModule.module.getScanPreviewWin()) {
  //   return
  // }
  // wasmModule.module
  //   .getScanPreviewWin()
  //   .SetMinScaleValue(drawSTLOps.zoomRange[0])
  // wasmModule.module
  //   .getScanPreviewWin()
  //   .SetMaxScaleValue(drawSTLOps.zoomRange[1])
  // const { upperArch, lowerArch } = drawSTLOps
  // if (upperArch !== currentShowSTLUpper) {
  //   if (upperArch) {
  //     const extension = getFileExtension(upperArch)
  //     if (extension == "stl") {
  //       stlFilePath["test/pre/arch_u.stl"] = upperArch
  //       await resourcesSynchronization.moduleFS(stlFilePath)
  //       wasmModule.module
  //         .getScanPreviewWin()
  //         .UpdateArch("test/pre/arch_u.stl", "")
  //       const isSaveU = wasmModule.module.SaveMtcFileToFolder(
  //         "test/pre/arch_u.stl",
  //         "Setting Data10/refinement/arch_u.mtc",
  //       )
  //       console.log("isSaveLUpperMTC :", isSaveU)
  //     }
  //     currentShowSTLUpper = upperArch
  //   } else if (currentShowSTLUpper) {
  //     wasmModule.module.getScanPreviewWin().RemoveArch(true, false)
  //     currentShowSTLUpper = null
  //     resourcesSynchronization.deleteFile(
  //       "test/case/Setting Data10/refinement/arch_u.mtc",
  //     )
  //   }
  // }
  // if (lowerArch !== currentShowSTLLower) {
  //   if (lowerArch) {
  //     const extension = getFileExtension(lowerArch)
  //     if (extension == "stl") {
  //       stlFilePath["test/pre/arch_l.stl"] = lowerArch
  //       await resourcesSynchronization.moduleFS(stlFilePath)
  //       wasmModule.module
  //         .getScanPreviewWin()
  //         .UpdateArch("", "test/pre/arch_l.stl")
  //       const isSaveL = wasmModule.module.SaveMtcFileToFolder(
  //         "test/pre/arch_l.stl",
  //         "Setting Data10/refinement/arch_l.mtc",
  //       )
  //       console.log("isSaveLlowerMTC :", isSaveL)
  //     }
  //     currentShowSTLLower = lowerArch
  //   } else if (currentShowSTLLower) {
  //     wasmModule.module.getScanPreviewWin().RemoveArch(false, true)
  //     currentShowSTLLower = null
  //     resourcesSynchronization.deleteFile(
  //       "test/case/Setting Data10/refinement/arch_l.mtc",
  //     )
  //   }
  // }
}

export async function fillHole(
  fillholeCallback?: (isSuccess: boolean, isUpperFlag: "up" | "low") => void,
) {
  const result = { upper: null, lower: null }
  // // fill hole
  if (currentShowSTLUpper) {
    let archPath = "/test/case/Raw/arch_o_u.mtc"
    const mtcfile = resourcesSynchronization.getFile(archPath)
    if (!mtcfile) {
      archPath = "test/pre/arch_u.stl"
    }
    const ret = wasmModule.module._autoFillHoles(
      wasmModule.module.allocateUTF8(archPath),
      wasmModule.module.allocateUTF8("/test/case/Setting Data0/arch_u.mtc"),
    )
    // if (!ret) {
    //   if (fillholeCallback) {
    //     fillholeCallback(false, "up");
    //   }

    // }
    result.upper = ret ? true : false
  }

  if (currentShowSTLLower) {
    let archPath = "/test/case/Raw/arch_o_l.mtc"
    const mtcfile = resourcesSynchronization.getFile(archPath)
    if (!mtcfile) {
      archPath = "test/pre/arch_l.stl"
    }
    const ret = wasmModule.module._autoFillHoles(
      wasmModule.module.allocateUTF8(archPath),
      wasmModule.module.allocateUTF8("/test/case/Setting Data0/arch_l.mtc"),
    )
    // if (!ret) {
    //   if (fillholeCallback) {
    //     fillholeCallback(false, "low");
    //   }
    // }
    result.lower = ret ? true : false
  }

  return result
}

export async function drawSTL(drawSTLOps: IDrawSTLOps) {
  if (!isEnabled) {
    if (isInitingPreview) {
      return
    }
    if (!isInitingPreview) {
      isInitingPreview = true
    }

    const windowTitle = document.title
    await wasmModule.initWASM(drawSTLOps.canvas, async () => {
      resourcesSynchronization.fsUnlink()
      // wasmModule.module.setPreviewColor(0.69, 0.86, 0.945, 0.69, 0.86, 0.945)
      wasmModule.module.setPreviewColor(0.63, 0.64, 0.65, 0.63, 0.64, 0.65)

      // this will invoke a loop can't do something after invoke this function
      setTimeout(() => {
        wasmModule.cancelemloop()
        wasmModule.module._scanPreview(
          wasmModule.module.allocateUTF8(windowTitle),
        )
      }, 100)
      isEnabled = true
      isInitingPreview = false
    })
    return
  }

  if (!wasmModule.module.getScanPreviewWin()) {
    return
  }

  if (wasmModule.module.getScanPreviewWin) {
    wasmModule.module
      .getScanPreviewWin()
      .SetMinScaleValue(drawSTLOps.zoomRange[0])
    wasmModule.module
      .getScanPreviewWin()
      .SetMaxScaleValue(drawSTLOps.zoomRange[1])
  }

  const { upperArch, lowerArch, fillholeCallback } = drawSTLOps

  if (upperArch !== currentShowSTLUpper) {
    if (upperArch) {
      const extension = getFileExtension(upperArch)

      if (extension == "stl") {
        stlFilePath["test/pre/arch_u.stl"] = upperArch
        await resourcesSynchronization.moduleFS(stlFilePath)
        // remove filling hole by zasidle @2023/11/7
        // // fill hole
        // const ret = wasmModule.module._autoFillHoles(
        //   wasmModule.module.allocateUTF8("test/pre/arch_u.stl"),
        //   wasmModule.module.allocateUTF8("test/pre/fillready_arch_u.stl")
        // );
        // if (!ret) {
        //   if (fillholeCallback) {
        //     fillholeCallback(false, "up");
        //   }
        // }
        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("test/pre/arch_u.stl", "")
      } else if (extension == "mtc") {
        stlFilePath["test/case/Raw/arch_o_u.mtc"] = upperArch
        await resourcesSynchronization.moduleFS(stlFilePath)
        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("test/case/Raw/arch_o_u.mtc", "")
      }
      currentShowSTLUpper = upperArch
    } else if (currentShowSTLUpper) {
      wasmModule.module.getScanPreviewWin().RemoveArch(true, false)
      currentShowSTLUpper = null
      // const deleteMtcUpper = wasmModule.module.DeleteMtcFiles(0);
      // console.log("deleteMtcUpper :", deleteMtcUpper);
      resourcesSynchronization.deleteFile("test/case/Raw/arch_o_u.mtc")
    }
  }

  if (lowerArch !== currentShowSTLLower) {
    if (lowerArch) {
      const extension = getFileExtension(lowerArch)
      if (extension == "stl") {
        stlFilePath["test/pre/arch_l.stl"] = lowerArch
        await resourcesSynchronization.moduleFS(stlFilePath)

        // remove filling hole by zasidle @2023/11/7
        // const ret = wasmModule.module._autoFillHoles(
        //   wasmModule.module.allocateUTF8("test/pre/arch_l.stl"),
        //   wasmModule.module.allocateUTF8("test/pre/fillready_arch_l.stl")
        // );
        // if (!ret) {
        //   if (fillholeCallback) {
        //     fillholeCallback(false, "low");
        //   }
        // }

        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("", "test/pre/arch_l.stl")
      } else if (extension == "mtc") {
        stlFilePath["test/case/Raw/arch_o_l.mtc"] = lowerArch
        await resourcesSynchronization.moduleFS(stlFilePath)
        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("", "test/case/Raw/arch_o_l.mtc")
      }

      currentShowSTLLower = lowerArch
    } else if (currentShowSTLLower) {
      wasmModule.module.getScanPreviewWin().RemoveArch(false, true)
      currentShowSTLLower = null
      resourcesSynchronization.deleteFile("test/case/Raw/arch_o_l.mtc")

      // const isDeleteMtcLower = wasmModule.module.DeleteMtcFiles(1);
      // console.log("isDeleteMtcLower :", isDeleteMtcLower);
    }
  }

  if (!upperArch && !lowerArch) return

  let upArchType = 0,
    downArchType = 0
  const originArchTypeData = resourcesSynchronization.getFileDirectly(
    "ArchType.json",
    "Setting Data2",
    "utf8",
  )
  // console.log("🚀 ~ drawSTL ~ originArchTypeData:", originArchTypeData)

  if (originArchTypeData) {
    const dataObject = JSON.parse(originArchTypeData)
    // eslint-disable-next-line no-prototype-builtins
    if (dataObject.hasOwnProperty("upArch")) {
      // console.log("🚀 ~ drawSTL ~ hasOwnProperty: upArch")
      upArchType = dataObject.upArch
    }

    if (dataObject.hasOwnProperty("downArch")) {
      // console.log("🚀 ~ drawSTL ~ hasOwnProperty: downArch")

      downArchType = dataObject.downArch
    }
  }

  // console.log("🚀 ~ drawSTL ~ lowerArch:", lowerArch)
  // console.log("🚀 ~ drawSTL ~ upperArch:", upperArch)
  let archType
  if (upperArch && lowerArch) {
    archType = {
      KeepPalate: false,
      downArch: downArchType,
      upArch: upArchType,
    }
  } else if (upperArch == null) {
    archType = {
      downArch: downArchType,
    }
  } else if (lowerArch == null) {
    archType = {
      KeepPalate: false,
      upArch: upArchType,
    }
  }
  const archTypeJson = JSON.stringify(archType)
  console.log("drawstl cteat archTypeJson:", archTypeJson)

  const isSaveData = await caseManagement.saveJsonDataToZip(
    archTypeJson,
    "ArchType.json",
    "Setting Data2",
  )
}

export function saveStlToMtc(
  upperStl: File | null,
  lowerStl: File | null,
  isShowRefinement = false,
) {
  const upPath = isShowRefinement
    ? "test/case/Setting Data10/refinement/arch_u.mtc"
    : "test/case/Raw/arch_o_u.mtc"

  const lowerPath = isShowRefinement
    ? "test/case/Setting Data10/refinement/arch_l.mtc"
    : "test/case/Raw/arch_o_l.mtc"

  if (!upperStl && !lowerStl) {
    console.log("At least one jaw needs to be preserved ")
    resourcesSynchronization.deleteFile(upPath)
    resourcesSynchronization.deleteFile(lowerPath)

    return 0
  }
  if (upperStl) {
    const ext = getFileExtension(upperStl)
    if (ext == "stl") {
      const isSaveStl = wasmModule.module.SaveMtcFileToFolder(
        "test/pre/arch_u.stl",
        isShowRefinement
          ? "Setting Data10/refinement/arch_u.mtc"
          : "Raw/arch_o_u.mtc",
      )
      console.log("ashuaizzz🚀 ~ isSaveUpperStl:", isSaveStl)
    }
  } else {
    resourcesSynchronization.deleteFile(upPath)
  }

  if (lowerStl) {
    const ext = getFileExtension(lowerStl)
    if (ext == "stl") {
      const isSaveStl = wasmModule.module.SaveMtcFileToFolder(
        "test/pre/arch_l.stl",
        isShowRefinement
          ? "Setting Data10/refinement/arch_l.mtc"
          : "Raw/arch_o_l.mtc",
      )
      console.log("ashuaizzz🚀 ~ isSaveLowerStl:", isSaveStl)
    }
  } else {
    resourcesSynchronization.deleteFile(lowerPath)
  }

  // const rawList = resourcesSynchronization.getDirInVituralFS("Raw")

  return 1
}

/**
 *changeArchMode
 *param name  (1、up.2、low.3、both)
 * */
export function changeArchMode(viewState: "up" | "low" | "both") {
  if (!wasmModule.module.getScanPreviewWin()) {
    return
  }
  const type =
    viewState === "up"
      ? EShowArchType.UpArch
      : viewState === "low"
      ? EShowArchType.LowArch
      : EShowArchType.BothArch
  wasmModule.module.getScanPreviewWin().OnArchChanged(type)
}

/**
 * setup zoom callback
 * @param zoomRange  the [min,max] tuple value of the scale
 * @param callback  (val: number) => void)
 */
export async function setZoomCallback(callback: (val: number) => void) {
  if (!isEnabled) {
    return
  }
  if (wasmModule.module.getScanPreviewWin && callback) {
    ;(window as any).preview = {}
    ;(window as any).preview.preViewCallback = callback
    if (!wasmModule.module.getScanPreviewWin()) {
      console.warn(
        "module getScanPreviewWin false,cant set update-slider callback.",
      )
      return
    }
    wasmModule.module
      .getScanPreviewWin()
      .SetUpdateSliderCB("preview.preViewCallback")
  }
}

/**
 * zoom with scale
 * @param scale
 */
export function zoomWithValue(scale: number) {
  if (!wasmModule.module.getScanPreviewWin()) {
    return
  }
  wasmModule.module.getScanPreviewWin().ZoomBySlider(scale)
  // console.log("zoomWithValue:", scale);
}

/**
 * clear preview state after out of preview
 */
export function clearPreview() {
  isEnabled = false
  currentShowSTLUpper = null
  currentShowSTLLower = null
  stlFilePath = {}
}

export async function drawSTLWithoutCanvas(drawSTLOps: IDrawSTLOps) {
  console.log("drawSTLWithoutCanvas", drawSTLOps)
  if (!isEnabled) {
    if (isInitingPreview) {
      return
    }
    if (!isInitingPreview) {
      isInitingPreview = true
    }
    // console.log("init preview start:",drawSTLOps)
    const windowTitle = document.title

    await resourcesSynchronization.fsUnlink()
    // wasmModule.module.setPreviewColor(0.69, 0.86, 0.945, 0.69, 0.86, 0.945)
    wasmModule.module.setPreviewColor(0.63, 0.64, 0.65, 0.63, 0.64, 0.65)

    // this will invoke a loop can't do something after invoke this function
    setTimeout(() => {
      wasmModule.cancelemloop()
      wasmModule.module._scanPreview(
        wasmModule.module.allocateUTF8(windowTitle),
      )
    }, 100)
    console.log("init preview OK!")
    isEnabled = true
    isInitingPreview = false

    return
  }

  if (!wasmModule.module.getScanPreviewWin()) {
    return
  }

  if (wasmModule.module.getScanPreviewWin) {
    wasmModule.module
      .getScanPreviewWin()
      .SetMinScaleValue(drawSTLOps.zoomRange[0])
    wasmModule.module
      .getScanPreviewWin()
      .SetMaxScaleValue(drawSTLOps.zoomRange[1])
  }

  const { upperArch, lowerArch, fillholeCallback } = drawSTLOps

  if (upperArch !== currentShowSTLUpper) {
    const upPath = drawSTLOps.isShowRefinement
      ? "test/case/Setting Data10/refinement/arch_u.mtc"
      : "test/case/Raw/arch_o_u.mtc"
    if (upperArch) {
      const extension = getFileExtension(upperArch)

      if (extension == "stl") {
        stlFilePath["test/pre/arch_u.stl"] = upperArch
        await resourcesSynchronization.moduleFS(stlFilePath)
        // remove filling hole by zasidle @2023/11/7
        // // fill hole
        // const ret = wasmModule.module._autoFillHoles(
        //   wasmModule.module.allocateUTF8("test/pre/arch_u.stl"),
        //   wasmModule.module.allocateUTF8("test/pre/fillready_arch_u.stl")
        // );
        // if (!ret) {
        //   if (fillholeCallback) {
        //     fillholeCallback(false, "up");
        //   }
        // }
        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("test/pre/arch_u.stl", "")
      } else if (extension == "mtc") {
        stlFilePath[upPath] = upperArch
        await resourcesSynchronization.moduleFS(stlFilePath)
        wasmModule.module.getScanPreviewWin().UpdateArch(upPath, "")
      }
      currentShowSTLUpper = upperArch
    } else if (currentShowSTLUpper) {
      wasmModule.module.getScanPreviewWin().RemoveArch(true, false)
      currentShowSTLUpper = null
      // const deleteMtcUpper = wasmModule.module.DeleteMtcFiles(0);
      // console.log("deleteMtcUpper :", deleteMtcUpper);
      resourcesSynchronization.deleteFile(upPath)
    }
  }

  if (lowerArch !== currentShowSTLLower) {
    const lowerPath = drawSTLOps.isShowRefinement
      ? "test/case/Setting Data10/refinement/arch_l.mtc"
      : "test/case/Raw/arch_o_l.mtc"
    if (lowerArch) {
      const extension = getFileExtension(lowerArch)
      if (extension == "stl") {
        stlFilePath["test/pre/arch_l.stl"] = lowerArch
        await resourcesSynchronization.moduleFS(stlFilePath)

        // remove filling hole by zasidle @2023/11/7
        // const ret = wasmModule.module._autoFillHoles(
        //   wasmModule.module.allocateUTF8("test/pre/arch_l.stl"),
        //   wasmModule.module.allocateUTF8("test/pre/fillready_arch_l.stl")
        // );
        // if (!ret) {
        //   if (fillholeCallback) {
        //     fillholeCallback(false, "low");
        //   }
        // }

        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("", "test/pre/arch_l.stl")
      } else if (extension == "mtc") {
        stlFilePath[lowerPath] = lowerArch
        await resourcesSynchronization.moduleFS(stlFilePath)
        wasmModule.module.getScanPreviewWin().UpdateArch("", lowerPath)
      }

      currentShowSTLLower = lowerArch
    } else if (currentShowSTLLower) {
      wasmModule.module.getScanPreviewWin().RemoveArch(false, true)
      currentShowSTLLower = null
      resourcesSynchronization.deleteFile(lowerPath)

      // const isDeleteMtcLower = wasmModule.module.DeleteMtcFiles(1);
      // console.log("isDeleteMtcLower :", isDeleteMtcLower);
    }
  }

  if ((!upperArch && !lowerArch) || drawSTLOps.isShowRefinement) return

  let upArchType = 0,
    downArchType = 0
  const originArchTypeData = resourcesSynchronization.getFileDirectly(
    "ArchType.json",
    "Setting Data2",
    "utf8",
  )
  // console.log("🚀 ~ drawSTLWithoutCanvas ~ originArchTypeData:", originArchTypeData)

  if (originArchTypeData) {
    const dataObject = JSON.parse(originArchTypeData)
    // eslint-disable-next-line no-prototype-builtins
    if (dataObject.hasOwnProperty("upArch")) {
      // console.log("🚀 ~ drawSTLWithoutCanvas ~ hasOwnProperty: upArch")
      upArchType = dataObject.upArch
    }

    if (dataObject.hasOwnProperty("downArch")) {
      // console.log("🚀 ~ drawSTLWithoutCanvas ~ hasOwnProperty: downArch")

      downArchType = dataObject.downArch
    }
  }

  // console.log("🚀 ~ drawSTLWithoutCanvas ~ lowerArch:", lowerArch)
  // console.log("🚀 ~ drawSTLWithoutCanvas ~ upperArch:", upperArch)
  let archType
  if (upperArch && lowerArch) {
    archType = {
      KeepPalate: false,
      downArch: downArchType,
      upArch: upArchType,
    }
  } else if (upperArch == null) {
    archType = {
      downArch: downArchType,
    }
  } else if (lowerArch == null) {
    archType = {
      KeepPalate: false,
      upArch: upArchType,
    }
  }
  const archTypeJson = JSON.stringify(archType)
  console.log("drawSTLWithoutCanvas creat archTypeJson:", archTypeJson)

  const isSaveData = await caseManagement.saveJsonDataToZip(
    archTypeJson,
    "ArchType.json",
    "Setting Data2",
  )
  console.log("🚀 ~ drawSTLWithoutCanvas ~ isSaveData:", isSaveData)
}

const material = new Mi.PhoneMaterial()
material.setAttribute({
  diffuseColor: new Mi.Vector3(0.9, 0.9, 0.9),
  ambientColor: new Mi.Vector3(0.5, 0.5, 0.5),
  isRenderBothSide: true,
})

material.renderState.disable(Mi.EEnabledCapability.CULL_FACE);

const stlLoader = new Mi.STLLoader()
// const light = new Mi.DirectionLight()
// light.position = new Mi.Vector3(100.0, 200.0, 300.0)
// light.direction = Mi.Vector3.normalize(
//   Mi.Vector3.sub(zeroVector,light.position),
// )
const currentSTLData = { upper: null, lower: null }
let oldContainer: HTMLElement = null
let view = null
let upperEntity
let lowerEntity
let camController: Mi.OrbitCameraController

export function changeArchModeWithMirror(viewState: "up" | "low" | "both") {
  const scene = view.getScene()
  const cam = view.getCamera()
  scene.removeAll()
  scene.addNode(cam)

  if (viewState === "up" || viewState === "both") {
    const scene = view.getScene()
    if (upperEntity) {
      scene.addNode(upperEntity)
    }
  }
  if (viewState === "low" || viewState === "both") {
    const scene = view.getScene()
    if (lowerEntity) {
      scene.addNode(lowerEntity)
    }
  }
  view.render()
}

/**
 * size range is betweeen 0.1 and 2
 * @param size
 */
export const setSTLZoomSize = (size: number) => {
  if (size < 0.1 || size > 2) {
    return
  }
  if (view && camController) {
    camController.currentZoomScale = size
    // const cam = view.getCamera()
    // cam.orthoHalfHeight = 40 / size
    view.render()
  }
}

/**
 * Draw stl in a domelement with Mirror
 * and save the stl into refinement dir
 * @param drawSTLOps
 */
export const drawSTLWithMirror = lodash.debounce(
  async (drawSTLOps: IDrawSTLOps) => {
    // remove old canvas and init scene
    if (oldContainer !== drawSTLOps.canvas) {
      console.log("draw new scene....", drawSTLOps)
      oldContainer = drawSTLOps.canvas
      // init viwer
      const engine = Mi.Engine.singleton
      engine.clearAllViews()
      const oldCanvas = drawSTLOps.canvas.getElementsByTagName("canvas")
      if (oldCanvas && oldCanvas.length > 0) {
        for (let cav of oldCanvas) {
          drawSTLOps.canvas.removeChild(cav)
        }
      }
      view = engine.createDefaultView(drawSTLOps.canvas)
      const scene = view.getScene()
      // scene.addNode(light)
      const cam = view.getCamera()
      camController = new Mi.OrbitCameraController()
      camController.setup(view, cam, {
        oribitActionButtonType: "right",
        onZoomChange: (val) => {
          if (drawSTLOps.onZoomChange) {
            drawSTLOps.onZoomChange(val)
          }
        },
        minZoomScale: drawSTLOps.zoomRange[0] ?? 0.5,
        maxZoomScale: drawSTLOps.zoomRange[1] ?? 2,
        defaultScale: 2,
      })
      const headLight = new Mi.PointLight()
      cam.addNode(headLight)
      upperEntity = null
      lowerEntity = null

      // set camera
      //const cam = view.getCamera()
      cam.type = Mi.ECameraType.Orthographic
      const canvas = view.getCanvas()
      cam.aspect = canvas.width / canvas.height

      cam.orthoHalfHeight = 40
      cam.position = new Mi.Vector3(0.0, 0.0, 50.0)
      cam.upVector = new Mi.Vector3(0.0, 1.0, 0.0)
      cam.lookAt = new Mi.Vector3(0, 0, 0)
    }

    const scene = view.getScene()

    if (upperEntity) {
      scene.removeNode(upperEntity)
      upperEntity = null
    }
    if (drawSTLOps.upperArch) {
      const upperArchFile = await drawSTLOps.upperArch.arrayBuffer()
      const geometry = stlLoader.parseBinary(upperArchFile)
      upperEntity = new Mi.Entity()
      const meshRendererComponent = upperEntity.addComponent(Mi.ComMeshRenderer)
      meshRendererComponent.mesh = Mi.Mesh.createMeshWithGeometry(
        geometry,
        material,
      )
      // entity.addGeometry(geometry,material);
      scene.addNode(upperEntity)
    }
    if (lowerEntity) {
      scene.removeNode(lowerEntity)
      lowerEntity = null
    }
    if (drawSTLOps.lowerArch) {
      const lowerArchFile = await drawSTLOps.lowerArch.arrayBuffer()
      const geometry = stlLoader.parseBinary(lowerArchFile)
      lowerEntity = new Mi.Entity()
      // entity.addGeometry(geometry,material);
      const meshRendererComponent = lowerEntity.addComponent(Mi.ComMeshRenderer)
      meshRendererComponent.mesh = Mi.Mesh.createMeshWithGeometry(
        geometry,
        material,
      )
      scene.addNode(lowerEntity)
    }

    view.render()

    // check if need to save
    setTimeout(async () => {
      if (currentSTLData.upper !== drawSTLOps.upperArch) {
        currentSTLData.upper = drawSTLOps.upperArch
          ? drawSTLOps.upperArch
          : null
        resourcesSynchronization.deleteFile(
          "test/case/Setting Data10/refinement/arch_u.mtc",
        )

        // save to virtual memory
        const stlFile = currentSTLData.upper

        stlFilePath["test/pre/arch_u.stl"] = stlFile
        await resourcesSynchronization.moduleFS(stlFilePath)

        const isSaveU = wasmModule.module.SaveMtcFileToFolder(
          "test/pre/arch_u.stl",
          "Setting Data10/refinement/arch_u.mtc",
        )
        console.log("isSaveLUpperMTC :", isSaveU)
      }

      if (currentSTLData.lower !== drawSTLOps.lowerArch) {
        currentSTLData.lower = drawSTLOps.lowerArch
          ? drawSTLOps.lowerArch
          : null
        resourcesSynchronization.deleteFile(
          "test/case/Setting Data10/refinement/arch_l.mtc",
        )

        // save to virtual memory
        const stlFile = currentSTLData.lower

        stlFilePath["test/pre/arch_l.stl"] = stlFile
        await resourcesSynchronization.moduleFS(stlFilePath)

        const isSaveU = wasmModule.module.SaveMtcFileToFolder(
          "test/pre/arch_l.stl",
          "Setting Data10/refinement/arch_l.mtc",
        )
        console.log("isSaveLowerMTC :", isSaveU)
      }
    }, 100)
  },
  500,
)
