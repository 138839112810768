import { FC, useEffect, useMemo, useState } from "react"
import { Actions, ControlProps, JsonSchema } from "@jsonforms/core"
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react"
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material"
import { debounce } from "lodash";

export const JInput: FC<{
  path: string
  errors: string
  label: string
  schema: JsonSchema
  data: string
  handleChange: (path: string, value: any) => void
}> = ({ path, label, schema, data, handleChange }) => {
  const [value, setValue] = useState<string>(data || "")
  const { core, dispatch } = useJsonForms()
  const [errors, setErrors] = useState("")
  
  const isPlaceholder = schema && schema.options && schema.options.placeholder

  const debouncedHandleChange = useMemo(
    () =>
      debounce((path, inputValue) => {
        handleChange(path, inputValue); // Sends only the last value
      }, 300, { leading: false, trailing: true }),
    [handleChange]
  );

  const updateErrors = (message: string) => {
    setErrors(message)
    dispatch &&
      dispatch(
        Actions.updateErrors([
          {
            instancePath: "/" + path,
            message,
            schemaPath: "",
            keyword: "",
            params: {},
          },
        ]),
      )
  }

  useEffect(()=>{
    if(data!=value){
      setValue(data) // Value should not be in deps
    }
  },[data])

  useEffect(() => {
    if (core?.schema.required?.indexOf(path) === -1) return
    updateErrors(!value && !data ? "This is a required field" : "")
  }, [data, value, core?.validationMode])

  return (
    <>
      <Box sx={{ my: 1 }}>
        <InputLabel
          error={!!(errors && core?.validationMode === "ValidateAndShow")}
          sx={{
            mb: 1,
            color: "rgba(0, 0, 0, 0.60)",
            "&.Mui-focused": {
              color: "rgba(0, 0, 0, 0.60)",
            },
          }}
        >
          {schema?.label ? schema.label : label}
        </InputLabel>
        <FormControl
          error={!!(errors && core?.validationMode === "ValidateAndShow")}
          variant={"outlined"}
          fullWidth
        >
          <OutlinedInput
            id={label}
            value={value}
            placeholder={isPlaceholder ? schema.options.placeholder : ""}
            onChange={(event: any) => {
              if( event.target.value === value) return
              setValue(event.target.value)
              debouncedHandleChange(path, event.target.value)
            }}
          />
          {errors && core?.validationMode === "ValidateAndShow" && (
            <FormHelperText sx={{ marginLeft: 0 }}>{errors}</FormHelperText>
          )}
        </FormControl>
      </Box>
    </>
  )
}

const UInput: FC<ControlProps> = ({
  handleChange,
  path,
  data,
  label,
  errors,
  schema,
}) => {
  const obj = {
    path: path,
    errors: errors,
    label: label,
    schema: schema,
    data: data,
    handleChange: handleChange,
  }

  return <JInput {...obj} />
}

export default withJsonFormsControlProps(UInput)
