import * as React from "react"
import { FC, useEffect } from "react"
import { Outlet } from "react-router-dom"

import { Grid } from "../components/mui.components"
import { UsmileDarkThemeProvider } from "../ui-component"
import { RootState } from "@/core/app/store"
import { useAppSelector } from "@/core/app/hooks"

const Clinical: FC = () => {
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  useEffect(() => {  
    if (patientData && patientData.firstName && patientData.lastName) {
      document.title = `${patientData.firstName[0]}. ${patientData.lastName}`
    } else {
      document.title = "uDesign Cloud"
    }
  
    return () => {
      document.title = "uDesign Cloud"
    }
  }, [patientData])
  
  return (
    <UsmileDarkThemeProvider>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        sx={{ minHeight: "85vh" }}
        minWidth={"lg"}
        id={"123"}
      >
        <Grid container item minWidth={"lg"} sx={{ m: 0 }}>
          <Outlet />
        </Grid>
      </Grid>
    </UsmileDarkThemeProvider>
  )
}

export default Clinical
