import { KeyboardEvent, useEffect, useState } from "react"
// import Drag from "@/components/Drag/Drag"
import { Rnd } from "react-rnd"
import SuperImposeIcon from "@mui/icons-material/JoinLeftOutlined"
// import LockIcon from "@mui/icons-material/Lock"
import WarnIcon from "@mui/icons-material/PriorityHigh"
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
import { ReactSVG } from "react-svg"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { getSettingLabel } from "@/core/utils/commonMethod"
import {
  EToothDirection,
  gdsPlusSetup,
  toothMovement,
  wasmModule,
} from "@/gluelayer"
import { UDTabs } from "@/UDTreat/custom/UDTabs"

import { updateSelTooth } from "../udSetupSlice"
import { setSelTooth } from "../udTreatSlice"

import { LeftArrow, RightArrow } from "./InfoIcons"
import { panelDataProps } from "./toothinfoprops"
import { UDToothAutoSetup } from "./UDToothAutoSetup"
import {
  LockMoveIcon,
  UnlockIcon,
  SingleToothSuperimposeIcon,
  SingleToothSuperimposeOpenIcon,
  ExtractionIcon,
  ToothResetIcon,
} from "./UDToothInfoIcon"
const panelButtons = [
  {
    index: 0,
    leftLabel: "Distal",
    rightLabel: "Mesial",
    leftDir: EToothDirection.Distal,
    rightDir: EToothDirection.Mesial,
    unit: "mm",
    key: "",
  },
  {
    index: 1,
    leftLabel: "Buccal",
    rightLabel: "Lingual",
    leftDir: EToothDirection.Buccal,
    rightDir: EToothDirection.Lingual,
    unit: "mm",
    key: "",
  },
  {
    index: 2,
    leftLabel: "Extrusion",
    rightLabel: "Intrusion",
    leftDir: EToothDirection.Extrusion,
    rightDir: EToothDirection.Intrusion,
    unit: "mm",
    key: "",
  },

  {
    index: 3,
    leftLabel: "Torque(B)",
    rightLabel: "Torque(L)",
    leftDir: EToothDirection.TorqueAdd,
    rightDir: EToothDirection.TorqueSub,
    unit: "°",
    key: "",
  },
  {
    index: 4,
    leftLabel: "Tipping(M)",
    rightLabel: "Tipping(D)",
    leftDir: EToothDirection.TippingAdd,
    rightDir: EToothDirection.TippingSub,
    unit: "°",
    key: "",
  },
  {
    index: 5,
    leftLabel: "Rotation(M)",
    rightLabel: "Rotation(D)",
    leftDir: EToothDirection.RotationAdd,
    rightDir: EToothDirection.RotationSub,
    unit: "°",
    key: "",
  },
]

const UDToothInfo = ({ isOpenCase, isEdit }) => {
  const theme = useTheme()
  const [isShow, setIsShow] = useState(false)
  const { disabled } = useAppSelector(
    (state: RootState) => state.udSetupService,
  )
  const { switchViewEdit } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const dispatch = useAppDispatch()
  const [dragxy, setDragxy] = useState({ x: 100, y: 200 })
  const [islock, setislock] = useState(false)
  const [isSuperimpose, setisSuperimpose] = useState(false)
  const [dataSource, setDataSource] = useState<panelDataProps>({
    data: [0, 0, 0, 0, 0, 0],
    flag: false,
    isBridge: false,
    isCrown: false,
    isImplant: false,
    isNoMovement: false,
    isRoot: false,
    isShow: false,
    isErupting: false,
    isPontic: false,
    isPrimary: false,
    toothId: 0,
    toothWidth: 0,
    isCanEdit: false,
    isToothCanMove: false,
  })
  const [textFieldValus, setTextFieldValues] = useState([
    ["", ""],
    ["", ""],
    ["", ""],
    ["", ""],
    ["", ""],
    ["", ""],
  ])
  const [movetoothPoPUP, setmovetoothPoPUP] = useState(false)
  const [resetConfirmOpen, setResetConfirmOpen] = useState(false)
  const [pulloutConfirmOpen, setPulloutConfirmOpen] = useState(false)

  const [disableStatus, setDisableStatus] = useState(false)
  const {
    clinicalSettings: { NumberSystem },
  } = useAppSelector((state: RootState) => state.userService)

  useEffect(() => {
    toothMovement.setCallbackShowToothInfo((data) => {
      const copyData = JSON.parse(JSON.stringify(data))
      //toothWidth
      let valWidth = (Math.round(copyData.toothWidth * 10) / 10).toString()
      if (!valWidth.includes(".")) {
        valWidth += ".0"
      }
      copyData.toothWidth = valWidth
      // handle data to fixed 2
      for (let i = 0; i < copyData.data.length; i++) {
        const val = Math.round(copyData.data[i] * 10) / 10
        copyData.data[i] = i === 1 || i === 2 ? -val : val
      }
      setDataSource(copyData)
      let selT = 0
      if (copyData.toothId > 0 && copyData.isShow) selT = copyData.toothId
      dispatch(setSelTooth(selT))
      dispatch(updateSelTooth(selT))
      toothMovement.getIsAllowMoveTooth(() => {
        setmovetoothPoPUP(true)
      })
    })
  }, [])

  useEffect(() => {
    setislock(toothMovement.getToothLockState())
    setIsShow(dataSource.isShow)
    const newTextFieldValus = [...textFieldValus]
    for (let i = 0; i < dataSource.data.length; i++) {
      const data = dataSource.data[i]
      const unit = panelButtons[i].unit
      const absData = Math.abs(data)

      if (data === 0) {
        newTextFieldValus[i] = ["", ""]
        continue
      }

      const positiveValue = `${absData}${unit}`
      const negativeValue = ""

      newTextFieldValus[i] =
        i >= 3
          ? data < 0
            ? [negativeValue, positiveValue]
            : [positiveValue, negativeValue]
          : data < 0
          ? [positiveValue, negativeValue]
          : [negativeValue, positiveValue]
    }

    setTextFieldValues(newTextFieldValus)

    setDisableStatus(!switchViewEdit || !dataSource.isToothCanMove)
  }, [dataSource])

  useEffect(() => {
    console.log("🚀 ~ UDToothInfo ~ islock:", islock)
    setDisableStatus(!switchViewEdit || islock)
  }, [islock])

  useEffect(() => {
    console.log("🚀 ~ UDToothInfo ~ dataSource.toothId:", dataSource.toothId)
    if (dataSource.isShow) {
      setisSuperimpose(false)
    }
  }, [dataSource.toothId, dataSource.isShow])

  const handleArrowClick = (dir: number, quick: boolean) => {
    toothMovement.disPatchButton(dir, quick)
  }

  //focus input
  const focusInput = (
    value: string,
    textFieldIndex: 0 | 1,
    index: number,
    unit: string,
  ) => {
    const newValue = Number.parseFloat(value.replace(unit, ""))
    if (!newValue && newValue !== 0) return

    const newTextFieldValus = [...textFieldValus]
    newTextFieldValus[index][textFieldIndex] = newValue.toString()
    setTextFieldValues(newTextFieldValus)
  }
  // blur input
  const blurInput = (
    value: string,
    dir: -1 | 1,
    index: number,
    unit: string,
  ) => {
    value = value.replace(unit, "")
    let numberValue = Number.parseFloat(value)
    if (!numberValue && numberValue !== 0) return
    numberValue *= dir
    numberValue *= -1
    toothMovement.moveWithInputValue(numberValue, index)
  }
  // enter input
  const enterInput = (
    event: KeyboardEvent<HTMLInputElement>,
    value: string,
    dir: -1 | 1,
    index: number,
    unit: string,
  ) => {
    if (event.code === "Enter") {
      // value = value.replace(unit, "")
      // let numberValue = Number.parseFloat(value)
      // if (!numberValue && numberValue !== 0) return
      // numberValue *= dir
      // if (index < 3) numberValue *= -1
      // toothMovement.moveWithInputValue(numberValue, index)
      event.target.blur() //The blurInput method will be triggered
    }
  }
  // change value
  const changeInput = (value: string, dir: -1 | 1, index: number) => {
    const regex = /^[0-9]+(\.[0-9]{0,1})?$/
    if (regex.test(value) || value === "") {
      // console.log("ashuaizzz🚀 ~regex value:", value)
      const newTextFieldValus = [...textFieldValus]
      newTextFieldValus[index][dir] = value
      setTextFieldValues(newTextFieldValus)
    }
  }
  const RemoveOccContact = () => {
    return (
      <Button
        onClick={() => {
          if (!wasmModule.isInit) return
          gdsPlusSetup.ResolveOccColl(true, true, () => ({}))
        }}
      >
        Remove Occ Contact
      </Button>
    )
  }
  const ToothMoveInfo = () => {
    return (
      <Stack
        paddingTop={1.5}
        marginLeft={2}
        marginRight={2}
        // marginTop={1}
        sx={{
          height: "90%",
        }}
        direction="column"
        spacing={1}
      >
        {panelButtons.map((arrowProps) => {
          return (
            <Stack key={arrowProps.index} spacing={-0.5}>
              <Stack direction="row">
                <IconButton
                  disabled={disableStatus}
                  onClick={() => handleArrowClick(arrowProps.leftDir, true)}
                >
                  <LeftArrow sx={{ width: 24, height: 24 }} />
                </IconButton>
                <IconButton
                  disabled={disableStatus}
                  onClick={() => handleArrowClick(arrowProps.leftDir, false)}
                >
                  <LeftArrow sx={{ width: 12, height: 12 }} />
                </IconButton>
                <TextField
                  variant="standard"
                  inputProps={{
                    style: {
                      textAlign: "center",
                    },
                  }}
                  disabled={disableStatus}
                  value={textFieldValus[arrowProps.index][0]}
                  onFocus={(e) => {
                    focusInput(
                      e.target.value,
                      0,
                      arrowProps.index,
                      arrowProps.unit,
                    )
                  }}
                  onBlur={(e) => {
                    blurInput(
                      e.target.value,
                      -1,
                      arrowProps.index,
                      arrowProps.unit,
                    )
                  }}
                  onKeyUp={(e) => {
                    if (e.code === "Enter") {
                      e.target.blur() //The blurInput method will be triggered
                    }
                  }}
                  onChange={(e) => {
                    changeInput(e.target.value, 0, arrowProps.index)
                  }}
                />
                <TextField
                  variant="standard"
                  inputProps={{
                    style: {
                      textAlign: "center",
                    },
                  }}
                  disabled={disableStatus}
                  value={textFieldValus[arrowProps.index][1]}
                  onFocus={(e) => {
                    focusInput(
                      e.target.value,
                      1,
                      arrowProps.index,
                      arrowProps.unit,
                    )
                  }}
                  onBlur={(e) => {
                    blurInput(
                      e.target.value,
                      1,
                      arrowProps.index,
                      arrowProps.unit,
                    )
                  }}
                  onKeyUp={(e) => {
                    if (e.code === "Enter") {
                      e.target.blur() //The blurInput method will be triggered
                    }
                  }}
                  onChange={(e) => {
                    changeInput(e.target.value, 1, arrowProps.index)
                  }}
                />
                <IconButton
                  disabled={disableStatus}
                  onClick={() => handleArrowClick(arrowProps.rightDir, false)}
                >
                  <RightArrow sx={{ width: 12, height: 12 }} />
                </IconButton>
                <IconButton
                  disabled={disableStatus}
                  onClick={() => handleArrowClick(arrowProps.rightDir, true)}
                >
                  <RightArrow sx={{ width: 24, height: 24 }} />
                </IconButton>
              </Stack>
              <Stack
                direction="row"
                divider={
                  <Divider variant="middle" orientation="vertical" flexItem />
                }
                alignItems="center"
                justifyContent={"center"}
                spacing={1}
              >
                <Typography variant="caption">
                  {arrowProps.leftLabel}
                </Typography>
                <Typography variant="caption">
                  {arrowProps.rightLabel}
                </Typography>
              </Stack>
            </Stack>
          )
        })}
        {!disabled ? <RemoveOccContact /> : <></>}
      </Stack>
    )
  }
  const buttonList = [
    {
      clickFun: () => {
        // console.log("lock tooth", islock)
        setislock(!islock)
        toothMovement.lockMoveTooth(!islock)
      },
      component: islock ? <UnlockIcon /> : <LockMoveIcon />,
      isActive: islock,
      isDisabled: !isEdit,
    },
    {
      clickFun: () => {
        setisSuperimpose(!isSuperimpose)
        toothMovement.showSingleSuperImpose(!isSuperimpose)
      },
      component: isSuperimpose ? (
        <SingleToothSuperimposeOpenIcon />
      ) : (
        <SingleToothSuperimposeIcon />
      ),
      isActive: isSuperimpose,
      isDisabled: false,
    },
    {
      clickFun: () => {
        setResetConfirmOpen(true)
      },
      component: <ToothResetIcon />,
      isDisabled: !isEdit,
    },
    {
      clickFun: () => {
        setPulloutConfirmOpen(true)
      },
      component: <ExtractionIcon />,
      isDisabled: !isEdit,
    },
  ]

  const handleResetConfirm = () => {
    wasmModule.wrapInstance.GetMoveToothModule().ResetToothMovement()
    setResetConfirmOpen(false)
  }

  const handlePulloutConfirm = () => {
    toothMovement.pulloutATooth()
    setPulloutConfirmOpen(false)
  }

  const renderDialogs = () => (
    <>
      <Dialog open={movetoothPoPUP}>
        <DialogTitle id="alert-dialog-title">{"uDesign"}</DialogTitle>
        <DialogContent>
          {"Need to add a key frame first to move tooth"}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setmovetoothPoPUP(false)}
            autoFocus
            sx={{ textTransform: "uppercase" }}
            size="medium"
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={resetConfirmOpen}
        onClose={() => setResetConfirmOpen(false)}
      >
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This operation will clear the tooth movement
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResetConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleResetConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={pulloutConfirmOpen}
        onClose={() => setPulloutConfirmOpen(false)}
      >
        <DialogTitle>uDesign</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to extract this tooth?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPulloutConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handlePulloutConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
  const renderRndContent = () =>
    isShow && (
      <Rnd
        position={{ x: dragxy.x, y: dragxy.y }}
        onDragStop={(e, d) => {
          setDragxy({ x: d.x, y: d.y })
        }}
        dragHandleClassName="dragarea"
      >
        <Box
          className="toothinfo"
          sx={{
            width: "300px",
            backgroundColor: theme.palette.background.paper,
            position: "absolute",
            borderRadius: 2,
            paddingBottom: "30px",
          }}
        >
          <Stack
            className="dragarea"
            paddingLeft={2}
            paddingRight={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              height: "10%",
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.background.default
                  : "whitesmoke",
              borderRadius: 2,
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {`${getSettingLabel(NumberSystem, dataSource.toothId)}`}
            </Typography>
            <Stack alignItems="center" direction="row" float="right">
              {buttonList.map(
                (
                  { clickFun, component, isActive = false, isDisabled },
                  index,
                ) => {
                  return (
                    <IconButton
                      key={index}
                      onClick={clickFun}
                      disabled={isDisabled}
                      color={isActive ? "primary" : "default"}
                      sx={{
                        width: 45,
                        marginY: 0.5,
                        height: 45,
                        opacity: isDisabled ? 0.4 : 1,
                      }}
                    >
                      {component}
                    </IconButton>
                  )
                },
              )}
            </Stack>
          </Stack>
          {!disabled ? (
            <UDTabs labels={["Tooth", "AutoSetup"]}>
              {ToothMoveInfo()}
              <UDToothAutoSetup />
            </UDTabs>
          ) : (
            <>{ToothMoveInfo()}</>
          )}
        </Box>
      </Rnd>
    )
  return (
    <>
      {renderRndContent()}
      {renderDialogs()}
    </>
  )
}
export default UDToothInfo
