import { FC, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import {
  FormAutoComplete,
  FormInput,
  UButton,
  UText,
} from "../../../../components"
import { Box, Grid } from "../../../../components/mui.components"
import { useAppDispatch } from "../../../../core/app/hooks"
import { setAlert } from "../../../../core/app/slices/alert/alertSlice"
import { updatePatientParams } from "../../../../core/app/slices/patients/patient.types"
import { updatePatientData } from "../../../../core/app/slices/patients/patientThunkApi"
import { IPatient } from "../../../../core/model/interface/IPatient"
import { IPatientForm } from "../../../../core/model/interface/IPatient"
import { validations } from "../../../../core/utils/validations"
import { useMyNavigation } from "../../../../hooks/useMyNavigation"
import { CountryCode } from "../../../CountryCode"
import {
  ARCHIVEPATIENT,
  UNARCHIVEPATIENT,
} from "../../config/menuAction.config"
import { splitCountryCodeAndMobileNumber } from "../../PatientList/util/commonUtil"

import CaseActionPopup from "./CaseActionPopup"
import UploadPatientPhoto from "./UploadPatientPhoto"

const EditPatientInfoForm: FC<{
  patientData: IPatient
  handleClose?: () => void
  isFromClinicalView?: boolean
}> = ({ patientData, handleClose, isFromClinicalView = false }) => {
  const { t } = useTranslation("common")
  const regex = /^[^\/\\:*?<>\|$'".,;]*$/;

  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const { patientId } = useParams()
  const [selectedCode, setSelectedCode] = useState("")
  const [isAlertOpen, setIsAlertOpen] = useState("")
  const [archivePatientBtn, setArchivePatientBtn] = useState({
    btnText: "",
    btnAction: "",
    variant: "shade",
    sxProps: {
      width: 0,
    },
  })

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
    getFieldState,
    getValues,
  } = useForm<IPatientForm>({ mode: "onSubmit" })

  useEffect(() => {
    setValue("firstName", patientData?.firstName)
    setValue("lastName", patientData?.lastName)
    setValue("month", patientData?.dob.split("-")[1])
    setValue("day", patientData?.dob.split("-")[2])
    setValue("year", patientData?.dob.split("-")[0])
    setValue("notes", patientData?.notes)
    setValue("email", patientData?.email)
    const formValue = watch("countryCode", patientData?.country)
    setValue("phone", patientData?.phone)
    setValue("countryCode", patientData?.country || "US")
    setSelectedCode(patientData?.country)
    setArchivePatientBtn(
      patientData.status === "ARCHIVED" ? UNARCHIVEPATIENT : ARCHIVEPATIENT,
    )
  }, [patientData])

  const validateDate = (day: string) => {
    const month =
      (getFieldState("month").isDirty && !getFieldState("month").invalid) ||
      getValues("month")
        ? parseInt(watch("month"), 10)
        : new Date().getMonth()
    const year =
      (getFieldState("year").isDirty && !getFieldState("year").invalid) ||
      getValues("year")
        ? parseInt(watch("year"), 10)
        : 0
    const date = new Date(year, month - 1, parseInt(day, 10))
    return date.getMonth() === month - 1 && !(date > new Date())
  }

  const onSubmit: SubmitHandler<IPatientForm> = (data) => {
    // setFormSubmitted(true)
    const payload: updatePatientParams = {
      first_name: String(data.firstName).trim(),
      last_name: String(data.lastName).trim(),
      patient_dob: data.year + "-" + data.month + "-" + data.day,
      ref_notes: data.notes,
      country: data.phone && data.countryCode && data.countryCode,
      phone: data.phone && data.countryCode && data.phone,
      email: data.email,
    }
    dispatch(updatePatientData({ patientId: patientId, payload })).then(
      (res) => {
        if (res.payload?.data?.status === "Success") {
          if (handleClose) {
            handleClose()
          } else {
            navigate(`overview/patient/${patientId}`)
          }
        } else {
          setAlert({
            message: t("overview.profile.updateFailed"),
            isError: true,
          })
        }
      },
    )
  }

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: isFromClinicalView ? 0 : 4,
        }}
      >
        <Box
          component={"form"}
          data-testid={"patient-form"}
          sx={{
            backgroundColor: "#ffffff",
            width: "760px",
            p: isFromClinicalView ? 3 : 5,
            mt: isFromClinicalView ? 0 : 2,
            borderRadius: "16px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container>
            <UText variant={"h6"} sxProp={{ mb: 1 }}>
              {t("formfields.patientPhoto")}
            </UText>
            <Grid
              container
              rowSpacing={0}
              columnSpacing={1}
              marginBottom={1}
              marginLeft={0}
              sx={{ alignItems: "center" }}
            >
              <UploadPatientPhoto />
            </Grid>
            <UText variant={"h6"} sxProp={{ p: 0 }}>
              {t("formfields.name")}
            </UText>
            <Grid container rowSpacing={0} columnSpacing={1} marginBottom={2}>
              <Grid item xs={12} sm={6}>
                <FormInput
                  formSxprops={{ my: 1 }}
                  inputLabel={"formfields.firstname"}
                  fieldName={"firstName"}
                  rules={{
                    required: "formfieldErrors.firstnamerequired",
                    pattern: {
                      value: regex,
                      message: 'The name can\'t contain any of the following characters: / \\ : * ? < > | $ \' " , . ;'
                    },
                    maxLength: {
                      value: 30,
                      message: "formfieldErrors.namelengtherror",
                    }
                  }}
                  errors={errors}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormInput
                  formSxprops={{ my: 1 }}
                  inputLabel={"formfields.lastname"}
                  fieldName={"lastName"}
                  rules={{
                    required: "formfieldErrors.larstnamerequired",
                    pattern: {
                      value: regex,
                      message: 'The name can\'t contain any of the following characters: / \\ : * ? < > | $ \' " , . ;'
                    },
                    maxLength: {
                      value: 30,
                      message: "formfieldErrors.namelengtherror",
                    }
                  }}
                  errors={errors}
                  control={control}
                />
              </Grid>
            </Grid>
            <UText variant={"h6"}>{t("formfields.dateofbirth")}</UText>
            <Grid container rowSpacing={0} columnSpacing={1} marginBottom={2}>
              <Grid item xs={4}>
                <FormInput
                  formSxprops={{ my: 1 }}
                  inputLabel={"formfields.month"}
                  placeholder={"formfields.monthplaceholder"}
                  fieldName={"month"}
                  rules={{
                    required: "formfieldErrors.monthrequired",
                    pattern: validations.month.pattern,
                  }}
                  errors={errors}
                  control={control}
                />
              </Grid>
              <Grid item xs={4}>
                <FormInput
                  formSxprops={{ my: 1 }}
                  inputLabel={"formfields.day"}
                  placeholder={"formfields.dayplaceholder"}
                  fieldName={"day"}
                  rules={{
                    required: "formfieldErrors.dayrequired",
                    pattern: validations.day.pattern,
                    validate: (value) => {
                      return (
                        validateDate(value) || validations.day.pattern.message
                      )
                    },
                  }}
                  errors={errors}
                  control={control}
                />
              </Grid>
              <Grid item xs={4}>
                <FormInput
                  formSxprops={{ my: 1 }}
                  inputLabel={"formfields.year"}
                  placeholder={"formfields.yearplaceholder"}
                  fieldName={"year"}
                  rules={{
                    required: "formfieldErrors.yearrequired",
                    pattern: validations.year.pattern,
                    validate: (value) =>
                      (Number(value) > 1900 &&
                        Number(value) <= new Date().getFullYear()) ||
                      "formfieldErrors.invalidYear",
                  }}
                  onInputChange={(value: string, newValue: string) => {
                    return newValue === "" ||
                      validations.year.inputFormat.test(newValue)
                      ? newValue
                      : value
                  }}
                  errors={errors}
                  control={control}
                />
              </Grid>
            </Grid>
            <UText variant={"h6"}>{t("formfields.notes")}</UText>
            <Grid container rowSpacing={0} columnSpacing={1} marginBottom={2}>
              <Grid item xs={12} sm={12}>
                <FormInput
                  rules={{
                    maxLength: {
                      value: 255,
                      message: "formfieldErrors.noteserror",
                    },
                  }}
                  formSxprops={{ my: 1 }}
                  fieldName={"notes"}
                  errors={errors}
                  control={control}
                  multiline={true}
                  maxRows={4}
                />
              </Grid>
            </Grid>
          </Grid>
          <UText variant={"h6"}>{t("formfields.contactinformation")}</UText>
          <Grid container rowSpacing={0} columnSpacing={1}>
            <Grid item xs={12} sm={12}>
              <FormInput
                formSxprops={{ my: 1 }}
                fieldName={"email"}
                inputLabel={"formfields.emailaddress"}
                rules={{
                  pattern: validations.email.pattern,
                }}
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid
              container
              rowSpacing={0}
              columnSpacing={1}
              marginBottom={2}
              marginLeft={0}
            >
              <Grid item xs={4}>
                <FormAutoComplete
                  formSxprops={{ mt: 1 }}
                  inputLabel={"formfields.countrycode"}
                  fieldName={"countryCode"}
                  isInputLabel={true}
                  options={CountryCode}
                  errors={errors}
                  control={control}
                  defaultValue={
                    CountryCode?.find((list) => list.id === selectedCode)
                      ?.label || "United States +1"
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <FormInput
                  formSxprops={{ mt: 1 }}
                  fieldName={"phone"}
                  inputLabel={"formfields.phonenumber"}
                  rules={{
                    pattern: validations.phoneNumber.pattern,
                  }}
                  errors={errors}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
              <Grid item sx={{ pl: 1 }}>
                {!isFromClinicalView && (
                  <UButton
                    variant={"shade"}
                    btnText={archivePatientBtn.btnText}
                    sxProp={{
                      height: 36,
                      width: archivePatientBtn.sxProps.width,
                      boxShadow:
                        "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);",
                    }}
                    onClickHandler={() =>
                      setIsAlertOpen(archivePatientBtn.btnAction)
                    }
                  />
                )}
              </Grid>

              <Grid item>
                <UButton
                  variant={"text"}
                  btnText={t("button.cancel")}
                  sxProp={{
                    height: "36px",
                    width: "74px",
                    mr: 3,
                  }}
                  onClickHandler={() => {
                    if (handleClose) {
                      handleClose()
                    } else {
                      navigate(`overview/patient/${patientId}`)
                    }
                  }}
                />
                <UButton
                  sxProp={{ width: "70px", height: "36px" }}
                  variant={"contained"}
                  btnType={"submit"}
                  btnText={t("button.save")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <CaseActionPopup
        isAlertOpen={isAlertOpen}
        setIsAlertOpen={setIsAlertOpen}
        rowPatientId={patientId}
      />
    </>
  )
}

export default EditPatientInfoForm
